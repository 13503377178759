import { useIntl } from "react-intl";

import { ImpactCategory } from "../../../../domain/impactCategories";
import { ImpactRating } from "../../../../domain/impactRatings";

// When downloading label PNGs, we create a Label component, and then pass it to convertSvgToBase64Png.
// convertSvgToBase64Png does not work if the component passed to it contains hooks.
// So we cannot call useIntl() from within the Label component.
// We work around this by declaring all the strings we need in the Translations component,
// and then getting sthe strings from the appropriate locale's JSON file.

const deDE = require("../../../../lang-compiled/de-DE.json");
const enGB = require("../../../../lang-compiled/en-GB.json");
const es419 = require("../../../../lang-compiled/es-419.json");
const esES = require("../../../../lang-compiled/es-ES.json");
const frFr = require("../../../../lang-compiled/fr-FR.json");
const nlNL = require("../../../../lang-compiled/nl-NL.json");
const zhCN = require("../../../../lang-compiled/zh-CN.json");

export default function Translations() {
  const intl = useIntl();

  // Rating letters
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:letter/A",
    defaultMessage: "A",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:letter/B",
    defaultMessage: "B",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:letter/C",
    defaultMessage: "C",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:letter/D",
    defaultMessage: "D",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:letter/E",
    defaultMessage: "E",
  });

  // Rating names
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:ratingName/veryLow",
    defaultMessage: "Very Low",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:ratingName/low",
    defaultMessage: "Low",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:ratingName/medium",
    defaultMessage: "Medium",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:ratingName/high",
    defaultMessage: "High",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:ratingName/veryHigh",
    defaultMessage: "Very High",
  });

  // Impact category titles
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:impactCategoryTitle/ghg",
    defaultMessage: "Carbon",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:impactCategoryTitle/landUse",
    defaultMessage: "Land use",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:impactCategoryTitle/waterUse",
    defaultMessage: "Water use",
  });

  // Long impact category titles
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:impactCategoryTitleLong/ghg",
    defaultMessage: "Carbon rating",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:impactCategoryTitleLong/landUse",
    defaultMessage: "Land use rating",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:impactCategoryTitleLong/waterUse",
    defaultMessage: "Water use rating",
  });

  // Units
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:unit/ghg",
    defaultMessage: "kg CO₂e",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:unit/landUse",
    defaultMessage: "m\u00B2year",
  });
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:unit/waterUse",
    defaultMessage: "L",
  });

  // Kg
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:unit/kg",
    defaultMessage: "kg",
  });

  // Serving
  intl.formatMessage({
    id: "components/utils/Vectors/labels/Translations:serving",
    defaultMessage: "serving",
  });

  return <></>;
}

function getTranslation(locale: string | null, key: string) {
  const enGbValue = enGB[key][0].value;
  switch (locale) {
    case "es-419":
      return es419[key] ? es419[key][0].value : enGbValue;
    case "es-ES":
      return esES[key] ? esES[key][0].value : enGbValue;
    case "de-DE":
      return deDE[key] ? deDE[key][0].value : enGbValue;
    case "fr-FR":
      return frFr[key] ? frFr[key][0].value : enGbValue;
    case "nl-NL":
      return nlNL[key] ? nlNL[key][0].value : enGbValue;
    case "zh-CN":
      return zhCN[key] ? zhCN[key][0].value : enGbValue;
    default:
      return enGbValue;
  }
}

export function impactRatingToLetter(
  impactRating: ImpactRating,
  locale: string | null
) {
  switch (impactRating) {
    case "VLOW":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:letter/A"
      );
    case "LOW":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:letter/B"
      );
    case "MEDIUM":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:letter/C"
      );
    case "HIGH":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:letter/D"
      );
    case "VHIGH":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:letter/E"
      );
  }
}

export function impactRatingToName(
  impactRating: ImpactRating,
  locale: string | null
) {
  switch (impactRating) {
    case "VLOW":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:ratingName/veryLow"
      );
    case "LOW":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:ratingName/low"
      );
    case "MEDIUM":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:ratingName/medium"
      );
    case "HIGH":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:ratingName/high"
      );
    case "VHIGH":
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:ratingName/veryHigh"
      );
  }
}

export function impactCategoryTitle(
  impactCategory: ImpactCategory,
  locale: string | null,
  long?: boolean
) {
  switch (impactCategory) {
    case ImpactCategory.GHG:
      return ghgTitle(locale, long);
    case ImpactCategory.LAND_USE:
      return landUseTitle(locale, long);
    case ImpactCategory.WATER_USE:
      return waterUseTitle(locale, long);
  }
}

export function ghgTitle(locale: string | null, long?: boolean) {
  const key = long
    ? "components/utils/Vectors/labels/Translations:impactCategoryTitleLong/ghg"
    : "components/utils/Vectors/labels/Translations:impactCategoryTitle/ghg";
  return getTranslation(locale, key);
}

export function landUseTitle(locale: string | null, long?: boolean) {
  const key = long
    ? "components/utils/Vectors/labels/Translations:impactCategoryTitleLong/landUse"
    : "components/utils/Vectors/labels/Translations:impactCategoryTitle/landUse";
  return getTranslation(locale, key);
}

export function waterUseTitle(locale: string | null, long?: boolean) {
  const key = long
    ? "components/utils/Vectors/labels/Translations:impactCategoryTitleLong/waterUse"
    : "components/utils/Vectors/labels/Translations:impactCategoryTitle/waterUse";
  return getTranslation(locale, key);
}

export function impactCategoryUnit(
  impactCategory: ImpactCategory,
  locale: string | null
) {
  switch (impactCategory) {
    case ImpactCategory.GHG:
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:unit/ghg"
      );
    case ImpactCategory.LAND_USE:
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:unit/landUse"
      );
    case ImpactCategory.WATER_USE:
      return getTranslation(
        locale,
        "components/utils/Vectors/labels/Translations:unit/waterUse"
      );
  }
}

export function impactCategoryUnitPerKg(
  impactCategory: ImpactCategory,
  locale: string | null
) {
  return `${impactCategoryUnit(impactCategory, locale)} / ${getTranslation(
    locale,
    "components/utils/Vectors/labels/Translations:unit/kg"
  )}`;
}

export function serving(locale: string | null) {
  return getTranslation(
    locale,
    "components/utils/Vectors/labels/Translations:serving"
  );
}
