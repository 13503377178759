import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  effectTypes,
  useEffectTypeForImpactCategoryPerFunctionalUnit,
} from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  useFoodManufacturerOrganization,
  useLandUse,
  useWaterUse,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import assertNever from "../../util/assertNever";
import ButterflyChart, { ComparisonValue } from "../graphs/ButterflyChart";
import InformationModalTooltip from "../utils/InformationModalTooltip";
import { Panel } from "../utils/Panel";
import { Pending } from "../utils/Vectors";
import * as ComparisonRecipeSelect from "./ComparisonRecipeSelect";
import FunctionalUnitDropdown from "./FunctionalUnitDropdown";
import {
  ImpactComparison_RecipeImpact as RecipeImpact,
  ImpactComparison_Recipe as Recipe,
} from "./ImpactComparison.graphql";
import "./ImpactComparison.css";
import { useRecipeImpactIsUnavailable } from "./useRecipeImpactIsUnavailable";

export type ComparisonImpactValue =
  | {
      type: "success";
      value: RecipeImpact;
    }
  | { type: "none" }
  | { type: "loading" }
  | { type: "pending" }
  | { type: "unavailable" };

interface ImpactCategoryComparison {
  comparisonRecipeImpactValue: ComparisonValue;
  selectedRecipeImpactMagnitude: number | null;
  name: React.ReactNode;
  pendingMessage: React.ReactNode;
  unit: React.ReactNode;
}

interface ImpactComparisonButterflyChartProps {
  comparisonRecipeSelect: React.ReactNode;
  impactCategoryComparisons: Array<ImpactCategoryComparison>;
  recipeName: string;
}

function ImpactComparisonButterflyChart(
  props: ImpactComparisonButterflyChartProps
) {
  const { impactCategoryComparisons, comparisonRecipeSelect, recipeName } =
    props;

  const intl = useIntl();

  return (
    <ButterflyChart
      data={impactCategoryComparisons.map((impactCategoryComparison) => {
        return {
          value1: impactCategoryComparison.selectedRecipeImpactMagnitude,
          value2: impactCategoryComparison.comparisonRecipeImpactValue,
          key: (
            <div className="d-flex flex-column text-center">
              <div className="medium-font">{impactCategoryComparison.name}</div>
              <small>{impactCategoryComparison.unit}</small>
            </div>
          ),
          pendingMessage: impactCategoryComparison.pendingMessage,
        };
      })}
      header={{
        left: <h4 className="my-0">{recipeName}</h4>,
        center: <h4 className="my-0">vs.</h4>,
        right: <div className="w-100">{comparisonRecipeSelect}</div>,
      }}
      unavailableValueMessage={intl.formatMessage({
        id: "components/recipes/ImpactComparison:impactUnavailable",
        defaultMessage: "Impact Unavailable",
      })}
    />
  );
}

interface ImpactComparisonProps {
  impact: RecipeImpact;
  includePackaging: boolean;
  recipe: Recipe;
}

export default function ImpactComparison(props: ImpactComparisonProps) {
  const { impact, includePackaging, recipe } = props;

  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const landUse = useLandUse();
  const waterUse = useWaterUse();
  const intl = useIntl();
  const recipeImpactIsUnavailable = useRecipeImpactIsUnavailable;
  const { trackFunctionalUnitSet } = useTracking();

  const [comparisonRecipeImpact, setComparisonRecipeImpact] =
    useState<ComparisonImpactValue>({ type: "none" });
  const [functionalUnit, setFunctionalUnit] = useState<FunctionalUnit>(
    FunctionalUnit.KG
  );

  const ghgEffectType = useEffectTypeForImpactCategoryPerFunctionalUnit(
    ImpactCategory.GHG,
    functionalUnit
  );
  const landUseEffectType = useEffectTypeForImpactCategoryPerFunctionalUnit(
    ImpactCategory.LAND_USE,
    functionalUnit
  );
  const waterUseEffectType = useEffectTypeForImpactCategoryPerFunctionalUnit(
    ImpactCategory.WATER_USE,
    functionalUnit
  );

  const comparisonRecipeSelect = (
    <ComparisonRecipeSelect.ComparisonRecipeSelect
      onChange={setComparisonRecipeImpact}
      includePackaging={includePackaging}
      selectedRecipe={recipe}
    />
  );

  const butterflyChartComparisonValue = (
    comparisonImpactValue: ComparisonImpactValue,
    impactCategory: ImpactCategory
  ): ComparisonValue => {
    if (
      comparisonImpactValue.type === "pending" ||
      comparisonImpactValue.type === "loading" ||
      comparisonImpactValue.type === "none" ||
      comparisonImpactValue.type === "unavailable"
    ) {
      return comparisonImpactValue;
    } else if (comparisonImpactValue.type === "success") {
      let impactMagnitude;
      if (impactCategory === ImpactCategory.GHG) {
        impactMagnitude = ghgEffectType.get(comparisonImpactValue.value);
      } else if (impactCategory === ImpactCategory.LAND_USE) {
        impactMagnitude = landUseEffectType.get(comparisonImpactValue.value);
      } else if (impactCategory === ImpactCategory.WATER_USE) {
        impactMagnitude = waterUseEffectType.get(comparisonImpactValue.value);
      } else {
        assertNever(impactCategory, "Unsupported impact category");
      }
      if (
        impactMagnitude === null ||
        recipeImpactIsUnavailable(comparisonImpactValue.value, impactCategory)
      ) {
        return { type: "unavailable" };
      }
      return { ...comparisonImpactValue, value: impactMagnitude };
    } else {
      assertNever(
        comparisonImpactValue,
        "Unsupported ComparisonImpactValue type"
      );
    }
  };

  const impactCategoryComparisons = (): Array<ImpactCategoryComparison> => {
    const ghgMagnitude = ghgEffectType.get(impact);
    const landUseMagnitude = landUseEffectType.get(impact);
    const waterUseMagnitude = waterUseEffectType.get(impact);

    let comparisons: Array<ImpactCategoryComparison> = [];

    if (ghgMagnitude! >= 0) {
      comparisons.push({
        selectedRecipeImpactMagnitude: ghgMagnitude,
        comparisonRecipeImpactValue: butterflyChartComparisonValue(
          comparisonRecipeImpact,
          ImpactCategory.GHG
        ),
        name: effectTypes.ghg.title(intl),
        unit: effectTypes.ghgPerKg.impactCategoryUnit(intl),
        pendingMessage: <PendingGHGMessage />,
      });
    }
    if (landUse) {
      comparisons.push({
        selectedRecipeImpactMagnitude: recipeImpactIsUnavailable(
          impact,
          ImpactCategory.LAND_USE
        )
          ? null
          : landUseMagnitude,
        comparisonRecipeImpactValue: butterflyChartComparisonValue(
          comparisonRecipeImpact,
          ImpactCategory.LAND_USE
        ),
        name: effectTypes.landUse.title(intl),
        unit: effectTypes.landUsePerKg.impactCategoryUnit(intl),
        pendingMessage: <PendingLandUseMessage />,
      });
    }
    if (waterUse) {
      comparisons.push({
        selectedRecipeImpactMagnitude: recipeImpactIsUnavailable(
          impact,
          ImpactCategory.WATER_USE
        )
          ? null
          : waterUseMagnitude,
        comparisonRecipeImpactValue: butterflyChartComparisonValue(
          comparisonRecipeImpact,
          ImpactCategory.WATER_USE
        ),
        name: effectTypes.waterUse.title(intl),
        unit: effectTypes.waterUsePerKg.impactCategoryUnit(intl),
        pendingMessage: <PendingLandUseMessage />,
      });
    }
    return comparisons;
  };

  const comparisons = impactCategoryComparisons();

  const handleFunctionalUnitChange = (functionalUnit: FunctionalUnit) => {
    setFunctionalUnit(functionalUnit);
    trackFunctionalUnitSet({
      chart: "Comparison",
      functionalUnit,
      foodManufacturerOrganization,
    });
  };

  // length === 0 check is a work around for hiding all avaiable impacts are negative,
  // and therefore hidden
  return comparisons.length === 0 ? null : (
    <Panel>
      <div className="ImpactComparison_Header">
        <h3>
          <FormattedMessage
            id="components/recipes/ImpactComparison:title"
            defaultMessage="Impact Comparison"
          />
        </h3>
        <FunctionalUnitDropdown
          selectedFunctionalUnit={functionalUnit}
          onChange={handleFunctionalUnitChange}
        />
      </div>
      <div className="ImpactComparison__ButterflyChartContainer">
        <ImpactComparisonButterflyChart
          comparisonRecipeSelect={comparisonRecipeSelect}
          impactCategoryComparisons={comparisons}
          recipeName={recipe.name}
        />
      </div>
      <InformationModalTooltip
        body={
          <FormattedMessage
            id="components/recipes/ImpactComparison:informationModal/body"
            defaultMessage="
                        <p>The Impact Comparison tool allows you to compare the impact of your product to another product that you have created.</p>

                        <p>This might be:</p>

                        <ul>
                            <li>A product that you make</li>
                            <li>An experimental product that you might make in future</li>
                            <li>A sample product in a similar category to yours</li>
                        </ul>
                        <p>Comparisons are partially based on the data that you provide when creating products, and as this data hasn't been verified by Foodsteps, comparison data cannot be communicated outside of your organisation.</p>
                    "
            values={{
              p: (chunks: React.ReactNode) => <p>{chunks}</p>,
              ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
              li: (chunks: React.ReactNode) => <li>{chunks}</li>,
            }}
          />
        }
        title={
          <FormattedMessage
            id="components/recipes/ImpactComparison:informationModal/title"
            defaultMessage="Product Impact Comparison"
          />
        }
      />
    </Panel>
  );
}

interface PendingMessageProps {
  text: React.ReactNode;
}

function PendingMessage(props: PendingMessageProps) {
  const { text } = props;

  return (
    <div className="d-flex flex-row">
      <Pending width={20} />
      <small className="ImpactComparison_NoImpactMessage_Text">{text}</small>
    </div>
  );
}

function PendingGHGMessage() {
  return (
    <PendingMessage
      text={
        <FormattedMessage
          id="components/recipes/ImpactComparison:pendingGHGMessage"
          defaultMessage="We can’t calculate the GHG emissions of this product right now."
        />
      }
    />
  );
}

function PendingLandUseMessage() {
  return (
    <PendingMessage
      text={
        <FormattedMessage
          id="components/recipes/ImpactComparison:pendingLandUseMessage"
          defaultMessage="We can’t calculate the land use impact of this product right now."
        />
      }
    />
  );
}

ImpactComparison.fragments = {
  recipe: gql`fragment ImpactComparison_Recipe on Recipe {
    ...ComparisonRecipeSelect_Recipe
    ${ComparisonRecipeSelect.fragments.recipe}
  }`,
  recipeImpact: gql`
    fragment ImpactComparison_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      unavailableLandUse
      unavailableWaterUse
    }
  `,
};
