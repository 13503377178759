import VectorProps from "./VectorProps";

export default function ErrorCross(props: VectorProps) {
  const {
    className = "",
    fill = "currentColor",
    handleClick = () => null,
    height,
    width,
  } = props;
  // https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Design-Library?type=design&node-id=1501-1661&mode=design&t=RBw5E35nF6LAZvmX-0
  return (
    <svg
      className={className}
      fill={fill}
      height={height ?? width}
      onClick={handleClick}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F44336" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36034 7.36034C6.87989 7.84079 6.87989 8.61976 7.36034 9.10021L10.2603 12.0001L7.3606 14.8998C6.88015 15.3803 6.88015 16.1592 7.3606 16.6397C7.84106 17.1201 8.62002 17.1201 9.10048 16.6397L12.0001 13.74L14.8998 16.6397C15.3802 17.1201 16.1592 17.1201 16.6397 16.6397C17.1201 16.1592 17.1201 15.3802 16.6397 14.8998L13.74 12.0001L16.6399 9.10025C17.1204 8.6198 17.1204 7.84083 16.6399 7.36038C16.1595 6.87993 15.3805 6.87993 14.9 7.36038L12.0001 10.2603L9.10021 7.36034C8.61976 6.87989 7.84079 6.87989 7.36034 7.36034Z"
        fill="#FBFBFB"
      />
    </svg>
  );
}
