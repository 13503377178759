import {
  Chart as Chartjs,
  ChartConfiguration,
  ChartDatasetProperties,
  TooltipModel,
  TooltipItem,
} from "chart.js";
import { IntlShape, useIntl } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { ImpactRating } from "../../__generated__/globalTypes";
import { EffectTypeAbsoluteValue } from "../../domain/EffectType";
import Chart from "./Chart";
import { infoCol } from "./colors";

import "./DoughnutChart.css";

// These padding values should be greater than the increase in radius on hover
const paddingY = 15;
const paddingX = 8;

type DoughnutChartDataset = ChartDatasetProperties<"doughnut", Array<number>>;

export interface DoughnutChartData {
  dataset: DoughnutChartDataset;
  labels: Array<string>;
}

interface CenterValue {
  label: string;
  unit: string;
  value: string;
}

interface DoughnutChartProps {
  centerValue?: CenterValue;
  chartData: DoughnutChartData;
  otherData?: {
    dataQualityScore: { ratingText: string; magnitudeText: string };
    rating: { value: ImpactRating; text: string } | null;
    percent: string;
    showDataQualityScore: boolean;
    value: number;
  }[];
  externalTooltipHandler?: (context: {
    chart: Chartjs;
    tooltip: TooltipModel<"doughnut">;
  }) => void;
  effectType: EffectTypeAbsoluteValue;
  formatValue: (value: number) => string;
  pie?: boolean;
}

const createChartConfig = (
  intl: IntlShape,
  props: DoughnutChartProps
): ChartConfiguration<"doughnut"> => {
  const {
    centerValue,
    chartData,
    effectType,
    externalTooltipHandler,
    formatValue,
    otherData,
    pie,
  } = props;
  const { dataset, labels } = chartData;

  const callbacks = externalTooltipHandler
    ? {
        beforeBody(tooltipItem: TooltipItem<any>[]) {
          return [
            otherData
              ? otherData[tooltipItem[0].dataIndex].rating?.value ?? ""
              : "",
            otherData
              ? otherData[tooltipItem[0].dataIndex].rating?.text ?? ""
              : "",
          ];
        },
        afterBody(tooltipItem: TooltipItem<any>[]) {
          return otherData
            ? otherData[tooltipItem[0].dataIndex].percent.toString()
            : "";
        },
        label(tooltipItem: TooltipItem<any>) {
          return tooltipItem.label;
        },
        footer(tooltipItem: TooltipItem<any>[]) {
          return [
            otherData
              ? otherData[tooltipItem[0].dataIndex].value
                  .toFixed(effectType.decimals)
                  .toLocaleString()
              : "",
            effectType.impactCategoryUnit(intl),
            otherData
              ? otherData[tooltipItem[0].dataIndex].dataQualityScore.ratingText
              : "",
            otherData
              ? otherData[tooltipItem[0].dataIndex].dataQualityScore
                  .magnitudeText
              : "",
            otherData
              ? otherData[tooltipItem[0].dataIndex].showDataQualityScore
                ? "true"
                : "false"
              : "false",
          ];
        },
      }
    : {
        label(tooltipItem: TooltipItem<any>) {
          return tooltipItem.label;
        },
        footer(tooltipItem: TooltipItem<any>[]) {
          return (
            tooltipItem[0].parsed
              .toFixed(effectType.decimals)
              .toLocaleString() +
            " " +
            effectType.impactCategoryUnit(intl)
          );
        },
      };

  return {
    type: "doughnut",
    data: {
      labels,
      datasets: [dataset],
    },
    plugins: [
      {
        id: "my-plugin",
        afterDatasetsDraw(chart, option) {
          if (centerValue !== undefined) {
            let y = chart.height / 2;
            let x = chart.width / 2;
            chart.ctx.fillStyle = infoCol;
            chart.ctx.textBaseline = "middle";
            chart.ctx.textAlign = "center";
            chart.ctx.font = "500 16px Graphik Web";
            chart.ctx.fillText(centerValue.label, x, y - 30);
            chart.ctx.fillStyle = infoCol;
            chart.ctx.font = "500 22px Graphik Web";
            chart.ctx.fillText(centerValue.value, x, y);
            chart.ctx.font = "400 13px Graphik Web";
            chart.ctx.fillText(centerValue.unit, x, y + 18);
          }
        },
      },
    ],
    options: {
      responsive: false,
      maintainAspectRatio: true,
      plugins: {
        datalabels: {
          formatter: formatValue,
        },
        tooltip: {
          position:
            externalTooltipHandler === undefined ? "nearest" : undefined,
          external: externalTooltipHandler ?? undefined,
          enabled: externalTooltipHandler === undefined ? true : false,
          displayColors: false,
          bodyAlign: "center",
          backgroundColor: infoCol,
          bodyColor: "white",
          callbacks,
        },
      },
      layout: {
        padding: {
          top: paddingY,
          bottom: paddingY,
          left: paddingX,
          right: paddingX,
        },
      },
      cutout: pie ? "0%" : "60%",
    },
  };
};

export default function DoughnutChart(props: DoughnutChartProps) {
  const {
    chartData,
    centerValue,
    effectType,
    externalTooltipHandler,
    formatValue,
    otherData,
    pie,
  } = props;
  const intl = useIntl();
  const chartConfig = useMemoOne(
    () =>
      createChartConfig(intl, {
        chartData,
        centerValue,
        effectType,
        externalTooltipHandler,
        formatValue,
        otherData,
        pie,
      }),
    [
      chartData,
      centerValue,
      effectType,
      externalTooltipHandler,
      formatValue,
      otherData,
      pie,
      intl,
    ]
  );
  // ts-ignore
  return <Chart chartConfig={chartConfig} />;
}
