import React from "react";
import { useIntl } from "react-intl";

import { EffectTypeAbsoluteValue } from "../../domain/EffectType";
import ExtraLargeNumber from "../typography/ExtraLargeNumber";
import useRecipeLabel from "./useRecipeLabel";

const DISABLED_CELL_VALUE = "-";

interface PackagingComponentImpactSectionRowProps {
  unit: React.ReactNode;
  value: React.ReactNode;
}

function PackagingComponentImpactSectionRow(
  props: PackagingComponentImpactSectionRowProps
) {
  const { unit, value } = props;

  return (
    <>
      <ExtraLargeNumber className="pt-3">{value}</ExtraLargeNumber>
      <div>{unit}</div>
    </>
  );
}

interface PackagingComponentImpactSectionProps {
  disabled: boolean;
  effectType: EffectTypeAbsoluteValue;
  ghgPerServingValue: number;
  impactPercentageOfTotalRecipe: number | null;
}

export default function PackagingComponentImpactSection(
  props: PackagingComponentImpactSectionProps
) {
  const {
    disabled,
    effectType,
    ghgPerServingValue,
    impactPercentageOfTotalRecipe,
  } = props;

  const intl = useIntl();
  const recipeLabel = useRecipeLabel();

  return (
    <div className="card__table ml-auto mr-auto">
      <div className="mb-2">
        <h4>
          {intl.formatMessage({
            id: "components/recipes/PackagingComponentImpactSection:title",
            defaultMessage: "Packaging Component Impact",
          })}
        </h4>
      </div>
      <PackagingComponentImpactSectionRow
        unit={effectType.unit(intl)}
        value={disabled ? DISABLED_CELL_VALUE : ghgPerServingValue.toFixed(2)}
      />
      <PackagingComponentImpactSectionRow
        unit={intl.formatMessage(
          {
            id: "components/recipes/PackagingComponentImpactSection:percentOfTotalRecipeImpact/unit",
            defaultMessage: "of total {recipeLabel} impact",
          },
          { recipeLabel: recipeLabel.singularLowercase }
        )}
        value={
          impactPercentageOfTotalRecipe === null || disabled
            ? DISABLED_CELL_VALUE
            : `${impactPercentageOfTotalRecipe.toFixed()}%`
        }
      />
    </div>
  );
}
