import { isEqual } from "lodash";
import { useHistory, useLocation } from "react-router";
import { useCallbackOne } from "use-memo-one";

export interface CreateQueryStringState<T> {
  toSearchParams: (value: T) => Array<[string, string]>;
  useState: () => [T, (value: T) => void];
}

export default function createQueryStringState<T>({
  key,
  parse,
  serialise,
  defaultValue,
}: {
  key: string;
  parse: (value: string) => T | null;
  serialise: (value: T) => string;
  defaultValue: T;
}): CreateQueryStringState<T> {
  return {
    toSearchParams(value: T): Array<[string, string]> {
      if (value === defaultValue) {
        return [];
      } else {
        return [[key, serialise(value)]];
      }
    },
    useState: () => useQueryStringState(key, parse, serialise, defaultValue),
  };
}

function useQueryStringState<T>(
  key: string,
  parse: (value: string) => T | null,
  serialise: (value: T) => string,
  defaultValue: T
): [T, (value: T) => void] {
  const [rawValue, setRawValue] = useRawQueryStringState(key);

  const value = rawValue !== null ? parse(rawValue) : null;

  const setValue = useCallbackOne(
    (value: T) =>
      setRawValue(isEqual(value, defaultValue) ? null : serialise(value)),
    [defaultValue, serialise, setRawValue]
  );

  return [value ?? defaultValue, setValue];
}

function useRawQueryStringState(
  key: string
): [string | null, (value: string | null) => void] {
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const value = urlParams.get(key);

  const setSearchParameter = useCallbackOne(
    (newValue: string | null): void => {
      const { location } = history;
      const urlParams = new URLSearchParams(location.search);
      const value = urlParams.get(key);

      if (value === newValue) {
        return;
      }

      if (newValue === null) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, newValue);
      }

      history.replace({
        search: urlParams.toString(),
      });
    },
    [history, key]
  );

  return [value, setSearchParameter];
}
