import classNames from "classnames";
import { useContext, useState } from "react";
import {
  AccordionContext,
  Accordion as BaseAccordion,
  useAccordionToggle,
} from "react-bootstrap";

import Checkbox from "./Checkbox";
import RotatingChevron from "./RotatingChevron";
import { FilledCircle } from "./Vectors";
import "./Accordion.css";

interface Option {
  checked: boolean;
  onChange: () => void;
  id: string;
  name: React.ReactNode;
}

interface Item {
  eventKey: string;
  options: Array<Option>;
  title: React.ReactNode;
  customContent?: React.ReactNode;
  overrideCount?: number;
}

interface AccordionProps {
  items: Array<Item>;
}

export default function Accordion(props: AccordionProps) {
  const { items } = props;

  return (
    <div className="Accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          item={item}
          overrideCount={
            items.find((i) => i.eventKey === item.eventKey)?.overrideCount
          }
        />
      ))}
    </div>
  );
}

interface AccordionItemProps {
  item: Item;
  overrideCount?: number;
}

function AccordionItem(props: AccordionItemProps) {
  const { item, overrideCount } = props;
  const count =
    overrideCount || item.options.filter((option) => option.checked).length;

  return (
    /* Dear Ross:
    Normally we'd put BaseAccordion around all the items, but if we do that we can only open
    one item at a time. So we wrap each item in its own BaseAccordion.
    See: https://stackoverflow.com/questions/62358575/how-to-open-multiple-accordion-tabs-in-react-bootstrap
    */
    <BaseAccordion>
      <AccordionHeader
        count={count}
        eventKey={item.eventKey}
        title={item.title}
      />
      <AccordionBody
        eventKey={item.eventKey}
        options={item.options}
        customContent={item.customContent}
      />
    </BaseAccordion>
  );
}

interface AccordionHeaderProps {
  count: number;
  eventKey: string;
  title: React.ReactNode;
}

function AccordionHeader(props: AccordionHeaderProps) {
  const { count, eventKey, title } = props;

  // eventKey is the identifier for the currently open accordion item, so is used to
  // determine certain styles that depend on state, e.g. the chevron rotation
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={classNames("Accordion_HeaderCard", {
        Accordion_HeaderCard_Open: isCurrentEventKey,
        Accordion_HeaderCard_Active: count > 0,
      })}
      onClick={decoratedOnClick}
    >
      <div className="medium-font">{title}</div>
      <div className="Accordion_HeaderCard_Right">
        {count > 0 && (
          <FilledCircle
            className="Accordion_HeaderCard_FilledCircle"
            fill="var(--accent-yellow)"
            textColor="black"
            width="20px"
            content={count}
          />
        )}
        <RotatingChevron expanded={isCurrentEventKey} />
      </div>
    </div>
  );
}

interface AccordionBodyProps {
  customContent?: React.ReactNode;
  eventKey: string;
  options: Array<Option>;
}

function AccordionBody(props: AccordionBodyProps) {
  const { customContent, eventKey, options } = props;

  return (
    <BaseAccordion.Collapse className="Accordion_Body" eventKey={eventKey}>
      <>
        {customContent !== undefined ? (
          <div key={eventKey}>{customContent}</div>
        ) : (
          options.map((option, index) => (
            <AccordionOption
              key={index}
              option={option}
              onChange={option.onChange}
            />
          ))
        )}
      </>
    </BaseAccordion.Collapse>
  );
}

interface AccordionOptionProps {
  option: Option;
  onChange: () => void;
}

function AccordionOption(props: AccordionOptionProps) {
  const { option, onChange } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div
      className={classNames("Accordion_Option", {
        Accordion_Option_Checked: option.checked,
      })}
      onClick={onChange}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Checkbox
        checked={option.checked}
        hover={hover}
        onChange={onChange}
        propagateOnClick={false}
      />
      <div>{option.name}</div>
    </div>
  );
}
