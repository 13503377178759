import { useIntl } from "react-intl";

import { Category } from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { Table, TableColumn } from "../utils/Table";
import {
  formatNumber,
  formatString,
  impactMagnitude,
  impactPerKg,
} from "./helperFunctions";

interface CategoriesTableProps {
  categoryColumnLabel: string;
  data: Array<Category>;
  impactCategory: ImpactCategory;
  unspecifiedCategoryLabel: string;
}

export default function CategoriesTable(props: CategoriesTableProps) {
  const {
    categoryColumnLabel,
    data,
    impactCategory,
    unspecifiedCategoryLabel,
  } = props;

  const intl = useIntl();
  const effectType = impactCategoryToEffectType(impactCategory);
  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);

  const columns: Array<TableColumn<Category>> = [
    {
      key: "category",
      label: categoryColumnLabel,
      renderCell: (category) => category.name ?? unspecifiedCategoryLabel,
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "spend",
      label: intl.formatMessage({
        id: "components/scope-3/CategoriesTable:spend",
        defaultMessage: "Spend",
      }),
      units: "£",
      renderCell: (category) => formatString(category.spend),
      sortComparator: comparators.map(
        (item) => (item.spend ? parseFloat(item.spend) : null),
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "mass",
      label: intl.formatMessage({
        id: "components/scope-3/CategoriesTable:mass",
        defaultMessage: "Mass",
      }),
      units: "kg",
      renderCell: (category) => formatNumber(category.massKg),
      sortComparator: comparators.map(
        (item) => item.massKg,
        comparators.number
      ),
    },
    {
      key: "impact",
      label: effectType.title(intl),
      units: effectType.unit(intl),
      renderCell: (category) =>
        formatNumber(impactMagnitude(category, impactCategory)),
      sortComparator: comparators.map(
        (category) => category.ghg,
        comparators.number
      ),
    },
    {
      key: "impact-per-kg",
      label: effectTypePerKg.title(intl),
      units: effectTypePerKg.unit(intl),
      renderCell: (category) =>
        formatNumber(impactPerKg(category, impactCategory, category.massKg)),
      sortComparator: comparators.map(
        (category) => impactPerKg(category, impactCategory, category.massKg),
        comparators.nullsLast(comparators.number)
      ),
    },
  ];

  return (
    <Table<Category>
      fullWidth
      columns={columns}
      rowKey={(item) => item.name}
      rows={data}
    />
  );
}
