import { useState } from "react";

import Accordion from "./Accordion";

function AccordionStory() {
  const [state, setState] = useState<Record<string, Array<any>>>({
    item1: [],
    item2: [],
    item3: [],
    item4: [],
    item5: [],
    item6: [],
  });

  function optionInItem(stateKey: string, value: any) {
    return stateKey in state ? state[stateKey].includes(value) : false;
  }

  function handleClick(stateKey: string, value: any) {
    if (stateKey in state) {
      if (state[stateKey].includes(value)) {
        setState({
          ...state,
          [`${stateKey}`]: [...state[stateKey].filter((x) => x !== value)],
        });
      } else {
        setState({ ...state, [`${stateKey}`]: [...state[stateKey], value] });
      }
    }
  }

  const items = [
    {
      eventKey: "0",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item0", "a"),
          onChange: () => handleClick("item0", "a"),
        },
        {
          id: "2",
          name: "Option 2",
          checked: optionInItem("item0", "b"),
          onChange: () => handleClick("item0", "b"),
        },
        {
          id: "3",
          name: "Option 3",
          checked: optionInItem("item0", "c"),
          onChange: () => handleClick("item0", "c"),
        },
      ],
      title: "Item 0",
    },
    {
      eventKey: "1",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item1", "a"),
          onChange: () => handleClick("item1", "a"),
        },
        {
          id: "2",
          name: "Option 2",
          checked: optionInItem("item1", "b"),
          onChange: () => handleClick("item1", "b"),
        },
        {
          id: "3",
          name: "Option 3",
          checked: optionInItem("item1", "c"),
          onChange: () => handleClick("item1", "c"),
        },
      ],
      title: "Item 1",
    },
    {
      eventKey: "2",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item2", "a"),
          onChange: () => handleClick("item2", "a"),
        },
        {
          id: "2",
          name: "Option 2",
          checked: optionInItem("item2", "b"),
          onChange: () => handleClick("item2", "b"),
        },
        {
          id: "3",
          name: "Option 3",
          checked: optionInItem("item2", "c"),
          onChange: () => handleClick("item2", "c"),
        },
      ],
      title: "Item 2",
    },
    {
      eventKey: "3",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item3", "a"),
          onChange: () => handleClick("item3", "a"),
        },
      ],
      title: "Item 3",
    },
    {
      eventKey: "4",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item4", "a"),
          onChange: () => handleClick("item4", "a"),
        },
        {
          id: "2",
          name: "Option 2",
          checked: optionInItem("item4", "b"),
          onChange: () => handleClick("item4", "b"),
        },
        {
          id: "3",
          name: "Option 3",
          checked: optionInItem("item4", "c"),
          onChange: () => handleClick("item4", "c"),
        },
      ],
      title: "Item 4",
    },
    {
      eventKey: "5",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item5", "a"),
          onChange: () => handleClick("item5", "a"),
        },
        {
          id: "2",
          name: "Option 2",
          checked: optionInItem("item5", "b"),
          onChange: () => handleClick("item5", "b"),
        },
        {
          id: "3",
          name: "Option 3",
          checked: optionInItem("item5", "c"),
          onChange: () => handleClick("item5", "c"),
        },
        {
          id: "4",
          name: "Option 3",
          checked: optionInItem("item5", "d"),
          onChange: () => handleClick("item5", "d"),
        },
      ],
      title: "Item 5",
    },
    {
      eventKey: "6",
      options: [
        {
          id: "1",
          name: "Option 1",
          checked: optionInItem("item6", "a"),
          onChange: () => handleClick("item6", "a"),
        },
        {
          id: "2",
          name: "Option 2",
          checked: optionInItem("item6", "b"),
          onChange: () => handleClick("item6", "b"),
        },
        {
          id: "3",
          name: "Option 3",
          checked: optionInItem("item6", "c"),
          onChange: () => handleClick("item6", "c"),
        },
      ],
      title: "Item 6",
    },
  ];
  return (
    <div className="w-100 h-100">
      <div className="w-50">
        <Accordion items={items} />
      </div>
    </div>
  );
}

const stories = {
  name: "Accordion",
  render: () => <AccordionStory />,
};
export default stories;
