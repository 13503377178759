import gql from "graphql-tag";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { RecipeFilter } from "../../__generated__/globalTypes";
import useCollections from "../../data-store/useCollections";
import { UseCollections_Collection } from "../../data-store/useCollections.graphql";
import { useTracking } from "../../tracking";
import * as statuses from "../../util/statuses";
import useMutation from "../graphql/useMutation";
import {
  extractNodesFromPagedQueryResult,
  usePagedQueryFetchAll,
} from "../graphql/usePagedQuery";
import useQuery from "../graphql/useQuery";
import { RecipeFilterWithExclude } from "../labels/DownloadLabelsModal";
import StatusDisplay from "../StatusDisplay";
import ActionModal from "../utils/ActionModal";
import { SecondaryButton } from "../utils/Button";
import Form from "../utils/Form";
import { Pending } from "../utils/Vectors";
import Tag from "../utils/Vectors/Tag";
import AddTagModal from "./AddTagModal";
import {
  EditTagsButton_Recipe,
  EditTagsModal_RecipeNameQuery,
  EditTagsModal_RecipeNameQueryVariables,
  EditTagsModal_RecipesWithCollectionsQuery,
  EditTagsModal_RecipesWithCollectionsQueryVariables,
  EditTagsModal_updateRecipesCollectionsMutation,
  EditTagsModal_updateRecipesCollectionsMutationVariables,
} from "./EditTagsButton.graphql";
import ManageTagsButton from "./ManageTagsButton";
import TagMultiSelect, {
  TagMultiSelectOption,
  TagMultiSelectOptionType,
} from "./TagMultiSelect";
import "./EditTagsButton.css";

interface EditTagsButtonProps {
  control: (
    className: string,
    disabled: boolean,
    icon: JSX.Element,
    label: JSX.Element,
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  ) => JSX.Element;
  onCollectionsDeleted: (
    deletedCollectionIds: Set<number>,
    collectionIdsRemovedFromRecipes: Set<number>
  ) => void;
  recipeFilter: RecipeFilterWithExclude;
  selectedAll: boolean;
}

export function EditTagsButton(props: EditTagsButtonProps) {
  const {
    control,
    onCollectionsDeleted,
    recipeFilter: { excludedIds, ...recipeFilter },
    selectedAll,
  } = props;

  const [modalState, setModalState] = useState<"none" | "edit" | "new">("none");
  const [recipesStatus, refreshRecipes] = useRecipes(recipeFilter);
  const { collectionsStatus, addCollection, refreshCollections } =
    useCollections();
  const { trackEditTagsStarted, trackTagCreated } = useTracking();
  const [selectedTagIds, setSelectedTagIds] = useState<Array<number> | null>(
    null
  );

  const handleAddTagComplete = (newTag: { id: number; name: string }) => {
    setSelectedTagIds([...(selectedTagIds ? selectedTagIds : []), newTag.id]);
    setModalState("edit");
    trackTagCreated({
      tagId: newTag.id,
      tagName: newTag.name,
      workflow: "Edit Tags",
    });
  };

  const handleEditTagsButtonClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.currentTarget.blur();
    // We need to refresh these queries every time the modal is shown,
    // because the tags belonging to a product may have changed
    Promise.all([refreshRecipes()]).then(() => {
      trackEditTagsStarted();
      setModalState("edit");
    });
    setSelectedTagIds(null);
  };

  const disabled = excludedIds === undefined && recipeFilter.ids?.length === 0;

  const icon = <Tag width={20} />;

  const label = (
    <FormattedMessage
      id="components/tags/EditTagsButton:Title"
      defaultMessage="Edit tags"
    />
  );

  return (
    <>
      {control(
        "EditTagsButton",
        disabled,
        icon,
        label,
        handleEditTagsButtonClick
      )}
      {modalState === "edit" && (
        <StatusDisplay.Many<
          [Array<EditTagsButton_Recipe>, Array<UseCollections_Collection>]
        >
          statuses={[recipesStatus, collectionsStatus]}
        >
          {(recipes, allCollections) => {
            return (
              <EditTagsModal
                allCollections={allCollections}
                recipes={recipes.filter(
                  (recipeWithCollections) =>
                    excludedIds === undefined ||
                    !excludedIds.includes(recipeWithCollections.id)
                )}
                selectedAll={selectedAll}
                onClose={(deletedTags, tagIdsToDelete) => {
                  setSelectedTagIds(null);
                  setModalState("none");
                  if (deletedTags.length > 0 || tagIdsToDelete.length > 0) {
                    onCollectionsDeleted(
                      new Set(deletedTags),
                      new Set(tagIdsToDelete)
                    );
                  }
                  refreshCollections();
                }}
                onNewTagClicked={() => setModalState("new")}
                selectedTagIds={selectedTagIds}
                setSelectedTagIds={setSelectedTagIds}
              />
            );
          }}
        </StatusDisplay.Many>
      )}
      {modalState === "new" && (
        <AddTagModal
          addTag={addCollection}
          show={modalState === "new"}
          onCancel={() => {
            setModalState("edit");
          }}
          onComplete={handleAddTagComplete}
        />
      )}
    </>
  );
}

interface EditTagsModalProps {
  onNewTagClicked: () => void;
  onClose: (deletedTags: number[], tagIdsToDelete: number[]) => void;
  allCollections: UseCollections_Collection[];
  recipes: EditTagsButton_Recipe[];
  selectedAll: boolean;
  selectedTagIds: Array<number> | null;
  setSelectedTagIds: (selectedTagIds: Array<number>) => void;
}

export function EditTagsModal(props: EditTagsModalProps) {
  const {
    onClose,
    allCollections,
    onNewTagClicked,
    recipes,
    selectedAll,
    selectedTagIds,
    setSelectedTagIds,
  } = props;

  return (
    <ActionModal
      className="edit-tags-modal"
      show
      title={
        recipes.length === 1 ? (
          <FormattedMessage
            id="components/tags/EditTagsButton:ModalTitleSingleProduct"
            defaultMessage="Edit Tags"
          />
        ) : (
          <FormattedMessage
            id="components/tags/EditTagsButton:ModalTitle"
            defaultMessage="Edit Tags in Selection"
          />
        )
      }
    >
      <ModalContent
        allCollections={allCollections}
        onNewTagClicked={onNewTagClicked}
        recipes={recipes}
        onClose={onClose}
        selectedAll={selectedAll}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
      />
    </ActionModal>
  );
}

function ModalContent(props: {
  allCollections: UseCollections_Collection[];
  onNewTagClicked: () => void;
  recipes: EditTagsButton_Recipe[];
  onClose: (deletedTags: number[], tagIdsToDelete: number[]) => void;
  selectedAll: boolean;
  selectedTagIds: Array<number> | null;
  setSelectedTagIds: (selectedTagIds: Array<number>) => void;
}) {
  const {
    onNewTagClicked,
    recipes,
    allCollections,
    onClose,
    selectedAll,
    selectedTagIds,
    setSelectedTagIds,
  } = props;

  const { trackAddTags, trackEditTagsCompleted, trackRemoveTags } =
    useTracking();

  const collectionIdToRecipeCount = useMemo(() => {
    const collectionIdToRecipeCount = new Map<number, number>();
    for (const recipe of recipes) {
      for (const collection of recipe.collections) {
        let count = collectionIdToRecipeCount.get(collection.id);
        if (count === undefined) {
          count = 1;
        } else {
          count += 1;
        }
        collectionIdToRecipeCount.set(collection.id, count);
      }
    }
    return collectionIdToRecipeCount;
  }, [recipes]);

  const addTagToAllRecipes = (
    currentOptions: Array<TagMultiSelectOption>,
    tag: { id: number; name: string }
  ) => {
    setOptions(
      currentOptions.map((option) =>
        option.id === tag.id
          ? {
              type: TagMultiSelectOptionType.ALL,
              id: tag.id,
              name: tag.name,
            }
          : option
      )
    );
  };

  const collectionToOption = useCallback(
    (collection: UseCollections_Collection): TagMultiSelectOption => {
      const recipeCount = collectionIdToRecipeCount.get(collection.id);
      if (recipes.length === 1) {
        return {
          type: TagMultiSelectOptionType.SIMPLE,
          id: collection.id,
          name: collection.name,
        };
      } else if (recipes.length === recipeCount || recipeCount === undefined) {
        return {
          type: TagMultiSelectOptionType.ALL,
          id: collection.id,
          name: collection.name,
        };
      } else {
        return {
          type: TagMultiSelectOptionType.PARTIAL,
          id: collection.id,
          name: collection.name,
          onAdd: addTagToAllRecipes,
          productCount: recipeCount,
        };
      }
    },
    [collectionIdToRecipeCount, recipes]
  );

  const initialOptions: Array<TagMultiSelectOption> =
    allCollections.map(collectionToOption);
  const initialSelectedTagIds = initialOptions
    .filter((option) => collectionIdToRecipeCount.get(option.id) !== undefined)
    .map((option) => option.id);

  const [canBeReset, setCanBeReset] = useState<boolean>(false);
  const [options, setOptions] =
    useState<Array<TagMultiSelectOption>>(initialOptions);

  useEffect(() => {
    setOptions(allCollections.map(collectionToOption));
  }, [allCollections, collectionToOption]);

  useEffect(() => {
    if (selectedTagIds === null) {
      setSelectedTagIds(initialSelectedTagIds);
    }
  });

  useEffect(() => {
    setCanBeReset(
      !(
        selectedTagIds?.length === initialSelectedTagIds.length &&
        selectedTagIds?.every((tagId) =>
          initialSelectedTagIds.includes(tagId)
        ) &&
        selectedTagIds?.every((tagId) => {
          const option = options.find((option) => option.id === tagId);
          const initialOption = initialOptions.find(
            (option) => option.id === tagId
          );
          return option!.type === initialOption!.type;
        })
      )
    );
  }, [selectedTagIds, initialSelectedTagIds, options, initialOptions]);

  // This could be moved into Select.tsx if we want to use it elsewhere too
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (target.classList.contains("multi-select-prevent-menu-open")) {
        setMenuIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuIsOpen]);

  const [updateRecipesCollections] = useMutation<
    EditTagsModal_updateRecipesCollectionsMutation,
    EditTagsModal_updateRecipesCollectionsMutationVariables
  >(updateRecipesCollectionsMutation());

  // assuming recipes is not length 0 should be safe
  // because this button should be disabled in that case
  const { status: recipeNameStatus } = useQuery<
    EditTagsModal_RecipeNameQuery,
    EditTagsModal_RecipeNameQueryVariables
  >(recipeNameQuery(), { id: recipes[0].id });

  const resetSelectedCollections = () => {
    setOptions(initialOptions);
    setSelectedTagIds(initialSelectedTagIds);
  };

  const save = async () => {
    const optionsByTagId = new Map(
      options.map((option) => [option.id, option])
    );
    const getOptionByTagId = (tagId: number) => {
      return optionsByTagId.get(tagId);
    };
    const tagIdsToDelete = initialSelectedTagIds.filter(
      (tagId) => !selectedTagIds?.includes(tagId)
    );
    const tagIdsToAddRecipesTo = selectedTagIds?.filter((tagId) => {
      const option = getOptionByTagId(tagId);
      return (
        option !== undefined && option.type !== TagMultiSelectOptionType.PARTIAL
      );
    });
    const recipeIds = recipes.map((recipe) => recipe.id);
    await updateRecipesCollections({
      variables: {
        collectionIds: tagIdsToAddRecipesTo ?? [],
        recipeIds,
        collectionIdsToDelete: tagIdsToDelete,
        recipeIdsToDelete: recipeIds,
      },
    });
    onClose(deletedTags, tagIdsToDelete);
    tagIdsToAddRecipesTo?.forEach((tagId) => {
      const option = getOptionByTagId(tagId);
      if (option !== undefined) {
        trackAddTags({
          tagName: option.name,
          numberOfProducts: recipeIds.length,
        });
      }
    });
    tagIdsToDelete.forEach((tagId) => {
      const option = getOptionByTagId(tagId);
      if (option !== undefined) {
        trackRemoveTags({
          tagName: option.name,
          numberOfProducts: recipeIds.length,
        });
      }
    });
    trackEditTagsCompleted();
  };

  const handleSelectionChange = (ids: ReadonlyArray<number>) => {
    setSelectedTagIds(ids.map((id) => id));
  };

  const [deletedTags, setDeletedTags] = useState<number[]>([]);

  return (
    <>
      <ActionModal.Body>
        <p className="edit-tag-intro" style={{ color: "var--text-muted)" }}>
          {recipes.length === 1 ? (
            <StatusDisplay status={recipeNameStatus}>
              {(recipeNameResult) => (
                <FormattedMessage
                  id="components/tags/EditTagsButton:SingleRecipeSubheading"
                  defaultMessage="Add and remove tags from <span>{recipeName}.</span>"
                  values={{
                    recipeName: recipeNameResult.recipes.edges[0].node.name,
                    span: (chunks: React.ReactNode) => (
                      <span style={{ fontWeight: 500 }}>{chunks}</span>
                    ),
                  }}
                />
              )}
            </StatusDisplay>
          ) : (
            <FormattedMessage
              id="components/tags/EditTagsButton:Subheading"
              defaultMessage="Add and remove tags from <span>{all}{selectedProductCount} products.</span>"
              values={{
                all: selectedAll ? "all " : "",
                selectedProductCount: recipes.length,
                span: (chunks: React.ReactNode) => (
                  <span style={{ fontWeight: 500 }}>{chunks}</span>
                ),
              }}
            />
          )}
        </p>
        <TagMultiSelect
          options={options}
          onChange={handleSelectionChange}
          onNewTagClicked={onNewTagClicked}
          selectedIds={selectedTagIds ?? []}
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
        />
      </ActionModal.Body>
      <ActionModal.Footer>
        <ManageTagsButton
          onCollectionDeleted={(id) => setDeletedTags([...deletedTags, id])}
          withText={true}
        />
        <div style={{ display: "flex", gap: "16px" }}>
          <SecondaryButton
            disabled={!canBeReset}
            icon={<Pending width="20px" />}
            onClick={resetSelectedCollections}
          >
            <FormattedMessage
              id="components/tags/EditTagsButton:reset"
              defaultMessage="Reset"
            />
          </SecondaryButton>
          <SecondaryButton onClick={() => onClose(deletedTags, [])}>
            <FormattedMessage
              id="components/tags/EditTagsButton:cancel"
              defaultMessage="Cancel"
            />
          </SecondaryButton>
          <Form onSubmit={save}>
            <Form.SubmitButton
              loadingLabel={
                <FormattedMessage
                  id="components/tags/EditTagsButton:saving"
                  defaultMessage="Saving"
                />
              }
              submitLabel={
                <FormattedMessage
                  id="components/tags/EditTagsButton:save"
                  defaultMessage="Save"
                />
              }
            />
          </Form>
        </div>
      </ActionModal.Footer>
    </>
  );
}

function useRecipes(
  recipeFilter: RecipeFilter
): [statuses.Status<Array<EditTagsButton_Recipe>>, () => Promise<void>] {
  const { status, refresh } = usePagedQueryFetchAll<
    EditTagsModal_RecipesWithCollectionsQuery,
    EditTagsModal_RecipesWithCollectionsQueryVariables,
    EditTagsButton_Recipe
  >(recipesQuery(), { recipeFilter }, (data) => data.recipes);

  const collectionsStatus = statuses.map(
    status,
    extractNodesFromPagedQueryResult
  );

  return [collectionsStatus, refresh];
}

const recipesQuery = () => gql`
  query EditTagsModal_RecipesWithCollectionsQuery(
    $after: String
    $recipeFilter: RecipeFilter!
  ) {
    recipes(after: $after, first: 1000, filter: $recipeFilter) {
      edges {
        node {
          ...EditTagsButton_Recipe
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  fragment EditTagsButton_Recipe on Recipe {
    id
    collections {
      name
      id
    }
  }
`;

const recipeNameQuery = () => gql`
  query EditTagsModal_RecipeNameQuery($id: Int!) {
    recipes(first: 1, filter: { ids: [$id] }) {
      edges {
        node {
          name
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const updateRecipesCollectionsMutation = () => gql`
  mutation EditTagsModal_updateRecipesCollectionsMutation(
    $collectionIds: [Int!]!
    $recipeIds: [Int!]!
    $collectionIdsToDelete: [Int!]!
    $recipeIdsToDelete: [Int!]!
  ) {
    removeRecipesFromCollections(
      input: {
        collectionIds: $collectionIdsToDelete
        recipeIds: $recipeIdsToDelete
      }
    ) {
      success
    }
    addRecipesToCollections(
      input: { collectionIds: $collectionIds, recipeIds: $recipeIds }
    ) {
      success
    }
  }
`;
