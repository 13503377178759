import { FormattedMessage } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import { useProductTags } from "../../services/useOrganizationFeatures";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import { recipesOwnedByOrganizationOrInUserGroupFilter } from "../utils/ownedByOrganizationOrInUserGroupFilter";
import RecipesPanel from "./RecipesPanel";
import useRecipeLabel from "./useRecipeLabel";

const tracking = { pageName: "recipes" };

export default function RecipesPage() {
  const pages = usePages();
  const [organizationId] = useOrganizationId();
  const recipeLabel = useRecipeLabel();
  const canUseCollections = useProductTags();
  const [{ isReadonly: userIsReadonly }] = useUserInfo();

  return (
    <Page tracking={tracking}>
      <div className="h-100 d-flex flex-column">
        <Page.Title breadcrumb={pages.Recipes.breadcrumb(null)} />
        <RecipesPanel
          newRecipeUrl={pages.RecipesNew.url ?? undefined}
          noRecipesMessage={
            <FormattedMessage
              id="components/recipes/RecipesPage:noRecipesMessage"
              defaultMessage="
                      You do not have any saved {recipesLabel}, please add a new {recipeLabel} to
                      get started.
                    "
              values={{
                recipeLabel: recipeLabel.singularLowercase,
                recipesLabel: recipeLabel.pluralLowercase,
              }}
            />
          }
          noRecipesDueToSearchMessage={
            <FormattedMessage
              id="components/recipes/RecipesPage:noRecipesDueToSearchMessage"
              defaultMessage="There are no {recipesLabel} matching your search."
              values={{
                recipesLabel: recipeLabel.pluralLowercase,
              }}
            />
          }
          pageName="Recipes"
          recipeUrl={(recipe) => pages.Recipe.url(recipe)}
          recipeFilter={recipesOwnedByOrganizationOrInUserGroupFilter(
            organizationId
          )}
          showAddDropdown={true}
          showCopyToButton={false}
          showExportDropdown={true}
          showFilterToRequiresAttention={true}
          showManageTagsButton={canUseCollections && !userIsReadonly}
          includeRecipesUsedAsIngredient={true}
          includeRecipesOnlyAccessibleViaCollection={false}
        />
      </div>
    </Page>
  );
}
