import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";

import { useTracking } from "../../tracking";
import FilterButton from "../filter-dropdown/FilterButton";
import "./ProductFilterSelect.css";
import { SegmentedProductFilterMenuList } from "./SegmentedProductFilterMenuList";

interface ProductFilterSelectProps {
  filterByParentCollections?: boolean;
  showGeneralStatusSection: boolean;
  showManageTagsButton: boolean;
  setFilters: (
    filterToSelectedCollectionIds: Array<number>,
    filterToRequiresClientAttention: boolean,
    intersectCollections: boolean,
    filterToSelectedGhgImpactRatings: Array<string>,
    filterToSelectedLandUseImpactRatings: Array<string>,
    filterToSelectedWaterUseImpactRatings: Array<string>,
    filterToSelectedDiets: Array<string>
  ) => void;
  filters?: {
    filterToSelectedCollectionIds: Array<number>;
    filterToRequiresClientAttention: boolean;
    intersectCollections: boolean;
    filterToSelectedGhgImpactRatings: Array<string>;
    filterToSelectedLandUseImpactRatings: Array<string>;
    filterToSelectedWaterUseImpactRatings: Array<string>;
    filterToSelectedDiets: Array<string>;
  };
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: boolean) => void;
}

const filtersToNumberOfFilters = (filters: {
  filterToSelectedCollectionIds: Array<number>;
  filterToRequiresClientAttention: boolean;
  filterToSelectedGhgImpactRatings: Array<string>;
  filterToSelectedLandUseImpactRatings: Array<string>;
  filterToSelectedWaterUseImpactRatings: Array<string>;
  filterToSelectedDiets: Array<string>;
}) => {
  // TODO: tidy when filterToSelectedCollectionIds isn't nullable
  let numberOfFilters = 0;
  numberOfFilters += filters.filterToSelectedCollectionIds.length;
  if (filters.filterToRequiresClientAttention) {
    numberOfFilters += 1;
  }
  numberOfFilters += filters.filterToSelectedGhgImpactRatings.length;
  numberOfFilters += filters.filterToSelectedLandUseImpactRatings.length;
  numberOfFilters += filters.filterToSelectedWaterUseImpactRatings.length;
  numberOfFilters += filters.filterToSelectedDiets.length;
  return numberOfFilters;
};

export default function ProductFilterSelect(props: ProductFilterSelectProps) {
  const {
    filterByParentCollections,
    showGeneralStatusSection,
    showManageTagsButton,
    setFilters,
    filters,
    menuIsOpen,
    setMenuIsOpen,
  } = props;

  const [currentNumberOfFilters, setCurrentNumberOfFilters] = useState(
    filters ? filtersToNumberOfFilters(filters) : 0
  );

  useEffect(() => {
    setCurrentNumberOfFilters(filters ? filtersToNumberOfFilters(filters) : 0);
  }, [filters]);

  const [needsAttention, setNeedsAttention] = useState(
    filters?.filterToRequiresClientAttention || false
  );
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<
    Array<number>
  >(filters?.filterToSelectedCollectionIds ?? []);

  const [intersectCollections, setIntersectCollections] = useState(
    filters?.intersectCollections || false
  );

  const [selectedGhgImpactRatings, setSelectedGhgImpactRatings] = useState<
    Array<string>
  >(filters?.filterToSelectedGhgImpactRatings || []);
  const [selectedLandUseImpactRatings, setSelectedLandUseImpactRatings] =
    useState<Array<string>>(
      filters?.filterToSelectedLandUseImpactRatings || []
    );
  const [selectedWaterUseImpactRatings, setSelectedWaterUseImpactRatings] =
    useState<Array<string>>(
      filters?.filterToSelectedWaterUseImpactRatings || []
    );
  const [selectedDiets, setSelectedDiets] = useState<Array<string>>(
    filters?.filterToSelectedDiets ?? []
  );

  const productFilterSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        productFilterSelectRef.current &&
        !productFilterSelectRef.current.contains(event.target as Node) &&
        // stop modals triggered by ManageTagsButton from hiding when clicked
        // ActionModal adds this class to body when it's in use
        // https://getbootstrap.com/docs/4.0/components/modal/#usage
        // > "it also adds .modal-open to the <body>"
        !document.body.classList.contains("modal-open")
      ) {
        setMenuIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuIsOpen]);

  const removeAppliedFiltersRef = React.createRef<HTMLDivElement>();

  const { trackFilterProductsStarted } = useTracking();

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    if (
      removeAppliedFiltersRef.current == null ||
      (removeAppliedFiltersRef.current &&
        !removeAppliedFiltersRef.current.contains(e.target as Node))
      // todo: we should store filters and selected options together with some kind of reset state
      // for easier to read code
    ) {
      if (!menuIsOpen) {
        trackFilterProductsStarted();
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  };

  const onRemoveAppliedFiltersClick = () => {
    setSelectedCollectionIds([]);
    setNeedsAttention(false);
    setSelectedGhgImpactRatings([]);
    setSelectedLandUseImpactRatings([]);
    setSelectedWaterUseImpactRatings([]);
    setSelectedDiets([]);
    setFilters(
      [],
      false,
      filters ? filters.intersectCollections : false,
      [],
      [],
      [],
      []
    );
  };

  useEffect(() => {
    if (!menuIsOpen) {
      // whenever the menu is closed,
      // sync the visible filter state in the menu to the currently applied filters
      setSelectedCollectionIds(filters?.filterToSelectedCollectionIds ?? []);
      setNeedsAttention(filters?.filterToRequiresClientAttention || false);
      setIntersectCollections(filters?.intersectCollections || false);
      setSelectedGhgImpactRatings(
        filters?.filterToSelectedGhgImpactRatings || []
      );
      setSelectedLandUseImpactRatings(
        filters?.filterToSelectedLandUseImpactRatings || []
      );
      setSelectedWaterUseImpactRatings(
        filters?.filterToSelectedWaterUseImpactRatings || []
      );
      setSelectedDiets(filters?.filterToSelectedDiets || []);
    }
  }, [menuIsOpen, filters]);

  return (
    <div ref={productFilterSelectRef} className="react-select-container">
      {menuIsOpen && (
        <SegmentedProductFilterMenuList
          filterByParentCollections={filterByParentCollections}
          showGeneralStatusSection={showGeneralStatusSection}
          showManageTagsButton={showManageTagsButton}
          setFilters={setFilters}
          onMenuClose={() => setMenuIsOpen(false)}
          needsAttention={needsAttention}
          setNeedsAttention={setNeedsAttention}
          selectedCollectionIds={selectedCollectionIds}
          setSelectedCollectionIds={setSelectedCollectionIds}
          intersectCollections={intersectCollections}
          setIntersectCollections={setIntersectCollections}
          selectedGhgImpactRatings={selectedGhgImpactRatings}
          setSelectedGhgImpactRatings={setSelectedGhgImpactRatings}
          selectedLandUseImpactRatings={selectedLandUseImpactRatings}
          setSelectedLandUseImpactRatings={setSelectedLandUseImpactRatings}
          selectedWaterUseImpactRatings={selectedWaterUseImpactRatings}
          setSelectedWaterUseImpactRatings={setSelectedWaterUseImpactRatings}
          selectedDiets={selectedDiets}
          setSelectedDiets={setSelectedDiets}
        />
      )}
      <FilterButton
        currentNumberOfFilters={currentNumberOfFilters}
        handleClick={handleClick}
        menuIsOpen={menuIsOpen}
        onRemoveAppliedFiltersClick={onRemoveAppliedFiltersClick}
        removeAppliedFiltersRef={removeAppliedFiltersRef}
      />
    </div>
  );
}

ProductFilterSelect.fragments = {
  collection: gql`
    fragment ProductFilterSelect_Collection on RecipeCollection {
      id
      name
    }
  `,
};
