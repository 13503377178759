import React, { useState } from "react";

import { Assessment } from "../../data-store";
import useAssessments from "../../data-store/useAssessments";
import { ImpactCategory } from "../../domain/impactCategories";
import useAvailableImpactCategories, {
  useAdditionalImpactCategories,
} from "../../services/useOrganizationFeatures";
import { mapNotNull } from "../../util/arrays";
import assertNever from "../../util/assertNever";
import { sequentialDataColors } from "../graphs/colors";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import Page from "../Page";
import { usePages } from "../pages";
import ImpactCategoryToggle from "../recipes/ImpactCategoryToggle";
import StatusDisplay from "../StatusDisplay";
import "./ProcurementDashboard.css";
import { AssessmentOverview } from "./AssessmentOverview";
import { ImpactByAssessmentChartCard } from "./ImpactByAssessmentChartCard";
import "./ProcurementDashboard.css";
import "../Page.css";

const tracking = { pageName: "procurementDashboard" };

export default function ProcurementDashboardPage() {
  const [organizationId] = useOrganizationId();
  const [assessmentsStatus] = useAssessments(organizationId);
  const pages = usePages();

  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );
  const additionalImpactCategories = useAdditionalImpactCategories();
  const availableImpactCategories = useAvailableImpactCategories();

  const impactCategoryToggle = additionalImpactCategories && (
    <ImpactCategoryToggle
      onChange={setImpactCategory}
      options={availableImpactCategories}
      selectedImpactCategory={impactCategory}
    />
  );

  return (
    <Page contentHasPadding={false} tracking={tracking}>
      <div className="StickyPageTitle ProcurementDashboard_Header">
        <Page.Title
          breadcrumb={pages.ProcurementDashboard.breadcrumb()}
          title={pages.ProcurementDashboard.title}
          inlineComponentEnd={impactCategoryToggle}
          rightAlignEndComponent={true}
        />
      </div>
      <StatusDisplay.Many<[Array<Assessment>]> statuses={[assessmentsStatus]}>
        {(assessments) => {
          const assessmentFilter = (assessment: Assessment) => {
            if (impactCategory === ImpactCategory.GHG) {
              return assessment.show_scope3_report;
            } else if (impactCategory === ImpactCategory.LAND_USE) {
              return assessment.show_land_use_report;
            } else if (impactCategory === ImpactCategory.WATER_USE) {
              return assessment.show_water_use_report;
            } else {
              assertNever(impactCategory, "invalid ImpactCategory");
            }
          };

          assessments = assessments.filter(assessmentFilter);
          return (
            <ProcurementDashboardContent
              assessments={assessments}
              impactCategory={impactCategory}
            />
          );
        }}
      </StatusDisplay.Many>
    </Page>
  );
}

interface ProcurementDashboardContentProps {
  assessments: Array<Assessment>;
  impactCategory: ImpactCategory;
}

export function ProcurementDashboardContent(
  props: ProcurementDashboardContentProps
) {
  const { assessments, impactCategory } = props;
  const sortedAssessments = assessments.sort(
    (a, b) =>
      Date.parse(b.period_covered_start) - Date.parse(a.period_covered_start)
  );
  const assessmentIdToColourMap = new Map<string, string>(
    mapNotNull(sortedAssessments, (assessment, index) => [
      assessment.id,
      sequentialDataColors[index % sequentialDataColors.length],
    ])
  );
  return (
    <div className="ProcurementDashboardContent">
      <ImpactByAssessmentChartCard
        impactCategory={impactCategory}
        assessmentIdToColourMap={assessmentIdToColourMap}
      />
      <AssessmentOverview
        assessments={sortedAssessments}
        impactCategory={impactCategory}
        assessmentIdToColourMap={assessmentIdToColourMap}
      />
    </div>
  );
}
