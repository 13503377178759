import { FormattedMessage, useIntl } from "react-intl";

import { Supplier } from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { Table, TableColumn } from "../utils/Table";
import {
  formatNumber,
  formatString,
  impactMagnitude,
  impactPerKg,
} from "./helperFunctions";

interface SuppliersTableProps {
  data: Array<Supplier>;
  impactCategory: ImpactCategory;
}

export default function SuppliersTable(props: SuppliersTableProps) {
  const { data, impactCategory } = props;

  const intl = useIntl();
  const effectType = impactCategoryToEffectType(impactCategory);
  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);

  const columns: Array<TableColumn<Supplier>> = [
    {
      key: "supplier",
      label: intl.formatMessage({
        id: "components/scope-3/SuppliersTable:supplier",
        defaultMessage: "Supplier",
      }),
      renderCell: (supplier) =>
        supplier.name ?? (
          <FormattedMessage
            id="components/scope-3/SuppliersTable:unspecifiedSupplier"
            defaultMessage="Unspecified supplier"
          />
        ),
      sortComparator: comparators.map(
        (item) => item.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "spend",
      label: intl.formatMessage({
        id: "components/scope-3/SuppliersTable:spend",
        defaultMessage: "Spend",
      }),
      units: "£",
      renderCell: (supplier) => formatString(supplier.spend),
      sortComparator: comparators.map(
        (item) => parseFloat(item.spend),
        comparators.number
      ),
    },
    {
      key: "mass",
      label: intl.formatMessage({
        id: "components/scope-3/SuppliersTable:mass",
        defaultMessage: "Mass",
      }),
      units: "kg",
      renderCell: (supplier) => formatNumber(supplier.massKg),
      sortComparator: comparators.map(
        (item) => item.massKg,
        comparators.number
      ),
    },
    {
      key: "impact",
      label: effectType.title(intl),
      units: effectType.unit(intl),
      renderCell: (supplier) =>
        formatNumber(impactMagnitude(supplier, impactCategory)),
      sortComparator: comparators.map(
        (supplier) => impactMagnitude(supplier, impactCategory),
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "impact-per-kg",
      label: effectTypePerKg.title(intl),
      units: effectTypePerKg.unit(intl),
      renderCell: (supplier) =>
        formatNumber(impactPerKg(supplier, impactCategory, supplier.massKg)),
      sortComparator: comparators.map(
        (supplier) => impactPerKg(supplier, impactCategory, supplier.massKg),
        comparators.nullsLast(comparators.number)
      ),
    },
  ];

  return (
    <Table<Supplier>
      fullWidth
      columns={columns}
      rowKey={(item) => item.name}
      rows={data}
    />
  );
}
