import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useUserInfo from "../../data-store/useUserInfo";
import { CollectionToSelect } from "../recipes/RecipeQueryControls.graphql";
import useRecipeLabel from "../recipes/useRecipeLabel";
import ManageTagsButton from "../tags/ManageTagsButton";
import Checkbox from "../utils/Checkbox";
import SearchBox from "../utils/SearchBox";
import Select from "../utils/Select";
import "./TagFilterSection.css";

interface IntersectCollectionsOption {
  display: string;
  type: "all" | "any";
}

interface TagsFilterSectionProps {
  collections: Array<CollectionToSelect>;
  selectedCollectionIds: Array<number>;
  setSelectedCollectionIds: (selectedCollectionIds: Array<number>) => void;
  intersectCollections: boolean;
  setIntersectCollections: (intersectCollections: boolean) => void;
}

export default function TagsFilterSection(props: TagsFilterSectionProps) {
  const {
    collections,
    selectedCollectionIds,
    setSelectedCollectionIds,
    intersectCollections,
    setIntersectCollections,
  } = props;

  const [hoverRow, setHoverRow] = useState<CollectionToSelect | null>(null);
  const recipeLabel = useRecipeLabel();
  const intl = useIntl();

  const allCollectionIntersectionOption: IntersectCollectionsOption = {
    display: intl.formatMessage({
      id: "components/product-filter/ProductFilterSelect:all",
      defaultMessage: "all",
    }),
    type: "all",
  };
  const anyCollectionIntersectionOption: IntersectCollectionsOption = {
    display: intl.formatMessage({
      id: "components/product-filter/ProductFilterSelect:any",
      defaultMessage: "any",
    }),
    type: "any",
  };

  const [search, setSearch] = useState("");

  const handleCollectionCheckboxChange = (collectionId: number) => {
    // TODO: make sure this is in sync! just alternating scares me (same for needsAttention)
    if (selectedCollectionIds?.includes(collectionId)) {
      setSelectedCollectionIds(
        selectedCollectionIds.filter((id) => id !== collectionId)
      );
    } else {
      setSelectedCollectionIds([
        ...(selectedCollectionIds || []),
        collectionId,
      ]);
    }
    setSearch("");
  };

  function setAllIntersectCollections(intersect: boolean) {
    setIntersectCollections(intersect);
  }

  return collections.length > 0 ? (
    <>
      <div className="any-or-all-row">
        <FormattedMessage
          id="components/product-filter/ProductFilterMenuList:recipeLabelsWith"
          defaultMessage="{recipeLabel} with"
          values={{ recipeLabel: recipeLabel.pluralUppercase }}
        />
        <div className="ProductFilterSelect_SelectContainer">
          <Select
            options={[
              anyCollectionIntersectionOption,
              allCollectionIntersectionOption,
            ]}
            onChange={(option) =>
              option ? setAllIntersectCollections(option.type === "all") : null
            }
            value={
              intersectCollections
                ? allCollectionIntersectionOption
                : anyCollectionIntersectionOption
            }
            optionKey={(x) => x.type}
            renderOption={(x) => x.display}
            isClearable={false}
            dropdownArrow={"upDown"}
          />
        </div>
        <FormattedMessage
          id="components/product-filter/ProductFilterMenuList:ofTheFollowingTags"
          defaultMessage="of the following tags:"
        />
      </div>
      <div className="SearchBox">
        <SearchBox
          onChange={setSearch}
          placeholder={intl.formatMessage({
            id: "components/product-filter/ProductFilterMenuList:searchBoxPlaceholder",
            defaultMessage: "Search tags",
          })}
          value={search}
          autoFocus
        />
      </div>
      <div className="CollectionsTableContainer">
        <div className="CollectionsTable">
          {collections
            .filter((collection) =>
              collection.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((collection) => (
              <div
                className={
                  collection.id === hoverRow?.id
                    ? "ListItem__RowHighlighted"
                    : "ListItem"
                }
                key={collection.id}
                onMouseOver={
                  setHoverRow ? () => setHoverRow(collection) : undefined
                }
                onMouseLeave={setHoverRow ? () => setHoverRow(null) : undefined}
                onClick={() => handleCollectionCheckboxChange(collection.id)}
              >
                <Checkbox
                  containerClassName="Checkbox"
                  checked={selectedCollectionIds?.includes(collection.id)}
                  label={
                    <div className="d-flex flex-row">
                      <FormattedMessage
                        id="components/product-filter/ProductFilterSelect:collectionName"
                        defaultMessage="{collectionName}"
                        values={{ collectionName: collection.name }}
                      />
                    </div>
                  }
                  hover={collection.id === hoverRow?.id}
                  propagateOnClick={false}
                  onChange={() => handleCollectionCheckboxChange(collection.id)}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  ) : (
    <NoTagsDisplay />
  );
}

function NoTagsDisplay() {
  const recipeLabel = useRecipeLabel();
  const [{ isReadonly: userIsReadonly }] = useUserInfo();

  return (
    <div className="NoTagsDisplay">
      <div className="small-copy NoTagsDisplay__Text">
        <FormattedMessage
          id="components/product-filter/TagFilterSection:noTagsFound"
          defaultMessage="To filter by tag you must first create tags and add them to {recipeLabel}"
          values={{ recipeLabel: recipeLabel.pluralLowercase }}
        />
      </div>
      {/*Because this section only shows when there are no tags, we don't need to define a*/}
      {/*function for deleting a collection, since between opening and closing the modal as*/}
      {/*a whole there is no way a collection can go from existing to deleted.*/}
      <ManageTagsButton
        onCollectionDeleted={() => null}
        disabled={userIsReadonly}
        withText={true}
      />
    </div>
  );
}
