import { useIntl } from "react-intl";

import { ImpactCategory } from "../../domain/impactCategories";
import useAvailableImpactCategories from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import InlineTitleToggle from "../utils/InlineTitleToggle";

interface ImpactCategoryInlineTitleToggleProps {
  className?: string;
  value: ImpactCategory;
  onChange: (impactCategory: ImpactCategory) => void;
  optionIsDisabled?: (option: ImpactCategory) => boolean;
}

export default function ImpactCategoryInlineTitleToggle(
  props: ImpactCategoryInlineTitleToggleProps
) {
  const { className, value, onChange, optionIsDisabled } = props;

  const availableImpactCategories = useAvailableImpactCategories();
  const intl = useIntl();

  const renderImpactCategory = (impactCategory: ImpactCategory) => {
    if (impactCategory === ImpactCategory.GHG) {
      return intl.formatMessage({
        defaultMessage: "GHG Emissions",
        id: "components/recipes/ImpactCategoryInlineTitleToggle:ghgEmissions",
      });
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return intl.formatMessage({
        defaultMessage: "Land Use",
        id: "components/recipes/ImpactCategoryInlineTitleToggle:landUse",
      });
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return intl.formatMessage({
        defaultMessage: "Water Use",
        id: "components/recipes/ImpactCategoryInlineTitleToggle:waterUse",
      });
    } else {
      assertNever(impactCategory, "Unsupported ImpactCategory");
    }
  };

  return (
    <InlineTitleToggle
      className={className}
      onChange={onChange}
      options={availableImpactCategories}
      optionIsDisabled={optionIsDisabled}
      selectedOption={value}
      renderOption={renderImpactCategory}
    />
  );
}
