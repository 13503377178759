import { gql } from "graphql-tag";
import { FormattedMessage, useIntl } from "react-intl";

import { EffectTypeAbsoluteValue } from "../../domain/EffectType";
import { unitLabel } from "../../domain/units";
import * as comparators from "../../util/comparators";
import { Comparator } from "../../util/comparators";
import sort from "../../util/sort";
import { formatNumber } from "../utils/numberFormatting";
import { Table, TableColumn } from "../utils/Table";
import { RecipePackagingComponentTable_PackagingComponentV2 as PackagingComponentV2 } from "./RecipePackagingComponentTable.graphql";

const DISABLED_CELL_VALUE = "-";

interface RecipePackagingComponentTableProps {
  disabled: boolean;
  effectType: EffectTypeAbsoluteValue;
  packagingComponents: Array<PackagingComponentV2>;
}

interface Row extends PackagingComponentV2 {}

export default function RecipePackagingComponentTable(
  props: RecipePackagingComponentTableProps
) {
  const { disabled, effectType, packagingComponents } = props;

  const intl = useIntl();

  const packagingComponentsTableColumns: Array<TableColumn<Row>> = [
    {
      key: "name",
      className: "col-8 pl-0",
      label: intl.formatMessage({
        id: "components/recipes/RecipeBreakdown:PackagingComponentsTable/columns/name/label",
        defaultMessage: "Components per serving",
      }),
      renderCell: (packagingComponent) => (
        <div className="d-flex flex-row">{packagingComponent.name}</div>
      ),
    },
    {
      key: "weight",
      label: intl.formatMessage({
        id: "components/recipes/RecipeBreakdown:PackagingComponentsTable/columns/weight/label",
        defaultMessage: "Weight",
      }),
      renderCell: (packagingComponent) => {
        if (disabled) {
          return DISABLED_CELL_VALUE;
        } else {
          const componentWeightKg =
            packagingComponent.componentMaterials.reduce(
              (sum, material) => (sum += material.weightKg),
              0
            );
          return formatNumber(componentWeightKg * 1000) + unitLabel("g");
        }
      },
      align: "center",
    },
    {
      key: "ghg",
      label: intl.formatMessage({
        id: "components/recipes/RecipeBreakdown:PackagingComponentsTable/columns/ghg/label",
        defaultMessage: "Carbon Footprint",
      }),
      units: effectType.unit(intl),
      align: "right",
      renderCell: (packagingComponent) => {
        const value = packagingComponent.ghgMagnitude;
        if (disabled) {
          return DISABLED_CELL_VALUE;
        } else if (value === null) {
          return (
            <span className="medium-font">
              <FormattedMessage
                id="components/recipes/RecipeBreakdown:PackagingComponentsTable/impactPending"
                defaultMessage="Impact pending"
              />
            </span>
          );
        } else {
          return value.toFixed(effectType.decimals);
        }
      },
    },
  ];

  const defaultPackagingComponentComparator: Comparator<PackagingComponentV2> =
    comparators.many(
      comparators.map(
        (packagingComponent) => packagingComponent.ghgMagnitude,
        comparators.nullsLast(comparators.reversed(comparators.number))
      ),
      comparators.map(
        (ingredient) => ingredient.name,
        comparators.stringSensitivityBase
      )
    );

  const rows: Array<Row> = sort(
    packagingComponents,
    defaultPackagingComponentComparator
  );

  return (
    <Table<Row>
      columns={packagingComponentsTableColumns}
      fullWidth
      rowKey={(packagingComponent) => packagingComponent.id}
      rows={rows}
    />
  );
}

function packagingComponentMaterialV2Fragment() {
  return gql`
    fragment RecipePackagingComponentTable_PackagingComponentMaterialV2 on PackagingComponentMaterialV2 {
      weightKg
    }
  `;
}

RecipePackagingComponentTable.fragments = {
  packagingComponent: gql`
    fragment RecipePackagingComponentTable_PackagingComponentV2 on PackagingComponentV2 {
      ghgMagnitude
      id
      name
      componentMaterials {
        ...RecipePackagingComponentTable_PackagingComponentMaterialV2
      }
    }

    ${packagingComponentMaterialV2Fragment()}
  `,
};
