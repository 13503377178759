import PercentageLozenge from "./PercentageLozenge";

const stories = {
  name: "Percentage Lozenge",
  children: [
    {
      name: "Good increase",
      render: () => <PercentageLozenge amount={3} sentiment="positive" />,
    },
    {
      name: "Bad increase",
      render: () => <PercentageLozenge amount={3} sentiment="negative" />,
    },
    {
      name: "Neutral increase",
      render: () => <PercentageLozenge amount={3} sentiment="neutral" />,
    },
    {
      name: "Good decrease",
      render: () => <PercentageLozenge amount={-3} sentiment="positive" />,
    },
    {
      name: "Bad decrease",
      render: () => <PercentageLozenge amount={-3} sentiment="negative" />,
    },
    {
      name: "Neutral decrease",
      render: () => <PercentageLozenge amount={-3} sentiment="neutral" />,
    },
  ],
};

export default stories;
