import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useEffectTypeForImpactCategoryPerFunctionalUnit } from "../../domain/EffectType";
import { FunctionalUnit } from "../../domain/functionalUnits";
import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import { LightButton } from "../utils/Button";
import { ROOT_NODE_NAME } from "../utils/Sunburst";
import { Table, TableColumn } from "../utils/Table";
import { ChevronLeft, ChevronRight, FilledSquare } from "../utils/Vectors";
import { LifeCycleStage } from "./LifeCycleImpacts";
import "./LifeCycleImpactTable.css";

interface LifeCycleImpactTableProps {
  activeStage: LifeCycleStage;
  functionalUnit: FunctionalUnit;
  goBack: () => void;
  impactCategory: ImpactCategory;
  onActiveStageChange: (stage: LifeCycleStage) => void;
  productWeightKgPerServing: number;
  root: LifeCycleStage;
}

export default function LifeCycleImpactTable(props: LifeCycleImpactTableProps) {
  const {
    activeStage,
    functionalUnit,
    goBack,
    impactCategory,
    onActiveStageChange,
    productWeightKgPerServing,
    root,
  } = props;

  const intl = useIntl();
  const effectTypePerFunctionalUnit =
    useEffectTypeForImpactCategoryPerFunctionalUnit(
      impactCategory,
      functionalUnit
    );

  const openStageButton = (stage: LifeCycleStage) => {
    return (
      <LightButton
        className="LifeCycleImpactTable_OpenStageButton"
        onClick={() => onActiveStageChange(stage)}
      >
        {stage.name}
        <ChevronRight
          className="LifeCycleImpactTable_OpenStageButton_ChevronRight"
          width={12}
        />
      </LightButton>
    );
  };

  const backButton = () => {
    return (
      <div
        className="action-link LifeCycleImpactTable_OpenStageButton"
        onClick={goBack}
      >
        <ChevronLeft
          className="LifeCycleImpactTable_OpenStageButton_ChevronLeft"
          width={12}
        />
        {activeStage.name}
      </div>
    );
  };

  const isExpandable = (stage: LifeCycleStage) =>
    stage.children.length > 0 && stage.impactMagnitude !== 0;

  const impactMagnitude = (impactPerPortion: number) => {
    if (functionalUnit === FunctionalUnit.KG) {
      return impactPerPortion / productWeightKgPerServing;
    } else if (functionalUnit === FunctionalUnit.PORTION) {
      return impactPerPortion;
    } else {
      assertNever(functionalUnit, "Unsupported FunctionalUnit");
    }
  };

  const formatImpactMagnitude = (impactMagnitude: number): string => {
    if (Math.abs(impactMagnitude) < 0.001) {
      return "0.00";
    } else {
      return impactMagnitude.toFixed(2);
    }
  };

  const topLevelColumns: Array<TableColumn<LifeCycleStage>> = [
    {
      className: "LifeCycleImpactTable_LifecycleStage",
      key: "lifecycleStage",
      verticalAlign: "middle",
      label: (
        <FormattedMessage
          defaultMessage="Lifecycle Stage"
          id="components/recipes/LifeCycleImpactTable:lifecycleStage"
        />
      ),
      renderCell: (stage) => (
        <div className="d-flex flex-row">
          <FilledSquare
            className="LifeCycleImpactTable_LifecycleStage_Square"
            width={20}
            fill={stage.baseColor ?? "none"}
          />
          <div className="medium-font LifeCycleImpactTable_LifecycleStage_Percentage">
            {Math.round(stage.percentage)}%
          </div>
          <div className="medium-font LifeCycleImpactTable_LifecycleStage_Name">
            {activeStage.name !== ROOT_NODE_NAME
              ? backButton()
              : isExpandable(stage)
              ? openStageButton(stage)
              : stage.name}
          </div>
        </div>
      ),
    },
    {
      className: "LifeCycleImpactTable_Impact",
      align: "right",
      key: "impact",
      label: effectTypePerFunctionalUnit.title(intl),
      units: effectTypePerFunctionalUnit.impactCategoryUnit(intl),
      renderCell: (stage) => (
        <div className="text-right">
          {formatImpactMagnitude(impactMagnitude(stage.impactMagnitude))}
        </div>
      ),
    },
  ];

  const subTableColumns: Array<TableColumn<LifeCycleStage>> = [
    {
      className: "LifeCycleImpactTable_SubTable_OfWhich",
      key: "ofWhich",
      verticalAlign: "middle",
      label: (
        <FormattedMessage
          defaultMessage="Of which"
          id="components/recipes/LifeCycleImpactTable:ofWhich"
        />
      ),
      renderCell: (stage) => (
        <div className="d-flex flex-row">
          <div className="medium-font LifeCycleImpactTable_LifecycleStage_Percentage">
            {Math.round(stage.percentage)}%
          </div>
          <div className="medium-font LifeCycleImpactTable_LifecycleStage_Name">
            {isExpandable(stage) ? openStageButton(stage) : stage.name}
          </div>
        </div>
      ),
    },
    {
      className: "LifeCycleImpactTable_Impact",
      align: "right",
      key: "impact",
      label: "",
      renderCell: (stage) => (
        <div className="text-right">
          {formatImpactMagnitude(impactMagnitude(stage.impactMagnitude))}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table<LifeCycleStage>
        fullWidth
        rows={
          activeStage.name === ROOT_NODE_NAME ? root.children : [activeStage]
        }
        rowKey={(stage) => stage.name}
        columns={topLevelColumns}
      />
      {activeStage.name !== ROOT_NODE_NAME && (
        <Table<LifeCycleStage>
          className="LifeCycleImpactTable_SubTable"
          columns={subTableColumns}
          rowKey={(stage) => stage.name}
          rows={activeStage.children}
        />
      )}
    </>
  );
}
