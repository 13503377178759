import { useState } from "react";
import { useIntl } from "react-intl";

import { ProcuredItem } from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import * as comparators from "../../util/comparators";
import { usePages } from "../pages";
import { Table, TableColumn, TableSort } from "../utils/Table";
import {
  formatNumber,
  formatString,
  impactMagnitude,
  impactPerKg,
} from "./helperFunctions";

interface ProcuredItemsTableProps {
  data: Array<ProcuredItem>;
  impactCategory: ImpactCategory;
}

export default function ProcuredItemsTable(props: ProcuredItemsTableProps) {
  const { data, impactCategory } = props;

  const intl = useIntl();
  const effectType = impactCategoryToEffectType(impactCategory);
  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);
  const pages = usePages();
  const [queryParams] = pages.Scope3Page.useQueryParams();
  const [sort, setSort] = useState<TableSort | null>(
    queryParams.viewHighestImpactItems
      ? {
          columnKey: "impact",
          descending: true,
        }
      : null
  );

  const columns: Array<TableColumn<ProcuredItem>> = [
    {
      key: "procured-item",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:procuredItem",
        defaultMessage: "Procured Item",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.name),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.name,
        comparators.stringSensitivityBase
      ),
    },
    {
      key: "category",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:category",
        defaultMessage: "Category",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.categoryName),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.categoryName,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "quantity",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:quantity",
        defaultMessage: "Quantity",
      }),
      renderCell: (procuredItem) => formatNumber(procuredItem.quantity),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.quantity,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "unit",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:unit",
        defaultMessage: "Unit",
      }),
      renderCell: (procuredItem) => formatString(procuredItem.unit),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.unit,
        comparators.nullsLast(comparators.stringSensitivityBase)
      ),
    },
    {
      key: "spend",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:spend",
        defaultMessage: "Spend",
      }),
      units: "£",
      renderCell: (procuredItem) => formatString(procuredItem.spend),
      sortComparator: comparators.map(
        (procuredItem) =>
          procuredItem.spend ? parseFloat(procuredItem.spend) : null,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "mass",
      label: intl.formatMessage({
        id: "components/scope-3/ProcuredItemsTable:mass",
        defaultMessage: "Mass",
      }),
      units: "kg",
      renderCell: (procuredItem) => formatNumber(procuredItem.massKg),
      sortComparator: comparators.map(
        (procuredItem) => procuredItem.massKg,
        comparators.nullsLast(comparators.number)
      ),
    },
    {
      key: "impact",
      label: effectType.title(intl),
      units: effectType.unit(intl),
      renderCell: (procuredItem) =>
        formatNumber(impactMagnitude(procuredItem, impactCategory)),
      sortComparator: comparators.map(
        (procuredItem) => impactMagnitude(procuredItem, impactCategory),
        comparators.nullsFirst(comparators.number)
      ),
    },
    {
      key: "impact-per-kg",
      label: effectTypePerKg.title(intl),
      units: effectTypePerKg.unit(intl),
      renderCell: (procuredItem) =>
        formatNumber(
          impactPerKg(procuredItem, impactCategory, procuredItem.massKg)
        ),
      sortComparator: comparators.map(
        (procuredItem) =>
          impactPerKg(procuredItem, impactCategory, procuredItem.massKg),
        comparators.nullsFirst(comparators.number)
      ),
    },
  ];

  return (
    <Table<ProcuredItem>
      fullWidth
      columns={columns}
      onSortChange={setSort}
      rowKey={(procuredItem) => procuredItem.internalId}
      rows={data}
      sort={sort}
    />
  );
}
