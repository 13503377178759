import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  foodstepsTurquoiseCol,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { titleOver2Lines } from "./titleOver2Lines";
import {
  impactRatingToName,
  impactCategoryTitle,
  impactRatingToLetter,
} from "./Translations";

const lineHeight = 14;

interface DescriptiveRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  locale: string | null;
  width: number;
}

export default function DescriptiveRatingScale(
  props: DescriptiveRatingScaleProps
) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    locale,
    width,
  } = props;

  const setHeight = (defaultHeight: number) => {
    return titleOver2Lines(impactCategory, locale)
      ? defaultHeight + lineHeight
      : defaultHeight;
  };

  return (
    <svg
      width={width}
      viewBox={`0 0 98 ${setHeight(154)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>

      <rect
        x="1"
        y="23"
        width="96"
        height={setHeight(130)}
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d={`M2 32C2 27.5817 5.58172 24 10 24H88C92.4183 24 96 27.5817 96 32V${setHeight(
          88
        )}H2V32Z`}
        fill="white"
      />
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />

      <text
        x={49}
        y={60}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <Title impactCategory={impactCategory} locale={locale} />

      <rect
        width="94"
        height={setHeight(40)}
        transform={`translate(2 ${setHeight(88)})`}
        fill="white"
      />

      <g
        transform={
          titleOver2Lines(impactCategory, locale)
            ? `translate(0 ${lineHeight})`
            : ""
        }
      >
        <ImpactRatingScale
          greyscale={greyscale}
          impactRating={impactRating}
          locale={locale}
        />

        <path
          d="M2 128H96V144C96 148.418 92.4183 152 88 152H10C5.58172 152 2 148.418 2 144V128Z"
          fill={greyscale ? "black" : foodstepsTurquoiseCol}
        />
        <path
          d="M12.9453 143.672H15.0639V140.05H18.1372V138.454H15.0639V135.983H18.948V134.322H12.9453V143.672Z"
          fill="white"
        />
        <path
          d="M23.0543 142.378C21.9819 142.378 21.3803 141.593 21.3803 140.285V140.18C21.3803 138.873 22.0081 138.114 23.0543 138.114C24.1136 138.114 24.7282 138.899 24.7282 140.207V140.298C24.7282 141.593 24.1136 142.378 23.0543 142.378ZM23.0412 143.816C25.0944 143.816 26.6506 142.443 26.6506 140.285V140.18C26.6506 138.049 25.1075 136.676 23.0543 136.676C21.0011 136.676 19.4448 138.075 19.4448 140.22V140.324C19.4448 142.456 21.0011 143.816 23.0412 143.816Z"
          fill="white"
        />
        <path
          d="M31.1129 142.378C30.0405 142.378 29.4389 141.593 29.4389 140.285V140.18C29.4389 138.873 30.0667 138.114 31.1129 138.114C32.1722 138.114 32.7868 138.899 32.7868 140.207V140.298C32.7868 141.593 32.1722 142.378 31.1129 142.378ZM31.0998 143.816C33.153 143.816 34.7092 142.443 34.7092 140.285V140.18C34.7092 138.049 33.1661 136.676 31.1129 136.676C29.0597 136.676 27.5034 138.075 27.5034 140.22V140.324C27.5034 142.456 29.0597 143.816 31.0998 143.816Z"
          fill="white"
        />
        <path
          d="M38.5438 143.816C39.5508 143.816 40.3877 143.228 40.7408 142.534V143.672H42.624V133.733H40.7408V137.853C40.3616 137.16 39.6815 136.676 38.583 136.676C36.8698 136.676 35.5751 137.996 35.5751 140.246V140.35C35.5751 142.626 36.8829 143.816 38.5438 143.816ZM39.1061 142.338C38.1645 142.338 37.5106 141.671 37.5106 140.311V140.207C37.5106 138.847 38.0991 138.127 39.1584 138.127C40.1916 138.127 40.7931 138.82 40.7931 140.18V140.285C40.7931 141.671 40.1 142.338 39.1061 142.338Z"
          fill="white"
        />
        <path
          d="M46.7921 143.816C48.5968 143.816 49.6692 143.044 49.6692 141.567C49.6692 140.102 48.7276 139.67 47.0144 139.422C46.0205 139.278 45.6936 139.108 45.6936 138.677C45.6936 138.258 46.0467 137.97 46.6613 137.97C47.3152 137.97 47.6291 138.232 47.7337 138.794H49.473C49.303 137.225 48.1653 136.676 46.6483 136.676C45.2489 136.676 43.9412 137.382 43.9412 138.807C43.9412 140.141 44.6474 140.664 46.4652 140.939C47.446 141.083 47.8514 141.279 47.8514 141.75C47.8514 142.221 47.5114 142.495 46.779 142.495C45.9551 142.495 45.6544 142.142 45.5759 141.527H43.8104C43.8758 143.005 44.9482 143.816 46.7921 143.816Z"
          fill="white"
        />
        <path
          d="M53.3696 143.803C53.8796 143.803 54.2589 143.711 54.5204 143.62V142.155C54.2981 142.247 54.0889 142.286 53.8011 142.286C53.3173 142.286 53.0426 142.024 53.0426 141.475V138.18H54.4812V136.833H53.0426V135.355H51.1595V136.833H50.2832V138.18H51.1595V141.645C51.1595 143.071 51.931 143.803 53.3696 143.803Z"
          fill="white"
        />
        <path
          d="M58.9531 143.816C60.8232 143.816 62.0002 142.992 62.2226 141.514H60.4309C60.3132 142.09 59.8817 142.469 59.0055 142.469C57.9723 142.469 57.3577 141.815 57.3054 140.677H62.2356V140.154C62.2356 137.709 60.6663 136.676 58.9008 136.676C56.913 136.676 55.3829 138.075 55.3829 140.233V140.337C55.3829 142.521 56.8869 143.816 58.9531 143.816ZM57.3315 139.513C57.4754 138.546 58.0508 137.983 58.9008 137.983C59.8032 137.983 60.3263 138.48 60.3917 139.513H57.3315Z"
          fill="white"
        />
        <path
          d="M63.4769 146H65.3732V142.626C65.7263 143.293 66.4978 143.816 67.5571 143.816C69.2572 143.816 70.5389 142.548 70.5389 140.285V140.18C70.5389 137.918 69.2311 136.676 67.5571 136.676C66.5502 136.676 65.7524 137.251 65.3732 137.905V136.833H63.4769V146ZM66.9948 142.338C65.9617 142.338 65.3078 141.671 65.3078 140.298V140.194C65.3078 138.82 65.9878 138.127 66.9817 138.127C67.9364 138.127 68.6164 138.807 68.6164 140.194V140.298C68.6164 141.632 68.041 142.338 66.9948 142.338Z"
          fill="white"
        />
        <path
          d="M74.2885 143.816C76.0932 143.816 77.1656 143.044 77.1656 141.567C77.1656 140.102 76.224 139.67 74.5108 139.422C73.5169 139.278 73.1899 139.108 73.1899 138.677C73.1899 138.258 73.543 137.97 74.1577 137.97C74.8116 137.97 75.1254 138.232 75.2301 138.794H76.9694C76.7994 137.225 75.6616 136.676 74.1446 136.676C72.7453 136.676 71.4375 137.382 71.4375 138.807C71.4375 140.141 72.1437 140.664 73.9615 140.939C74.9424 141.083 75.3478 141.279 75.3478 141.75C75.3478 142.221 75.0077 142.495 74.2754 142.495C73.4515 142.495 73.1507 142.142 73.0722 141.527H71.3068C71.3721 143.005 72.4445 143.816 74.2885 143.816Z"
          fill="white"
        />
        <path
          d="M81.6594 136.848L81.6591 136.611C81.6592 136.475 81.7241 136.381 81.8534 136.33C82.3715 136.125 82.6021 135.528 82.3508 135.044C82.3036 134.953 82.2427 134.871 82.1695 134.799C81.9584 134.59 81.7455 134.383 81.5343 134.174C81.5114 134.152 81.4974 134.149 81.4726 134.172C81.3439 134.291 81.2137 134.408 81.0837 134.526C80.9907 134.61 80.8958 134.692 80.804 134.777C80.6159 134.952 80.5118 135.165 80.5002 135.419C80.4876 135.697 80.5844 135.936 80.7845 136.133C80.8726 136.22 80.9768 136.285 81.0939 136.33C81.2219 136.379 81.2922 136.48 81.2923 136.613C81.2924 136.778 81.2925 136.943 81.2922 137.108C81.2922 137.13 81.2975 137.156 81.2679 137.165C81.2386 137.174 81.2279 137.15 81.2139 137.132C81.0416 136.915 80.8181 136.789 80.5353 136.767C80.4535 136.76 80.4537 136.762 80.4615 136.84L80.4618 136.843C80.4793 137.018 80.5388 137.177 80.6458 137.319C80.7651 137.476 80.9221 137.584 81.1118 137.646C81.2294 137.685 81.2919 137.769 81.2923 137.891C81.2926 137.979 81.2923 138.068 81.2923 138.157C81.2923 138.415 81.1188 138.571 80.8599 138.518C80.9766 138.625 81.061 138.693 81.1715 138.72C81.4565 138.79 81.6728 138.879 82.0895 138.523C81.874 138.564 81.6815 138.371 81.6803 138.157C81.6799 138.054 81.6803 137.985 81.6803 137.882C81.6803 137.767 81.7423 137.683 81.8578 137.646C82.0279 137.591 82.1505 137.498 82.2657 137.365C82.3966 137.213 82.472 137.038 82.4862 136.839C82.4918 136.762 82.4915 136.761 82.4129 136.767C82.2011 136.782 82.0174 136.861 81.8597 136.999C81.8102 137.043 81.767 137.092 81.7272 137.143C81.715 137.159 81.7025 137.171 81.6803 137.164C81.6593 137.157 81.6593 137.14 81.6594 137.123V136.848Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.4875 138.58C83.221 138.58 84.6262 137.201 84.6262 135.5C84.6262 133.799 83.221 132.42 81.4875 132.42C79.7541 132.42 78.3489 133.799 78.3489 135.5C78.3489 137.201 79.7541 138.58 81.4875 138.58ZM81.4875 139C83.4573 139 85.0542 137.433 85.0542 135.5C85.0542 133.567 83.4573 132 81.4875 132C79.5177 132 77.9209 133.567 77.9209 135.5C77.9209 137.433 79.5177 139 81.4875 139Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
  locale: string | null;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating, locale } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);
  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating, locale);

  const ratingLetterY = 111;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="12"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path d="M42 94H51V114H42V94Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="54"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="24"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="36"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="48"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M47 94H56V114H47V94Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="60"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M49.209 40.7719C59.9111 40.7719 68.5869 32.0961 68.5869 21.394C68.5869 10.6919 59.9111 2.01611 49.209 2.01611C38.5068 2.01611 29.8311 10.6919 29.8311 21.394C29.8311 32.0961 38.5068 40.7719 49.209 40.7719Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M65.6326 17.6722C65.0187 17.3652 64.4047 17.3652 63.4838 18.2861C63.0924 18.6775 63.1768 19.0535 63.1768 19.821C63.1768 20.0604 62.9339 21.1681 62.6499 21.3559C61.5999 22.05 59.8047 21.7058 59.0326 21.3559C58.3327 21.0382 57.2644 21.1487 56.8838 21.8163C56.2698 22.8908 56.7303 23.9652 57.8047 24.4256C58.4356 24.6958 59.8823 24.675 60.0249 26.25C60.1364 27.4818 58.1117 27.6489 57.9582 29.3373C57.8047 31.0256 56.5937 31.7747 56.0473 33.0885C55.5009 34.4024 55.2921 35.1299 54.6367 35.9848C53.7542 37.1345 52.164 37.687 50.7581 37.3325C50.2331 37.1989 49.7282 36.9441 49.3629 36.5435C48.2746 35.354 48.755 33.4845 48.5678 31.8821C48.4158 30.5928 47.7635 29.368 46.7796 28.5222C45.7006 27.5952 44.3008 27.1501 43.0622 26.4517C41.8235 25.7533 41.0944 24.7725 41.076 23.3497C41.0576 21.913 41.5749 20.174 42.9179 19.666C44.2609 19.1579 47.0498 19.0106 48.4435 19.359C50.2853 19.8195 52.7657 19.9668 53.8156 18.5915C54.2561 18.0144 54.3666 17.0597 53.6621 16.4427C52.4342 15.3683 50.7458 16.7497 49.5179 16.2892C48.6092 15.9485 48.3836 14.4274 46.9086 14.4474C45.4336 14.4673 44.5142 14.5395 43.5318 15.5218C42.6109 16.4427 40.8197 16.4243 40.3086 15.0613C39.8481 13.8334 40.7629 13.2133 41.383 12.759C42.0031 12.3047 42.4262 11.7039 42.6089 10.9564C42.7086 10.5466 42.4574 8.76829 43.6853 8.15434C45.5932 7.20117 45.7298 10.1497 47.369 9.53573C48.5969 9.07527 48.2531 7.83508 48.5969 7.23341C49.2109 6.15899 49.9691 6.02852 50.7458 5.69852C52.3942 4.99708 53.4932 4.34629 54.2791 2.68555C62.1101 4.80215 67.9749 11.703 68.5428 20.0604C68.5428 20.0604 66.2481 17.9776 65.6342 17.6706L65.6326 17.6722Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.5684 13.3697C31.6006 13.3667 31.6344 13.3651 31.6666 13.3605C33.2675 13.1549 34.6044 13.0106 35.8584 11.9929C37.1308 10.96 37.8568 9.56783 38.3142 8.30922C38.6319 7.43434 38.1238 6.65155 37.625 5.86108C35.0249 7.80425 32.9298 10.3844 31.5699 13.3697H31.5684Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.4741 29.7978C34.2761 28.8431 34.0551 28.0987 33.7066 27.1885C33.306 26.1448 33.4334 24.8355 33.4104 23.7181C33.3997 23.1978 33.2462 21.9699 32.7857 21.3559C31.9906 20.2953 31.2508 19.6676 29.9738 19.0398C29.8802 19.8118 29.8311 20.5962 29.8311 21.3943C29.8311 26.0327 31.4626 30.2905 34.1809 33.6273C34.1809 33.6273 34.2607 33.5214 34.4158 33.3096C35.1694 32.2367 34.7411 31.081 34.4741 29.7978Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M55.3101 31.6599H34.0601C37.6001 37.2599 43.8401 40.9899 50.9501 40.9899C54.7201 40.9899 58.2301 39.9299 61.2301 38.1199L55.3101 31.6599Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.48 40.6601C63.84 38.9901 70.96 30.8301 70.96 20.9901C70.96 11.1501 63.84 2.99007 54.48 1.32007V40.6601Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M54.48 1.32007V20.7701H70.95C70.84 11.0301 63.78 2.98007 54.48 1.32007Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M36.0002 25.7001V31.6701H41.1102V25.7001C41.1102 25.0601 41.5502 24.5401 42.1002 24.5401H47.2202C48.6302 24.5401 49.3102 22.8101 48.2702 21.8501L43.7102 17.6401H45.4702C46.8802 17.6401 47.5602 15.9101 46.5202 14.9501L42.4402 11.1801H43.5602C44.9702 11.1801 45.6502 9.45012 44.6102 8.49012L39.9702 4.41012C39.1602 3.69012 37.9402 3.69012 37.1202 4.41012L32.4802 8.49012C31.4402 9.45012 32.1202 11.1801 33.5302 11.1801H34.6502L30.5702 14.9501C29.5302 15.9101 30.2102 17.6401 31.6202 17.6401H33.3802L28.8202 21.8501C27.7802 22.8101 28.4602 24.5401 29.8702 24.5401H34.9902C35.5402 24.5401 35.9802 25.0601 35.9802 25.7001H36.0002Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M50.9502 40.99C58.1902 40.99 64.5102 37.13 68.0202 31.37C62.1902 37.16 57.2402 28 57.2402 28C49.7502 39.47 43.9702 27.29 43.9702 27.29C40.7202 31.44 37.6502 32.91 34.9502 32.96C38.6002 37.82 44.3902 40.99 50.9402 40.99H50.9502Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M52.3198 19.5301C52.3198 16.2901 56.0498 9.72006 56.0498 9.72006C56.4498 8.84006 57.8298 8.84006 58.2298 9.72006C58.2298 9.72006 61.9598 16.1801 61.9598 19.5301C61.9598 22.1901 59.7998 24.6801 57.1398 24.6801C54.4798 24.6801 52.3198 22.1901 52.3198 19.5301Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.6802 24.3099C27.6802 17.4299 35.6002 3.45994 35.6002 3.45994C36.4502 1.59994 39.3802 1.59994 40.2302 3.45994C40.2302 3.45994 48.1502 17.1899 48.1502 24.3099C48.1502 29.9599 43.5702 35.2499 37.9102 35.2499C32.2502 35.2499 27.6802 29.9599 27.6802 24.3099Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 10;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 12;
  }
  return 13;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === "VLOW") {
    return 14;
  }
  return 16;
}

interface TitleProps {
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Title(props: TitleProps) {
  const { impactCategory, locale } = props;

  const titleString = impactCategoryTitle(impactCategory, locale);

  const y = 78;

  const commonProps = {
    y,
    x: 49,
    textAnchor: "middle",
    fontFamily: "Graphik Web",
    fontWeight: 500,
    fontSize: impactCategoryTitleFontSize(impactCategory, locale),
    fill: "black",
  };

  if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    const words = titleString.split(" ");
    const firstWord = words.shift();
    return (
      <>
        <text {...commonProps}>{firstWord}</text>
        <text {...commonProps} y={y + lineHeight}>
          {words.join(" ")}
        </text>
      </>
    );
  } else {
    return <text {...commonProps}>{titleString}</text>;
  }
}
