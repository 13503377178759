import gql from "graphql-tag";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  impactCategoryToEffectTypePerKg,
  useImpactCategoryEffectTypePerPortion,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import { getImpactCategoryRating } from "../../domain/impactRatings";
import useImpactRatingInfos from "../../services/useImpactRatingInfos";
import {
  useAdditionalImpactCategories,
  useLabels,
  useOrganizationSystemBoundaryIsCradleToGrave,
} from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";
import CarbonLabelsSalesPrompt from "../labels/CarbonLabelsSalesPrompt";
import { getImpactMagnitude } from "../labels/helperFunctions";
import RecipePageLabel from "../labels/RecipePageLabel";
import StatusDisplay from "../StatusDisplay";
import LargeNumber from "../typography/LargeNumber";
import Number from "../typography/Number";
import { Panel } from "../utils/Panel";
import TooltipOverlay from "../utils/TooltipOverlay";
import HotDrink from "../utils/Vectors/HotDrink";
import { SmartphoneCharges } from "../utils/Vectors/illustrations";
import {
  FoodServiceImpactSummary_Recipe,
  FoodServiceImpactSummary_RecipeImpact,
} from "./FoodServiceImpactSummary.graphql";
import ImpactCategoryInlineTitleToggle from "./ImpactCategoryInlineTitleToggle";
import ImpactRatingExplanation from "./ImpactRatingExplanation";
import "./FoodServiceImpactSummary.css";

interface FoodServiceImpactSummaryProps {
  impact: FoodServiceImpactSummary_RecipeImpact;
  recipe: FoodServiceImpactSummary_Recipe;
  showExportButton: boolean;
}

export default function FoodServiceImpactSummary(
  props: FoodServiceImpactSummaryProps
) {
  const { impact, recipe, showExportButton } = props;

  const labels = useLabels();
  const intl = useIntl();
  const showCradleToGraveImpactRating =
    useOrganizationSystemBoundaryIsCradleToGrave();
  const { status: impactRatingInfosStatus } = useImpactRatingInfos();

  const [impactCategory, setImpactCategory] = useState<ImpactCategory>(
    ImpactCategory.GHG
  );

  const HotDrinkTooltip = () => (
    <TooltipOverlay
      id="components/recipes/FoodServiceImpactSummary:hotDrinkIconTooltip"
      overlay={
        <FormattedMessage
          id="components/recipes/FoodServiceImpactSummary:hotDrinkIconTooltipMessage"
          defaultMessage="Hot drinks use a unique A - E per serving rating scale to account for dilution"
        />
      }
      placement="top"
    >
      <HotDrink width={32} />
    </TooltipOverlay>
  );

  const impactMagnitude = getImpactMagnitude(
    impactCategory,
    recipe.isHotDrink,
    impact.effects
  );
  const impactRating = getImpactCategoryRating(impact, impactCategory);

  const additionalImpactCategories = useAdditionalImpactCategories();

  return (
    <Panel className="FoodServiceImpactSummary_Panel">
      <div className="FoodServiceImpactSummary_Title">
        {additionalImpactCategories ? (
          <>
            <h3 className="mb-0">
              {intl.formatMessage({
                id: "components/recipes/FoodServiceImpactSummary:impactSummaryOf",
                defaultMessage: "Impact Summary of",
              })}
            </h3>
            <ImpactCategoryInlineTitleToggle
              className="FoodServiceImpactSummary_ImpactCategoryInlineTitleToggle"
              onChange={setImpactCategory}
              value={impactCategory}
            />
          </>
        ) : (
          <h3 className="mb-0">
            <FormattedMessage
              id="components/recipes/FoodServiceImpactSummary:impactSummaryOfGhgEmissions"
              defaultMessage="Impact Summary of GHG Emissions"
            />
          </h3>
        )}
      </div>
      <div className="FoodServiceImpactSummary_Body">
        <RecipeSummary impact={impact} impactCategory={impactCategory} />
        {showCradleToGraveImpactRating && (
          <>
            <div className="FoodServiceImpactSummary_GhgRating">
              <div className="FoodServiceImpactSummary_GhgRating_Header">
                {recipe.isHotDrink ? (
                  <div className="mt-n2 d-flex mr-2">
                    <HotDrinkTooltip />
                  </div>
                ) : null}
                <div className="medium-font">
                  {intl.formatMessage({
                    id: "components/recipes/FoodServiceImpactSummary:labelMeaningTitle",
                    defaultMessage: "Rating",
                  })}
                </div>
              </div>
              <StatusDisplay status={impactRatingInfosStatus}>
                {({ impactRatingInfos }) => (
                  <ImpactRatingExplanation
                    impact={impact}
                    impactCategory={impactCategory}
                    impactRatingInfos={impactRatingInfos}
                    isHotDrink={recipe.isHotDrink}
                  />
                )}
              </StatusDisplay>
            </div>
            <div className="FoodServiceImpactSummary_Label_Container">
              {labels && impactMagnitude && impactRating ? (
                <div className="FoodServiceImpactSummary_Label">
                  <div className="medium-font">
                    {intl.formatMessage({
                      id: "components/recipes/FoodServiceImpactSummary:labelTitle",
                      defaultMessage: "Label",
                    })}
                  </div>
                  <RecipePageLabel
                    impactMagnitude={impactMagnitude}
                    impactRating={impactRating}
                    impactCategory={impactCategory}
                    recipeId={recipe.id}
                    showExportButton={showExportButton}
                  />
                </div>
              ) : (
                <CarbonLabelsSalesPrompt />
              )}
            </div>
          </>
        )}
      </div>
    </Panel>
  );
}

interface RecipeSummaryProps {
  impact: FoodServiceImpactSummary_RecipeImpact;
  impactCategory: ImpactCategory;
}

function RecipeSummary(props: RecipeSummaryProps) {
  const { impact, impactCategory } = props;

  const intl = useIntl();
  const impactCategoryEffectTypePerPortion =
    useImpactCategoryEffectTypePerPortion;

  const effectTypePerKg = impactCategoryToEffectTypePerKg(impactCategory);
  const effectTypePerPortion =
    impactCategoryEffectTypePerPortion(impactCategory);
  const impactPerKg = effectTypePerKg.get(impact);
  const impactPerPortion = effectTypePerPortion.get(impact);

  const equivalentSymbol = () => {
    if (impactCategory === ImpactCategory.GHG) {
      return <SmartphoneCharges width={48} />;
    } else if (impactCategory === ImpactCategory.LAND_USE) {
      return null;
    } else if (impactCategory === ImpactCategory.WATER_USE) {
      return null;
    } else {
      assertNever(impactCategory, "Invalid ImpactCategory");
    }
  };

  return (
    <div className="FoodServiceImpactSummary_RecipeSummary">
      <div className="FoodServiceImpactSummary_RecipeSummary_Impacts">
        <div>
          <LargeNumber>
            {impactPerKg?.toFixed(effectTypePerKg.decimals)}
          </LargeNumber>
          <small>{effectTypePerKg.unit(intl)}</small>
        </div>
        <div>
          <LargeNumber>
            {impactPerPortion?.toFixed(effectTypePerPortion.decimals)}
          </LargeNumber>
          <small>{effectTypePerPortion.unit(intl)}</small>
        </div>
      </div>

      {impactPerPortion !== null && effectTypePerPortion.equivalent && (
        <div className="FoodServiceImpactSummary_RecipeSummary_Equivalent">
          <small className="medium-font">
            <FormattedMessage
              id="components/recipes/FoodServiceImpactSummary:equivalentTo"
              defaultMessage="equivalent to"
            />
          </small>
          <div className="FoodServiceImpactSummary_RecipeSummary_Equivalent_SymbolAndDataContainer">
            {equivalentSymbol()}
            <div className="FoodServiceImpactSummary_RecipeSummary_Equivalent_DataContainer">
              <Number>
                {(
                  impactPerPortion / effectTypePerPortion.equivalent.value
                ).toFixed(1)}
              </Number>
              <small>
                {effectTypePerPortion.equivalent.title(intl)}{" "}
                <FormattedMessage
                  id="components/recipes/FoodServiceImpactSummary:per"
                  defaultMessage="per"
                />{" "}
                {effectTypePerPortion.per(intl)}
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

FoodServiceImpactSummary.fragments = {
  impact: gql`
    fragment FoodServiceImpactSummary_RecipeImpact on RecipeImpact {
      effects {
        ghgPerKg
        ghgPerRootRecipeServing
        landUsePerKg
        landUsePerRootRecipeServing
        waterUsePerKg
        waterUsePerRootRecipeServing
      }
      errors {
        kind
      }
      impactRatingGhg
      impactRatingLandUse
      impactRatingWaterUse
      ...ImpactRatingExplanation_RecipeImpact
    }
    ${ImpactRatingExplanation.fragments.recipeImpact}
  `,

  recipe: gql`
    fragment FoodServiceImpactSummary_Recipe on Recipe {
      id
      isHotDrink
    }
  `,
};
