import { ImpactCategory } from "../../domain/impactCategories";
import ActionBar from "./ActionBar";
import { RecipeSelectionType } from "./RecipesPanel";

const stories = {
  name: "ActionBar",
  render: () => <Story />,
};

function Story() {
  return (
    <div className="m-4">
      <ActionBar
        allProductsIncludingUnseenSelected={false}
        allProductsInViewSelected={false}
        impactCategory={ImpactCategory.GHG}
        recipeFilter={{}}
        recipeSelection={{ type: RecipeSelectionType.PARTIAL, ids: [1, 2, 3] }}
        requiresAttentionIds={[2]}
        onCollectionsDeleted={(ids) => alert(`Deleted ids: ${ids.toString()}`)}
        onDelete={() => alert("Delete")}
        onSelectAllProducts={() => alert("Select all products")}
        pageName="Recipes"
        setToastState={() => alert("Show copy toast")}
        totalRecipeCount={100}
      />
    </div>
  );
}

export default stories;
