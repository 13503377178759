import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import {
  RecipeOrder,
  PackagingComponentV2Order,
  FoodClassOrder,
} from "../__generated__/globalTypes";
import commonRoutes from "../common-routes.json";
import { useTracking } from "../tracking";
import {
  parseIngredientsOrder,
  OrderBy as IngredientsOrderBy,
} from "./ingredients/IngredientsQueryControls";
import {
  parsePackagingOrder,
  OrderBy as PackagingOrderBy,
} from "./packaging/PackagingQueryControls";
import { useHasRecipePermissionAddAndEditProductsFeature } from "./permissions";
import {
  parseRecipeOrder,
  OrderBy as RecipeOrderBy,
} from "./recipes/RecipeQueryControls";
import useRecipeLabel from "./recipes/useRecipeLabel";
import createQueryStringState from "./utils/createQueryStringState";
import { State as GridListState } from "./utils/GridListToggle";
import {
  Feedback,
  Help,
  LifeCycle,
  Dashboard as DashboardIcon,
  Shared,
} from "./utils/Vectors";

export const NAV_ICON_WIDTH = 20;

const recipesListCollectionQueryStringState = createQueryStringState<number[]>({
  key: "filterToSelectedCollectionIds",
  parse: (value) =>
    value !== "" ? value.split(",").map((x) => Number(x)) : [],
  serialise: (value) => (value ? value.toString() : ""),
  defaultValue: [],
});

const recipesListFilterToRequiresClientAttentionStringState =
  createQueryStringState<boolean>({
    key: "requiresClientAttention",
    parse: (value) => !!value,
    serialise: (value) => (value ? "true" : "false"),
    defaultValue: false,
  });

const recipesListFilterToSelectedGhgImpactRatingsStringState =
  createQueryStringState<Array<string>>({
    key: "filterToSelectedGhgImpactRatings",
    parse: (value) => (value !== "" ? value.split(",") : []),
    serialise: (value) => value.join(","),
    defaultValue: [],
  });

const recipesListFilterToSelectedLandUseImpactRatingsStringState =
  createQueryStringState<Array<string>>({
    key: "filterToSelectedLandUseImpactRatings",
    parse: (value) => (value !== "" ? value.split(",") : []),
    serialise: (value) => value.join(","),
    defaultValue: [],
  });

const recipesListFilterToSelectedWaterUseImpactRatingsStringState =
  createQueryStringState<Array<string>>({
    key: "filterToSelectedWaterUseImpactRatings",
    parse: (value) => (value !== "" ? value.split(",") : []),
    serialise: (value) => value.join(","),
    defaultValue: [],
  });

const recipesListOrderByQueryStringState =
  createQueryStringState<RecipeOrderBy>({
    key: "orderBy",
    parse: parseRecipeOrder,
    serialise: (value) => value,
    defaultValue: RecipeOrder.NAME_ASC,
  });
const recipesListSearchTermQueryStringState = createQueryStringState<string>({
  key: "searchTerm",
  parse: (value) => value,
  serialise: (value) => value,
  defaultValue: "",
});
const recipesListIntersectCollectionsQueryStringState =
  createQueryStringState<boolean>({
    key: "intersectCollections",
    parse: (value) => value === "true",
    serialise: (value) => (value === true ? "true" : ""),
    defaultValue: false,
  });

const recipeListDietQueryStringState = createQueryStringState<Array<string>>({
  key: "filterToSelectedDiets",
  parse: (value) => value.split(","),
  serialise: (value) => value.join(","),
  defaultValue: [],
});

const stringToGridListState = (value: string): GridListState => {
  if (value === "list") {
    return "list";
  }
  return "grid";
};

const recipesViewQueryStringState = createQueryStringState<GridListState>({
  key: "view",
  parse: stringToGridListState,
  serialise: (value) => value,
  defaultValue: "grid",
});

export type RecipesListPageQueryParams = {
  filterToSelectedCollectionIds: Array<number>;
  filterToRequiresClientAttention: boolean;
  intersectCollections: boolean;
  filterToSelectedGhgImpactRatings: Array<string>;
  filterToSelectedLandUseImpactRatings: Array<string>;
  filterToSelectedWaterUseImpactRatings: Array<string>;
  filterToSelectedDiets: Array<string>;
  orderBy: RecipeOrderBy;
  searchTerm: string;
  view: GridListState;
};

const packagingComponentsListSearchTermQueryStringState =
  createQueryStringState<string>({
    key: "searchTerm",
    parse: (value) => value,
    serialise: (value) => value,
    defaultValue: "",
  });

const packagingComponentsListOrderByQueryStringState =
  createQueryStringState<PackagingOrderBy>({
    key: "orderBy",
    parse: parsePackagingOrder,
    serialise: (value) => value,
    defaultValue: PackagingComponentV2Order.NAME_ASC,
  });

export type PackagingComponentsListPageQueryParams = {
  orderBy: PackagingOrderBy;
  searchTerm: string;
};

const ingredientsListSearchTermQueryStringState =
  createQueryStringState<string>({
    key: "searchTerm",
    parse: (value) => value,
    serialise: (value) => value,
    defaultValue: "",
  });

const ingredientsListOrderByQueryStringState =
  createQueryStringState<IngredientsOrderBy>({
    key: "orderBy",
    parse: parseIngredientsOrder,
    serialise: (value) => value,
    defaultValue: FoodClassOrder.NAME_ASC,
  });

export type IngredientsListPageQueryParams = {
  orderBy: IngredientsOrderBy;
  searchTerm: string;
};

export type Scope3PageQueryParams = {
  assessmentId: string;
  viewHighestImpactItems: boolean;
};

const scope3AssessmentIdQueryStringState = createQueryStringState<string>({
  key: "assessmentId",
  parse: (value) => value,
  serialise: (value) => value,
  defaultValue: "",
});

const scope3ViewHighestImpactItemsQueryStringState =
  createQueryStringState<boolean>({
    key: "viewHighestImpactItems",
    parse: (value) => !!value,
    serialise: (value) => (value ? "true" : "false"),
    defaultValue: false,
  });

export function buildPath(url: string, queryParams: string): string {
  return `${url}?${queryParams}`;
}

export function usePages() {
  const intl = useIntl();
  const hasRecipePermissionAddAndEditProductsFeature =
    useHasRecipePermissionAddAndEditProductsFeature();
  const recipeLabel = useRecipeLabel();
  const { trackBulkRecipeUploadStarted } = useTracking();

  const notFoundTitle = intl.formatMessage({
    id: "components/pages:NotFound/title",
    defaultMessage: "Not Found",
  });

  // The below breadcrumb pages are not actual pages, and so just exist as a title for the breadcrumb.
  const productFootprintsBreadcrumb = {
    title: intl.formatMessage({
      id: "components/pages:ProductFootprintsBreadcrumbPage/title",
      defaultMessage: "Product Footprints",
    }),
  };

  const inputsBreadcrumb = {
    title: intl.formatMessage({
      id: "components/pages:InputsBreadcrumbPage/title",
      defaultMessage: "Inputs",
    }),
  };

  const corporateReportingBreadcrumb = {
    title: intl.formatMessage({
      id: "components/pages:CorporateReportingBreadcrumbPage/title",
      defaultMessage: "Corporate Reporting",
    }),
  };

  const reportsBreadcrumb = {
    title: intl.formatMessage({
      id: "components/pages:ReportsBreadcrumbPage/title",
      defaultMessage: "Reports",
    }),
  };

  const ghgEmissionsBreadcrumb = {
    title: intl.formatMessage({
      id: "components/pages:GhgEmissionsBreadcrumbPage/title",
      defaultMessage: "GHG Emissions",
    }),
  };

  const addPackagingComponentTitle = intl.formatMessage({
    id: "components/packaging/PackagingPage:addPackagingComponentButtonLabel",
    defaultMessage: "Add Custom Component",
  });

  const addRecipeTitle = intl.formatMessage(
    {
      id: "components/pages:AddRecipe/title",
      defaultMessage: "Add New {recipeLabel}",
    },
    { recipeLabel: recipeLabel.singularUppercase }
  );

  const RecipesListPage = {
    stringifyQueryParams(
      queryParams: RecipesListPageQueryParams | null
    ): string {
      if (queryParams === null) {
        return "";
      }

      const searchParams = new URLSearchParams([
        ...recipesListCollectionQueryStringState.toSearchParams(
          queryParams.filterToSelectedCollectionIds
        ),
        ...recipeListDietQueryStringState.toSearchParams(
          queryParams.filterToSelectedDiets
        ),
        ...recipesListOrderByQueryStringState.toSearchParams(
          queryParams.orderBy
        ),
        ...recipesListSearchTermQueryStringState.toSearchParams(
          queryParams.searchTerm
        ),
        ...recipesViewQueryStringState.toSearchParams(queryParams.view),
      ]);

      return searchParams.toString();
    },

    useQueryParams: (): [
      RecipesListPageQueryParams,
      (update: Partial<RecipesListPageQueryParams>) => void
    ] => {
      const [filterToSelectedCollectionIds, setFilterToSelectedCollectionIds] =
        recipesListCollectionQueryStringState.useState();
      const [
        filterToRequiresClientAttention,
        setFilterToRequiresClientAttention,
      ] = recipesListFilterToRequiresClientAttentionStringState.useState();
      const [orderBy, setOrderBy] =
        recipesListOrderByQueryStringState.useState();
      const [searchTerm, setSearchTerm] =
        recipesListSearchTermQueryStringState.useState();
      const [view, setView] = recipesViewQueryStringState.useState();

      const [intersectCollections, setIntersectCollections] =
        recipesListIntersectCollectionsQueryStringState.useState();

      const [
        filterToSelectedGhgImpactRatings,
        setFilterToSelectedGhgImpactRatings,
      ] = recipesListFilterToSelectedGhgImpactRatingsStringState.useState();
      const [
        filterToSelectedLandUseImpactRatings,
        setFilterToSelectedLandUseImpactRatings,
      ] = recipesListFilterToSelectedLandUseImpactRatingsStringState.useState();
      const [
        filterToSelectedWaterUseImpactRatings,
        setFilterToSelectedWaterUseImpactRatings,
      ] =
        recipesListFilterToSelectedWaterUseImpactRatingsStringState.useState();
      const [filterToSelectedDiets, setFilterToSelectedDiets] =
        recipeListDietQueryStringState.useState();

      const set = (update: Partial<RecipesListPageQueryParams>) => {
        if (update.filterToSelectedCollectionIds !== undefined) {
          setFilterToSelectedCollectionIds(
            update.filterToSelectedCollectionIds
          );
        }
        if (update.filterToRequiresClientAttention !== undefined) {
          setFilterToRequiresClientAttention(
            update.filterToRequiresClientAttention
          );
        }
        if (update.filterToSelectedGhgImpactRatings !== undefined) {
          setFilterToSelectedGhgImpactRatings(
            update.filterToSelectedGhgImpactRatings
          );
        }
        if (update.filterToSelectedLandUseImpactRatings !== undefined) {
          setFilterToSelectedLandUseImpactRatings(
            update.filterToSelectedLandUseImpactRatings
          );
        }
        if (update.filterToSelectedWaterUseImpactRatings !== undefined) {
          setFilterToSelectedWaterUseImpactRatings(
            update.filterToSelectedWaterUseImpactRatings
          );
        }
        if (update.orderBy !== undefined) {
          setOrderBy(update.orderBy);
        }
        if (update.searchTerm !== undefined) {
          setSearchTerm(update.searchTerm);
        }
        if (update.intersectCollections !== undefined) {
          setIntersectCollections(update.intersectCollections);
        }
        if (update.filterToSelectedDiets !== undefined) {
          setFilterToSelectedDiets(update.filterToSelectedDiets);
        }
        if (update.view !== undefined) {
          setView(update.view);
        }
      };

      return [
        {
          filterToSelectedCollectionIds,
          filterToRequiresClientAttention,
          intersectCollections,
          filterToSelectedGhgImpactRatings,
          filterToSelectedLandUseImpactRatings,
          filterToSelectedWaterUseImpactRatings,
          orderBy,
          searchTerm,
          view,
          filterToSelectedDiets,
        },
        set,
      ];
    },
  };

  const PackagingComponentsListPage = {
    stringifyQueryParams(
      queryParams: PackagingComponentsListPageQueryParams | null
    ): string {
      if (queryParams === null) {
        return "";
      }

      const searchParams = new URLSearchParams([
        ...packagingComponentsListOrderByQueryStringState.toSearchParams(
          queryParams.orderBy
        ),
        ...packagingComponentsListSearchTermQueryStringState.toSearchParams(
          queryParams.searchTerm
        ),
      ]);

      return searchParams.toString();
    },

    useQueryParams: (): [
      PackagingComponentsListPageQueryParams,
      (update: Partial<PackagingComponentsListPageQueryParams>) => void
    ] => {
      const [orderBy, setOrderBy] =
        packagingComponentsListOrderByQueryStringState.useState();

      const [searchTerm, setSearchTerm] =
        packagingComponentsListSearchTermQueryStringState.useState();

      const set = (update: Partial<PackagingComponentsListPageQueryParams>) => {
        if (update.orderBy !== undefined) {
          setOrderBy(update.orderBy);
        }

        if (update.searchTerm !== undefined) {
          setSearchTerm(update.searchTerm);
        }
      };

      return [{ orderBy, searchTerm }, set];
    },
  };

  // This needs to be a function as we need to pass in whether the org has access to this page.
  // This is because this component is first rendered immediately on page load, which is before
  // we have the access to the organization context. Therefore, we decide if the organization
  // has access in-place where this page is used and pass in that information then.
  const Dashboard = (locked: boolean = false) => ({
    title: intl.formatMessage({
      id: "components/pages:Dashboard/title",
      defaultMessage: "Dashboard",
    }),
    url: "/",
    symbol: <DashboardIcon width={NAV_ICON_WIDTH} />,
    locked,
    lockedMessage: {
      message: (
        <FormattedMessage
          id="components/pages:dashboardLockedMessage"
          defaultMessage="Access dashboard insights on your product range"
        />
      ),
      maxWidth: "188px",
    },
    breadcrumb: () => [
      productFootprintsBreadcrumb,
      { title: Dashboard().title, url: Dashboard().url },
    ],
  });

  const Recipes = {
    title: intl.formatMessage({
      id: "components/pages:Recipes/title",
      defaultMessage: "Products",
    }),
    url: "/products",
    breadcrumb: (queryParams: RecipesListPageQueryParams | null = null) => {
      return [
        productFootprintsBreadcrumb,
        {
          title: Recipes.title,
          url: buildPath(
            Recipes.url,
            RecipesListPage.stringifyQueryParams(queryParams)
          ),
        },
      ];
    },
  };

  const Recipe = {
    url: (recipe: { id: number }) => `${Recipes.url}/${recipe.id}`,
    urlTemplate: `${Recipes.url}/:recipeId([0-9]+)`,
    useParams: () => {
      const params = useParams<{ recipeId: string }>();
      const recipeId = parseInt(params.recipeId);
      return { recipeId };
    },
    breadcrumb: (
      recipe: { id: number; name: string } | null,
      recipesListPageQueryParams: RecipesListPageQueryParams | null
    ) => [
      ...Recipes.breadcrumb(recipesListPageQueryParams),
      recipe === null
        ? { title: notFoundTitle }
        : { title: recipe.name, url: Recipe.url(recipe) },
    ],
  };

  const RecipeEdit = {
    url: (recipe: { id: number }) => `${Recipes.url}/${recipe.id}/edit`,
    urlTemplate: `${Recipes.url}/:recipeId([0-9]+)/edit`,
    useParams: () => {
      const params = useParams<{ recipeId: string }>();
      const recipeId = parseInt(params.recipeId);
      return { recipeId };
    },
  };

  const RecipesNew = {
    title: addRecipeTitle,
    url: hasRecipePermissionAddAndEditProductsFeature ? "/products/new" : null,
  };

  const RecipesUpload = {
    title: intl.formatMessage(
      {
        id: "components/pages:RecipesUpload",
        defaultMessage: "Upload {recipeType}",
      },
      {
        recipeType: recipeLabel.pluralUppercase,
      }
    ),
    url: hasRecipePermissionAddAndEditProductsFeature
      ? `${Recipes.url}/upload`
      : null,
    breadcrumb: () => {
      const recipeUploadUrl = RecipesUpload.url;
      if (recipeUploadUrl === null) {
        throw new Error("cannot access recipes upload page");
      } else {
        trackBulkRecipeUploadStarted();
        return [
          ...Recipes.breadcrumb(),
          {
            title: RecipesUpload.title,
            url: recipeUploadUrl,
          },
        ];
      }
    },
  };

  const Collections = (locked: boolean = false) => ({
    title: intl.formatMessage({
      id: "components/pages:Collections/title",
      defaultMessage: "Collections",
    }),
    url: "/products/collections",
    breadcrumb: () => [
      productFootprintsBreadcrumb,
      { title: Collections().title, url: Collections().url },
    ],
    locked,
    lockedMessage: {
      message: (
        <FormattedMessage
          id="components/pages:collectionsLockedMessage"
          defaultMessage="Categorise your products into collections"
        />
      ),
      maxWidth: "186px",
    },
  });

  const Collection = {
    url: (collection: { id: number }) =>
      `/products/collections/${collection.id}`,
    urlTemplate: "/products/collections/:collectionId([0-9]+)",
    useParams: () => {
      const params = useParams<{ collectionId: string }>();
      const collectionId = parseInt(params.collectionId);
      return { collectionId };
    },
    breadcrumb: (
      collection: { id: number; name: string } | null,
      queryParams: RecipesListPageQueryParams | null
    ) => {
      return [
        ...Collections().breadcrumb(),
        collection === null
          ? { title: notFoundTitle }
          : {
              title: collection.name,
              url: buildPath(
                Collection.url(collection),
                RecipesListPage.stringifyQueryParams(queryParams)
              ),
            },
      ];
    },
    breadcrumbNotFound: () => Collection.breadcrumb(null, null),
  };

  const CollectionRecipe = () => ({
    url: (collection: { id: number }, recipe: { id: number }) =>
      `/products/collections/${collection.id}/products/${recipe.id}`,
    urlTemplate:
      "/products/collections/:collectionId([0-9]+)/products/:recipeId([0-9]+)",
    useParams: () => {
      const params = useParams<{ collectionId: string; recipeId: string }>();
      const collectionId = parseInt(params.collectionId);
      const recipeId = parseInt(params.recipeId);
      return { collectionId, recipeId };
    },
    breadcrumb: (
      collection: { id: number; name: string } | null,
      recipe: { id: number; name: string } | null,
      recipesListPageQueryParams: RecipesListPageQueryParams | null
    ) => [
      ...Collection.breadcrumb(collection, recipesListPageQueryParams),
      recipe === null
        ? { title: notFoundTitle }
        : { title: recipe.name, url: Recipe.url(recipe) },
    ],
  });

  const CollectionRecipesNew = {
    url: hasRecipePermissionAddAndEditProductsFeature
      ? (collection: { id: number }) => `${Collection.url(collection)}/new`
      : null,
    urlTemplate: hasRecipePermissionAddAndEditProductsFeature
      ? `${Collection.urlTemplate}/new`
      : null,
    useParams: Collection.useParams,
    breadcrumb: (
      collection: { id: number; name: string } | null,
      recipesListPageQueryParams: RecipesListPageQueryParams | null
    ) => [
      ...Collection.breadcrumb(collection, recipesListPageQueryParams),
      {
        title: addRecipeTitle,
        url:
          collection === null || CollectionRecipesNew.url === null
            ? undefined
            : CollectionRecipesNew.url(collection),
      },
    ],
  };

  const AccountDetails = {
    title: intl.formatMessage({
      id: "components/pages:AccountDetails/title",
      defaultMessage: "Profile",
    }),
    url: "/profile",
    breadcrumb: () => [
      { title: AccountDetails.title, url: AccountDetails.url },
    ],
  };

  const CalculationOptions = {
    title: intl.formatMessage({
      id: "components/pages:CalculationOptions/title",
      defaultMessage: "Calculation Options",
    }),
    url: "/options",
    symbol: <LifeCycle width={NAV_ICON_WIDTH} />,
  };

  const CalculationTable = {
    url: `/calculation-table/`,
    useQueryParams: (): {
      subjectId: string;
      subjectType: string;
    } => {
      const { search } = useLocation();
      const searchParams = new URLSearchParams(search);
      const subjectId = searchParams.get("subjectId");
      const subjectType = searchParams.get("subjectType");

      if (subjectId === null) {
        throw new Error("Must provide a subjectId query parameter.");
      } else if (subjectType === null) {
        throw new Error("Must provide a subjectType query parameter.");
      } else {
        return {
          subjectId,
          subjectType,
        };
      }
    },
  };

  const Ingredients = {
    title: intl.formatMessage({
      id: "components/pages:Ingredients/title",
      defaultMessage: "Ingredients",
    }),
    url: "/inputs/ingredients",
    breadcrumb: () => [
      productFootprintsBreadcrumb,
      inputsBreadcrumb,
      { title: Ingredients.title, url: Ingredients.url },
    ],
  };

  const IngredientsListPage = {
    stringifyQueryParams(
      queryParams: IngredientsListPageQueryParams | null
    ): string {
      if (queryParams === null) {
        return "";
      }

      const searchParams = new URLSearchParams([
        ...ingredientsListOrderByQueryStringState.toSearchParams(
          queryParams.orderBy
        ),
        ...ingredientsListSearchTermQueryStringState.toSearchParams(
          queryParams.searchTerm
        ),
      ]);

      return searchParams.toString();
    },

    useQueryParams: (): [
      IngredientsListPageQueryParams,
      (update: Partial<IngredientsListPageQueryParams>) => void
    ] => {
      const [orderBy, setOrderBy] =
        ingredientsListOrderByQueryStringState.useState();

      const [searchTerm, setSearchTerm] =
        ingredientsListSearchTermQueryStringState.useState();

      const set = (update: Partial<IngredientsListPageQueryParams>) => {
        if (update.orderBy !== undefined) {
          setOrderBy(update.orderBy);
        }

        if (update.searchTerm !== undefined) {
          setSearchTerm(update.searchTerm);
        }
      };

      return [{ orderBy, searchTerm }, set];
    },
  };

  const ContactUs = {
    title: intl.formatMessage({
      id: "components/pages:ContactUs/title",
      defaultMessage: "Contact Support",
    }),
    url: "/contact-support",
    symbol: <Feedback width={NAV_ICON_WIDTH} />,
    breadcrumb: () => [{ title: ContactUs.title, url: ContactUs.url }],
  };

  const Community = {
    title: intl.formatMessage({
      id: "components/pages:Community/title",
      defaultMessage: "Community",
    }),
    url: "https://foodsteps.circle.so",
    symbol: <Help width={NAV_ICON_WIDTH} />,
  };

  const ResetPassword = {
    title: intl.formatMessage({
      id: "components/pages:ResetPassword/title",
      defaultMessage: "Reset Password",
    }),
    url: `/${commonRoutes.RESET_PASSWORD}/:uid/:token`,
    urlTemplate: `/${commonRoutes.RESET_PASSWORD}/:uid/:token`,
    useParams: () => {
      const params = useParams<{ uid: string; token: string }>();
      const uid = params.uid;
      const token = params.token;
      return { uid, token };
    },
  };

  const SignUp = {
    title: intl.formatMessage({
      id: "components/pages:SignUp/title",
      defaultMessage: "Sign Up",
    }),
    url: `/${commonRoutes.SIGN_UP}`,
    useQueryParams: (): {
      parentOrganizationId: string | null;
      platformLanguage: string | null;
      signUpOverride: string | null;
    } => {
      const { search } = useLocation();
      const searchParams = new URLSearchParams(search);

      return {
        parentOrganizationId: searchParams.get("parentOrganizationId"),
        platformLanguage: searchParams.get("platformLanguage"),
        signUpOverride: searchParams.get("signUpOverride"),
      };
    },
  };

  const LogIn = {
    title: intl.formatMessage({
      id: "components/pages:LogIn/title",
      defaultMessage: "Log In",
    }),
    url: `/${commonRoutes.LOG_IN}`,
  };

  const Plans = {
    title: intl.formatMessage({
      id: "components/pages:Plans/title",
      defaultMessage: "Plans",
    }),
    url: "/plans",
    breadcrumb: () => [{ title: Plans.title, url: Plans.url }],
  };

  // This needs to be a function as we need to pass in whether the org has access to this page.
  // This is because this component is first rendered immediately on page load, which is before
  // we have the access to the organization context. Therefore, we decide if the organization
  // has access in-place where this page is used and pass in that information then.
  const Packaging = (locked: boolean = false) => ({
    title: intl.formatMessage({
      id: "components/pages:Packaging:title",
      defaultMessage: "Packaging",
    }),
    url: "/inputs/packaging",
    breadcrumb: () => [
      productFootprintsBreadcrumb,
      inputsBreadcrumb,
      { title: Packaging().title, url: Packaging().url },
    ],
    locked,
    lockedMessage: {
      message: (
        <FormattedMessage
          id="components/pages:packagingLockedMessage"
          defaultMessage="Create packaging and measure its impact"
        />
      ),
      maxWidth: "160px",
    },
  });

  const PackagingComponentsEdit = {
    title: intl.formatMessage({
      id: "components/pages:PackagingComponentsExisting:title",
      defaultMessage: "Edit Packaging",
    }),
    url: (component: { id: string }) =>
      `/inputs/packaging/${component.id}/edit`,
    urlTemplate: "/inputs/packaging/:componentId/edit",
    useParams: () => {
      const params = useParams<{ componentId: string }>();
      const componentId = params.componentId;
      return { componentId };
    },
  };

  const PackagingComponentsNew = {
    title: intl.formatMessage({
      id: "components/pages:PackagingComponentsNew:title",
      defaultMessage: "Add Packaging",
    }),
    url: "/inputs/packaging/new",
  };

  const ProcurementDashboard = {
    title: intl.formatMessage({
      id: "components/pages:ProcurementDashboard:title",
      defaultMessage: "Dashboard",
    }),
    url: "/procurement-dashboard",
    breadcrumb: () => [
      corporateReportingBreadcrumb,
      { title: ProcurementDashboard.title, url: ProcurementDashboard.url },
    ],
  };

  const Scope3 = {
    title: intl.formatMessage({
      id: "components/pages:Scope3/title",
      defaultMessage: "Scope 3.1 (Purchased Food & Beverage)",
    }),
    url: "/reports/scope-3",
    breadcrumb: () => [
      corporateReportingBreadcrumb,
      reportsBreadcrumb,
      ghgEmissionsBreadcrumb,
      { title: Scope3.title, url: Scope3.url },
    ],
  };

  const Scope3Page = {
    stringifyQueryParams(queryParams: Scope3PageQueryParams | null): string {
      if (queryParams === null) {
        return "";
      }

      const searchParams = new URLSearchParams([
        ...scope3AssessmentIdQueryStringState.toSearchParams(
          queryParams.assessmentId
        ),
        ...scope3ViewHighestImpactItemsQueryStringState.toSearchParams(
          queryParams.viewHighestImpactItems
        ),
      ]);

      return searchParams.toString();
    },

    useQueryParams: (): [
      Scope3PageQueryParams,
      (update: Partial<Scope3PageQueryParams>) => void
    ] => {
      const [assessmentId, setAssessmentId] =
        scope3AssessmentIdQueryStringState.useState();
      const [viewHighestImpactItems, setViewHighestImpactItems] =
        scope3ViewHighestImpactItemsQueryStringState.useState();

      const set = (update: Partial<Scope3PageQueryParams>) => {
        if (update.assessmentId !== undefined) {
          setAssessmentId(update.assessmentId);
        }
        if (update.viewHighestImpactItems !== undefined) {
          setViewHighestImpactItems(update.viewHighestImpactItems);
        }
      };

      return [{ assessmentId, viewHighestImpactItems }, set];
    },
  };

  const SharedProducts = {
    title: intl.formatMessage(
      {
        id: "components/pages:Shared:title",
        defaultMessage: "Shared",
      },
      { recipeLabel: recipeLabel.pluralUppercase }
    ),
    url: "/shared",
    symbol: <Shared width={NAV_ICON_WIDTH} />,
    breadcrumb: (queryParams: RecipesListPageQueryParams | null) => {
      return [
        {
          title: SharedProducts.title,
          url: buildPath(
            SharedProducts.url,
            RecipesListPage.stringifyQueryParams(queryParams)
          ),
        },
      ];
    },
  };

  const SharedProduct = () => ({
    url: (recipe: { id: number }) => `/shared/${recipe.id}`,
    urlTemplate: "/shared/:recipeId([0-9]+)",
    useParams: () => {
      const params = useParams<{ recipeId: string }>();
      const recipeId = parseInt(params.recipeId);
      return { recipeId };
    },
    breadcrumb: (
      recipe: { id: number; name: string } | null,
      queryParams: RecipesListPageQueryParams | null
    ) => [
      productFootprintsBreadcrumb,
      ...SharedProducts.breadcrumb(queryParams),
      recipe === null
        ? { title: notFoundTitle }
        : { title: recipe.name, url: Recipe.url(recipe) },
    ],
  });

  const LandUse = {
    title: intl.formatMessage({
      id: "components/pages:LandUse/title",
      defaultMessage: "Land Use (Purchased Food & Beverage)",
    }),
    url: "/reports/land-use",
    breadcrumb: () => [
      corporateReportingBreadcrumb,
      reportsBreadcrumb,
      { title: LandUse.title, url: LandUse.url },
    ],
  };

  const WaterUse = {
    title: intl.formatMessage({
      id: "components/pages:WaterUse/title",
      defaultMessage: "Water Use (Purchased Food & Beverage)",
    }),
    url: "/reports/water-use",
    breadcrumb: () => [
      corporateReportingBreadcrumb,
      reportsBreadcrumb,
      { title: WaterUse.title, url: WaterUse.url },
    ],
  };

  return {
    addPackagingComponentTitle,
    addRecipeTitle,
    AccountDetails,
    CalculationTable,
    CalculationOptions,
    Collection,
    CollectionRecipe,
    CollectionRecipesNew,
    Collections,
    Community,
    ContactUs,
    Dashboard,
    Ingredients,
    IngredientsListPage,
    LandUse,
    LogIn,
    Packaging,
    PackagingComponentsEdit,
    PackagingComponentsListPage,
    PackagingComponentsNew,
    Plans,
    ProcurementDashboard,
    Recipe,
    Recipes,
    RecipeEdit,
    RecipesListPage,
    RecipesNew,
    RecipesUpload,
    ResetPassword,
    Scope3,
    Scope3Page,
    SharedProducts,
    SharedProduct,
    SignUp,
    WaterUse,
  };
}
