import { gql } from "graphql-tag";
import { uniq, zipWith } from "lodash";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMemoOne } from "use-memo-one";

import { useDataStore } from "../../data-store";
import useAvailableIngredients from "../../data-store/useAvailableIngredients";
import { subrecipeOnlyQuantityUnits } from "../../domain/units";
import {
  useAccurateCookingImpacts,
  useAccuratePostPreparationStorageImpacts,
  useFoodManufacturerOrganization,
  useRequestImpactAnalysis,
} from "../../services/useOrganizationFeatures";
import { DuplicateProcessing, useTracking } from "../../tracking";
import { mapNotNull } from "../../util/arrays";
import assertNever from "../../util/assertNever";
import counter from "../../util/counter";
import UserVisibleError from "../../util/UserVisibleError";
import { useGraphQL } from "../graphql/GraphQLProvider";
import {
  MutationField,
  useExecuteCompoundMutation,
  addRecipe,
  updateRecipe,
} from "../graphql/mutations";
import * as paging from "../graphql/paging";
import useMutation from "../graphql/useMutation";
import {
  useOrganization,
  useOrganizationId,
} from "../organizations/OrganizationProvider";
import { packagingComponentPageSize } from "../packaging/PackagingPage";
import Page from "../Page";
import { usePages } from "../pages";
import StatusDisplay from "../StatusDisplay";
import { ButtonLink } from "../utils/Button";
import Card from "../utils/Card";
import ErrorAlert from "../utils/ErrorAlert";
import FileInputButton from "../utils/FileInputButton";
import Form from "../utils/Form";
import { getNameForLocale } from "../utils/getNameForLocale";
import { recipesOwnedByOrganizationOrInUserGroupFilter } from "../utils/ownedByOrganizationOrInUserGroupFilter";
import Spinner from "../utils/Spinner";
import ChevronRight from "../utils/Vectors/ChevronRight";
import IngredientMatcher from "./IngredientMatcher";
import * as RecipeServingsEditor from "./RecipeEditor/RecipeServingsEditor";
import { ParsedRecipe, parseRecipesCsv } from "./recipes-csv";
import {
  ColumnDescription,
  columnDescriptions,
  getDefaultColumnName,
  manufacturerColumnDescriptions,
} from "./recipes-csv-columns";
import { SubrecipeOnlyInvalidUnitError } from "./recipes-csv-errors";
import { convertCaternetRecipesJsonToCsv } from "./recipes-json";
import UploadedRecipesTable from "./UploadedRecipesTable";
import {
  UploadRecipesPage_AddRecipe as AddRecipe,
  UploadRecipesPage_AddRecipeCollection as AddRecipeCollection,
  UploadRecipesPage_AddRecipeCollectionVariables as AddRecipeCollectionVariables,
  UploadRecipesPage_CollectionsQuery as TagsQuery,
  UploadRecipesPage_CollectionsQueryVariables as TagsQueryVariables,
  UploadRecipesPage_ExistingRecipe as ExistingRecipe,
  UploadRecipesPage_ExistingRecipesQuery as ExistingRecipesQuery,
  UploadRecipesPage_ExistingRecipesQueryVariables as ExistingRecipesQueryVariables,
  UploadRecipesPage_FoodClassLookupQuery as FoodClassLookupQuery,
  UploadRecipesPage_FoodClassLookupQueryVariables as FoodClassLookupQueryVariables,
  UploadRecipesPage_UpdateRecipe as UpdateRecipe,
  UploadRecipesPage_PackagingComponentLookupQuery,
  UploadRecipesPage_PackagingComponentLookupQueryVariables,
  UploadRecipesPage_UploadedRecipesQuery as UploadedRecipesQuery,
  UploadRecipesPage_UploadedRecipesQueryVariables as UploadedRecipesQueryVariables,
} from "./UploadRecipesPage.graphql";
import "./UploadRecipesPage.css";
import useRecipeLabel from "./useRecipeLabel";

export default function UploadRecipesPage() {
  const pages = usePages();

  return (
    <Page>
      <div className="d-flex flex-column h-100">
        <Page.Title breadcrumb={pages.RecipesUpload.breadcrumb()} />
        <div className="flex-fit">
          <UploadRecipesPageContent />
        </div>
      </div>
    </Page>
  );
}

type UseSubrecipe =
  | {
      type: "recipeId";
      recipeId: number;
    }
  | {
      type: "clientId";
      clientId: string;
    };

export interface IngredientNameStatus {
  foodClassId: number | null;
  foodClassName?: string;
  hasError: boolean;
  ingredientName: string;
  isAccepted: boolean;
  isExact: boolean;
  numOccurrences: number;
  selectedAlternativeName: string | null;
  selectedAlternativeFoodClassId: number | null;
}

interface IngredientSubrecipeStatus {
  ingredientId: string;
  usesSubrecipe: UseSubrecipe;
}

export interface UploadedRecipe {
  ghgPerRootRecipeServing: number | null;
  id: number;
  impactTooHigh: boolean;
  impactTooLow: boolean;
  parsedRecipe: ParsedRecipe;
  weightKgPerServing: number | null;
  weightTooHigh: boolean;
  weightTooLow: boolean;
}

const GHG_KG_PER_SERVING_TOO_HIGH_THRESHOLD = 6;
const GHG_KG_PER_SERVING_TOO_LOW_THRESHOLD = 0.1;
const WEIGHT_KG_PER_SERVING_TOO_HIGH_THRESHOLD = 0.8;
const WEIGHT_KG_PER_SERVING_TOO_LOW_THRESHOLD = 0.04;

function uploadedRecipeIsOutOfBounds(recipe: UploadedRecipe): boolean {
  return (
    recipe.impactTooHigh ||
    recipe.impactTooLow ||
    recipe.weightTooHigh ||
    recipe.weightTooLow
  );
}

type DuplicateRecipeHandler = "new" | "replace" | "skip duplicates";

interface DuplicateRecipe {
  parsedRecipeRowIndex: number;
  existingRecipeId: number;
}

type ParsedRecipeWithAncestors = ParsedRecipe & {
  ancestors: ParsedRecipe[];
};

type Subrecipe =
  | {
      kind: "inFile";
      ingredientId: string;
      name: string;
      parsedRecipeRowIndex: number;
      parsedSubrecipe: ParsedRecipeWithAncestors;
      useRecipeId: null;
    }
  | {
      kind: "existing";
      ingredientId: string;
      name: string;
      parsedRecipeRowIndex: number;
      parsedSubrecipe: null;
      useRecipeId: number;
    };

type State =
  | {
      type: "inactive";
    }
  | { type: "readingFile" }
  | {
      type: "parsedRecipes";
      duplicateRecipes: Array<DuplicateRecipe>;
      recipes: Array<ParsedRecipe>;
      servingsCount: RecipeServingsEditor.Value;
      servingsCountRequired: boolean;
      subrecipes: Array<Subrecipe>;
    }
  | {
      type: "duplicateRecipes";
      duplicateRecipes: Array<DuplicateRecipe>;
      recipes: Array<ParsedRecipe>;
      servingsCount: number;
      subrecipes: Array<Subrecipe>;
    }
  | {
      type: "approveIngredients";
      duplicateRecipes: Array<DuplicateRecipe>;
      duplicateRecipeHandler: DuplicateRecipeHandler;
      identifiedPackagingComponents: Map<string, string>;
      ingredientNameStatuses: Array<IngredientNameStatus>;
      ingredientSubrecipeStatuses: Array<IngredientSubrecipeStatus>;
      recipes: Array<ParsedRecipe>;
      servingsCount: number;
    }
  | {
      type: "uploaded";
      recipes: Array<UploadedRecipe>;
    }
  | {
      type: "error";
      error: unknown;
    };

function UploadRecipesPageContent() {
  const [state, setState] = useState<State>({ type: "inactive" });
  const {
    trackBulkRecipeUploadCompleted,
    trackBulkRecipeUploadDuplicatesProcessed,
    trackBulkRecipeUploadFileParsed,
    trackBulkRecipeUploadServingsEntered,
  } = useTracking();
  const accurateCookingImpacts = useAccurateCookingImpacts();
  const accuratePostPreparationStorageImpacts =
    useAccuratePostPreparationStorageImpacts();
  const canRequestImpactAnalysis = useRequestImpactAnalysis();
  const createIngredientStatuses = useCreateIngredientStatuses();
  const [organizationId] = useOrganizationId();
  const [availableIngredientsStatus] = useAvailableIngredients(organizationId);
  const recipeLabel = useRecipeLabel();
  const createRecipeTagSet = useCreateRecipeTagSet({
    organizationId,
  });
  const fetchUploadedRecipes = useFetchUploadedRecipes();
  const findDuplicateRecipes = useFindDuplicateRecipes();
  const findSubrecipes = useFindSubrecipes();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const pages = usePages();
  const createIngredientNameToFoodClassIdMap =
    useCreateIngredientNameToFoodClassIdMap();

  const executeCompoundMutation = useExecuteCompoundMutation();

  async function handleFileChange(file: File) {
    try {
      setState({ type: "readingFile" });
      let text = await file.text();

      if (file.type === "application/json") {
        text = convertCaternetRecipesJsonToCsv(text);
      }

      const parseRecipesCsvOutput = parseRecipesCsv(
        text,
        foodManufacturerOrganization
      );
      const recipes = parseRecipesCsvOutput.parsedRecipes;

      const subrecipesInUploadOrder = await getSubrecipesInUploadOrder({
        recipes: recipes.map((subRecipe) => ({
          ...subRecipe,
          ancestors: [],
        })),
        total: [],
        allParsedRecipes: recipes,
      });

      const subrecipeClientIds = mapNotNull(
        subrecipesInUploadOrder,
        (subrecipe) => subrecipe.parsedSubrecipe?.clientId ?? null
      );

      const topLevelRecipes = recipes.filter(
        (recipe) => !subrecipeClientIds.includes(recipe.clientId)
      );

      const recipesInUploadOrder: ParsedRecipe[] = [];
      const recipesInUploadOrderIds = new Set<string>();

      for (const subrecipe of subrecipesInUploadOrder) {
        if (subrecipe.kind === "inFile") {
          if (recipesInUploadOrderIds.has(subrecipe.parsedSubrecipe.clientId)) {
            continue;
          } else {
            recipesInUploadOrderIds.add(subrecipe.parsedSubrecipe.clientId);
            recipesInUploadOrder.push(subrecipe.parsedSubrecipe);
          }
        }
      }

      recipesInUploadOrder.push(...topLevelRecipes);

      const duplicateRecipes = await findDuplicateRecipes(recipes);

      trackBulkRecipeUploadFileParsed({
        fileType: file.type,
        recipes: recipes.length,
      });

      setState({
        type: "parsedRecipes",
        duplicateRecipes,
        recipes: Array.from(recipesInUploadOrder),
        servingsCount: RecipeServingsEditor.initialValue(undefined),
        servingsCountRequired: parseRecipesCsvOutput.servingsCountRequired,
        subrecipes: subrecipesInUploadOrder,
      });
    } catch (error: unknown) {
      setState({ type: "error", error });
    }
  }

  async function getSubrecipesInUploadOrder({
    recipes,
    total,
    allParsedRecipes,
  }: {
    recipes: Array<ParsedRecipeWithAncestors>;
    total: Array<Subrecipe>;
    allParsedRecipes: Array<ParsedRecipe>;
  }): Promise<Array<Subrecipe>> {
    const subrecipes = await findSubrecipes(recipes, allParsedRecipes);
    if (subrecipes.length === 0) {
      return total;
    }

    const subrecipeClientIds = uniq(
      mapNotNull(
        subrecipes,
        (subrecipe) => subrecipe.parsedSubrecipe?.clientId ?? null
      )
    );

    const subrecipeIngredientIds = uniq(
      mapNotNull(subrecipes, (subrecipe) =>
        subrecipe.kind === "existing" ? subrecipe.ingredientId : null
      )
    );

    total = total.filter(
      (subrecipe) =>
        (subrecipe.kind === "existing" &&
          !subrecipeIngredientIds.includes(subrecipe.ingredientId)) ||
        (subrecipe.kind === "inFile" &&
          !subrecipeClientIds.includes(subrecipe.parsedSubrecipe.clientId))
    );

    total.unshift(...subrecipes);

    const nestedSubrecipes = mapNotNull(
      subrecipes,
      (subrecipe) => subrecipe.parsedSubrecipe
    );
    return getSubrecipesInUploadOrder({
      recipes: nestedSubrecipes,
      total,
      allParsedRecipes,
    });
  }

  async function createUploadRecipesIngredientStatuses(
    recipes: Array<ParsedRecipe>,
    subrecipes: Array<Subrecipe>
  ) {
    const ingredientsOrPackagingComponents = recipes
      .filter((recipe) => !recipe.skipUpload)
      .flatMap((recipe) => recipe.ingredients);

    const ingredientIdToRecipeClientId = new Map<string, string>(
      mapNotNull(subrecipes, (subrecipe) =>
        subrecipe.kind === "inFile"
          ? [subrecipe.ingredientId, subrecipe.parsedSubrecipe.clientId]
          : null
      )
    );
    const ingredientIdToUseRecipeId = new Map<string, number>(
      mapNotNull(subrecipes, (subrecipe) =>
        subrecipe.kind === "existing"
          ? [subrecipe.ingredientId, subrecipe.useRecipeId]
          : null
      )
    );

    const {
      identifiedPackagingComponents,
      ingredientSubrecipeStatuses,
      ingredientNameStatuses,
    } = await createIngredientStatuses(
      ingredientIdToRecipeClientId,
      ingredientIdToUseRecipeId,
      ingredientsOrPackagingComponents,
      true
    );

    return {
      identifiedPackagingComponents,
      ingredientSubrecipeStatuses,
      ingredientNameStatuses,
    };
  }

  async function handleUpload() {
    if (state.type !== "approveIngredients") {
      return;
    }

    const { updatedStatuses, hasError } = setIngredientNameStatusErrors(
      state.ingredientNameStatuses
    );
    setState({ ...state, ingredientNameStatuses: updatedStatuses });
    if (hasError) {
      throw new UserVisibleError(
        "Please select a valid Foodsteps Ingredient for all updated matches."
      );
    }

    const ingredientIdToRecipeClientId = new Map(
      mapNotNull(state.ingredientSubrecipeStatuses, (status) =>
        status.usesSubrecipe.type === "clientId"
          ? [status.ingredientId, status.usesSubrecipe.clientId]
          : null
      )
    );

    const ingredientIdToUseRecipeId = new Map(
      mapNotNull(state.ingredientSubrecipeStatuses, (status) =>
        status.usesSubrecipe.type === "recipeId"
          ? [status.ingredientId, status.usesSubrecipe.recipeId]
          : null
      )
    );

    const ingredientNameToFoodClassId = createIngredientNameToFoodClassIdMap(
      state.ingredientNameStatuses,
      "Upload Recipes"
    );

    const recipeTagSet = await createRecipeTagSet();

    const recipeRowIndexToExistingRecipeId =
      state.duplicateRecipeHandler === "new"
        ? new Map<number, number>()
        : new Map(
            state.duplicateRecipes.map((duplicateRecipe) => [
              duplicateRecipe.parsedRecipeRowIndex,
              duplicateRecipe.existingRecipeId,
            ])
          );

    const recipeMutationFields: Array<MutationField<AddRecipe | UpdateRecipe>> =
      [];

    const recipesToUpload = state.recipes.filter(
      (recipe) => !recipe.skipUpload
    );

    for (const recipe of recipesToUpload) {
      const tagIds =
        recipe.tagNames === null
          ? []
          : await Promise.all(
              recipe.tagNames.map(
                async (tagName) => await recipeTagSet.nameToId(tagName)
              )
            );

      const packagingComponentV2Ids = recipe.ingredients
        .map(({ name }) => state.identifiedPackagingComponents.get(name))
        .filter(
          (packagingComponent) => packagingComponent !== undefined
        ) as string[];

      const commonRecipeInput = {
        clientId: recipe.clientId,
        code: recipe.code,
        collectionIds: tagIds,
        cookingPenalties: [],
        coProducts: [],
        hasCompleteCookingPenalties: accurateCookingImpacts
          ? recipe.notCooked.parsedValue
          : true,
        hasCompletePostPreparationStoragePenalties:
          accuratePostPreparationStorageImpacts
            ? recipe.notStored.parsedValue
            : true,
        ingredients: recipe.ingredients
          .filter(({ name }) => !state.identifiedPackagingComponents.has(name))
          .map((ingredient) => {
            const ingredientInput = {
              name: ingredient.name,
              quantity: ingredient.quantity,
              unit: ingredient.unit,
              ...(foodManufacturerOrganization
                ? {
                    unitProcess: {
                      processLoss:
                        ingredient.processLossPercentage == null
                          ? null
                          : ingredient.processLossPercentage / 100,
                    },
                  }
                : {}),
            };
            if (ingredientIdToRecipeClientId.get(ingredient.id)) {
              return {
                ...ingredientInput,
                foodClassId: null,
                useRecipeWithClientId: ingredientIdToRecipeClientId.get(
                  ingredient.id
                ),
              };
            } else if (ingredientIdToUseRecipeId.get(ingredient.id)) {
              return {
                ...ingredientInput,
                foodClassId: null,
                useRecipeId: ingredientIdToUseRecipeId.get(ingredient.id),
              };
            } else {
              const matchedIngredientFoodClassId =
                ingredientNameToFoodClassId.get(ingredient.name);
              return {
                ...ingredientInput,
                foodClassId: matchedIngredientFoodClassId ?? null,
              };
            }
          }),
        isCooked: foodManufacturerOrganization
          ? false
          : !recipe.notCooked.parsedValue,
        isHotDrink: false,
        isStored: foodManufacturerOrganization
          ? false
          : !recipe.notStored.parsedValue,
        name: recipe.name,
        numServings: recipe.servings ?? state.servingsCount,
        ownerOrganizationId: organizationId,
        packagingComponentsV2: packagingComponentV2Ids.map((id) => {
          return { packagingComponentId: id, amountOfProductsPackaged: null };
        }),
        productMassQuantity: recipe.productWeight,
        productMassUnit: "kg",
        productProcessingMassQuantity: recipe.productProcessingWeight,
        productProcessingMassUnit: "kg",
        ...(recipe.productProcessingEnergy !== undefined &&
        recipe.productProcessingEnergy !== null &&
        recipe.productProcessingLossPercentage !== undefined
          ? {
              unitProcess: {
                energyUses: [
                  {
                    amountKwh: recipe.productProcessingEnergy,
                  },
                ],
                finalProductLoss:
                  recipe.productProcessingLossPercentage == null
                    ? null
                    : recipe.productProcessingLossPercentage / 100,
              },
            }
          : {}),
      };

      const existingRecipeId = recipeRowIndexToExistingRecipeId.get(
        recipe.rowIndex
      );
      if (existingRecipeId !== undefined) {
        recipeMutationFields.push(
          updateRecipe<UpdateRecipe>({
            input: {
              ...commonRecipeInput,
              id: existingRecipeId,
            },
            outputFragment: gql`
              fragment UploadRecipesPage_UpdateRecipe on UpdateRecipe {
                success
                recipe {
                  id
                }
              }
            `,
          })
        );
      } else {
        recipeMutationFields.push(
          addRecipe<AddRecipe>({
            input: commonRecipeInput,
            outputFragment: gql`
              fragment UploadRecipesPage_AddRecipe on AddRecipe {
                success
                recipe {
                  id
                }
              }
            `,
          })
        );
      }
    }

    let mutationResult;
    try {
      mutationResult = await executeCompoundMutation(recipeMutationFields);
    } catch (error: unknown) {
      setState({ type: "error", error });
      return;
    }
    const recipeIds = mutationResult.map((node) => node.recipe.id);

    const uploadedRecipesResult = await fetchUploadedRecipes(recipeIds);

    const uploadedRecipes: Array<UploadedRecipe> = zipWith(
      recipesToUpload,
      mutationResult,
      (recipe, recipeMutationResult) => {
        const uploadedRecipe = uploadedRecipesResult.find(
          (uploadedRecipe) =>
            uploadedRecipe.id === recipeMutationResult.recipe.id
        );

        const ghgPerRootRecipeServing =
          uploadedRecipe!.impact.effects?.ghgPerRootRecipeServing ?? null;

        const weightKgPerServing = uploadedRecipe!.impact.weightKgPerServing;

        return {
          ghgPerRootRecipeServing,
          id: recipeMutationResult.recipe.id,
          impactTooHigh:
            !foodManufacturerOrganization && // we don't have thresholds for manufacturers yet
            ghgPerRootRecipeServing !== null &&
            ghgPerRootRecipeServing >= GHG_KG_PER_SERVING_TOO_HIGH_THRESHOLD,
          impactTooLow:
            !foodManufacturerOrganization && // we don't have thresholds for manufacturers yet
            ghgPerRootRecipeServing !== null &&
            ghgPerRootRecipeServing <= GHG_KG_PER_SERVING_TOO_LOW_THRESHOLD,
          parsedRecipe: recipe,
          weightKgPerServing,
          weightTooHigh:
            !foodManufacturerOrganization && // we don't have thresholds for manufacturers yet
            weightKgPerServing !== null &&
            weightKgPerServing >= WEIGHT_KG_PER_SERVING_TOO_HIGH_THRESHOLD,
          weightTooLow:
            !foodManufacturerOrganization && // we don't have thresholds for manufacturers yet
            weightKgPerServing !== null &&
            weightKgPerServing <= WEIGHT_KG_PER_SERVING_TOO_LOW_THRESHOLD,
        };
      }
    );

    setState({
      type: "uploaded",
      recipes: uploadedRecipes.sort(
        (a, b) =>
          Number(uploadedRecipeIsOutOfBounds(b)) -
          Number(uploadedRecipeIsOutOfBounds(a))
      ),
    });

    const uploadedIngredients: Array<IngredientNameStatus> =
      state.ingredientNameStatuses;
    const fuzzyMatchedIngredients: Array<IngredientNameStatus> =
      uploadedIngredients.filter((ingredient) => !ingredient.isExact);
    const numAcceptedFuzzyMatchedIngredients: number =
      fuzzyMatchedIngredients.filter(
        (ingredient) => ingredient.isAccepted
      ).length;

    trackBulkRecipeUploadCompleted({
      recipes: uploadedRecipes.length,
      exactFoodClassMatches:
        uploadedIngredients.length - fuzzyMatchedIngredients.length,
      exactPackagingComponentMatches: state.identifiedPackagingComponents.size,
      fuzzyMatchedFoodClasses: fuzzyMatchedIngredients.length,
      acceptedIngredientMatches: numAcceptedFuzzyMatchedIngredients,
      rejectedIngredientMatches:
        fuzzyMatchedIngredients.length - numAcceptedFuzzyMatchedIngredients,
      duplicateRecipeHandler: state.duplicateRecipeHandler,
    });
  }

  if (state.type === "inactive") {
    return (
      <>
        <Card style={{ maxWidth: "900px" }}>
          <p>
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:uploadPrompt"
              defaultMessage="Please select a CSV containing the products you want to upload."
            />
          </p>
          {foodManufacturerOrganization && (
            <p>
              <FormattedMessage
                id="components/recipes/UploadRecipesPage:foodManufacturerBetaWarning"
                defaultMessage="Please note that this feature is in beta for food manufacturers."
              />
            </p>
          )}
          <div className="form-group">
            <FileInputButton
              onChange={handleFileChange}
              accept=".csv,application/JSON"
            />
          </div>
          <h5 className="medium-font mt-4">
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:format"
              defaultMessage="Format"
            />
          </h5>
          <p>
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:csvColumnNameInstructions"
              defaultMessage="The CSV should have following column names (as seen below in bold):"
            />
          </p>
          {(foodManufacturerOrganization
            ? manufacturerColumnDescriptions
            : columnDescriptions
          ).map((columnDescription: ColumnDescription) => {
            return (
              <dl className="row mb-1" key={columnDescription.id}>
                <dt className="col-2 medium-font">
                  {getDefaultColumnName(columnDescription)}
                </dt>
                <dd className="col-10">{columnDescription.description}</dd>
              </dl>
            );
          })}
          <p className="mt-3">
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:rowInstructions"
              defaultMessage="Each row should correspond to one ingredient. A product with multiple ingredients should have multiple rows."
            />
          </p>
          <p>
            <a
              href={
                foodManufacturerOrganization
                  ? `/assets/upload_recipes_example_manufacturers.csv`
                  : `/assets/upload_recipes_example.csv`
              }
              download="foodsteps-upload-recipes-example.csv"
            >
              <FormattedMessage
                id="components/recipes/UploadRecipesPage:downloadExampleCsv"
                defaultMessage="Download an example CSV."
              />
            </a>
          </p>
        </Card>
      </>
    );
  } else if (state.type === "readingFile") {
    return <Spinner />;
  } else if (state.type === "parsedRecipes") {
    const handleNext = async () => {
      let servingsCount: number;
      if (state.servingsCountRequired) {
        const servingsCountResult = RecipeServingsEditor.read(
          state.servingsCount
        );

        if (servingsCountResult.hasError) {
          setState({ ...state, servingsCount: servingsCountResult.value });
          return;
        }
        servingsCount = servingsCountResult.input;
        trackBulkRecipeUploadServingsEntered({
          servingsPerRecipe: servingsCount,
        });
      } else {
        servingsCount = 1;
      }

      if (duplicateRecipesDecisionRequired(state)) {
        setState({
          ...state,
          type: "duplicateRecipes",
          servingsCount,
        });
      } else {
        const {
          identifiedPackagingComponents,
          ingredientSubrecipeStatuses,
          ingredientNameStatuses,
        } = await createUploadRecipesIngredientStatuses(
          state.recipes,
          state.subrecipes
        );
        setState({
          ...state,
          type: "approveIngredients",
          duplicateRecipeHandler: "new",
          identifiedPackagingComponents,
          ingredientNameStatuses,
          ingredientSubrecipeStatuses,
          servingsCount,
        });
      }
    };

    return (
      <Form onSubmit={handleNext}>
        <div className="d-flex flex-column h-100">
          <p>
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:identifiedRecipes"
              defaultMessage="Identified {recipeCount} products."
              values={{
                recipeCount: state.recipes.length,
              }}
            />
          </p>
          {state.servingsCountRequired && (
            <>
              <RecipeServingsEditor.RecipeServingsEditor
                label={
                  <FormattedMessage
                    id="components/recipes/UploadRecipesPage:servingsPerRecipe"
                    defaultMessage="Servings per recipe"
                  />
                }
                onChange={(servingsCount) =>
                  setState({ ...state, servingsCount })
                }
                value={state.servingsCount}
              />
              <p>
                <FormattedMessage
                  id="components/recipes/UploadRecipesPage:servingsInstructions/enterServings"
                  defaultMessage="Please enter a value for the number of servings per product above."
                />
              </p>
              <p>
                <FormattedMessage
                  id="components/recipes/UploadRecipesPage:servingsInstructions/explanation"
                  defaultMessage="This value will be applied to all the products in the spreadsheet."
                />
              </p>
            </>
          )}
          <div className="form-group">
            <Form.SubmitButton
              submitLabel={
                <FormattedMessage
                  id="components/recipes/UploadRecipesPage:next"
                  defaultMessage="Next"
                />
              }
            />
          </div>
        </div>
      </Form>
    );
  } else if (state.type === "duplicateRecipes") {
    async function handleNext(
      duplicateProcessing: DuplicateProcessing,
      duplicateRecipeHandler: DuplicateRecipeHandler,
      recipes: Array<ParsedRecipe>,
      subrecipes: Array<Subrecipe>
    ) {
      if (state.type !== "duplicateRecipes") {
        return;
      }
      const {
        identifiedPackagingComponents,
        ingredientSubrecipeStatuses,
        ingredientNameStatuses,
      } = await createUploadRecipesIngredientStatuses(recipes, subrecipes);

      trackBulkRecipeUploadDuplicatesProcessed({
        duplicateProcessing,
      });
      setState({
        ...state,
        type: "approveIngredients",
        identifiedPackagingComponents,
        ingredientSubrecipeStatuses,
        ingredientNameStatuses,
        duplicateRecipeHandler,
        recipes,
      });
    }
    async function handleKeepBoth() {
      if (state.type !== "duplicateRecipes") {
        return;
      }
      await handleNext("Keep Both", "new", state.recipes, state.subrecipes);
    }

    const handleStop = () => {
      trackBulkRecipeUploadDuplicatesProcessed({ duplicateProcessing: "Stop" });
      setState({ type: "inactive" });
    };

    async function handleReplace() {
      if (state.type !== "duplicateRecipes") {
        return;
      }
      await handleNext("Replace", "replace", state.recipes, state.subrecipes);
    }

    async function handleSkipDuplicates() {
      if (state.type !== "duplicateRecipes") {
        return;
      }
      const duplicateRecipesRowIndices = state.duplicateRecipes.map(
        (duplicateRecipe) => duplicateRecipe.parsedRecipeRowIndex
      );
      const handledRecipes: Array<ParsedRecipe> = [];
      for (const recipe of state.recipes) {
        let newRecipe: ParsedRecipe = recipe;
        if (duplicateRecipesRowIndices.includes(recipe.rowIndex)) {
          newRecipe = {
            ...recipe,
            skipUpload: true,
          };
        }
        handledRecipes.push(newRecipe);
      }

      const handledSubrecipes: Array<Subrecipe> = [];
      for (const subrecipe of state.subrecipes) {
        let newSubrecipe: Subrecipe = subrecipe;
        if (
          subrecipe.kind === "inFile" &&
          duplicateRecipesRowIndices.includes(
            subrecipe.parsedSubrecipe.rowIndex
          )
        ) {
          const duplicateRecipe = state.duplicateRecipes.find(
            (duplicateRecipe) =>
              duplicateRecipe.parsedRecipeRowIndex ===
              subrecipe.parsedSubrecipe.rowIndex
          );
          newSubrecipe = {
            ...subrecipe,
            kind: "existing",
            parsedSubrecipe: null,
            useRecipeId: duplicateRecipe!.existingRecipeId,
          };
        }
        handledSubrecipes.push(newSubrecipe);
      }
      await handleNext(
        "Skip Duplicates",
        "skip duplicates",
        handledRecipes,
        handledSubrecipes
      );
    }

    return (
      <DuplicateRecipePrompt
        onKeepBoth={handleKeepBoth}
        onStop={handleStop}
        onReplace={handleReplace}
        onSkipDuplicates={handleSkipDuplicates}
      />
    );
  } else if (state.type === "approveIngredients") {
    const handleIsAcceptedChange = (
      status: IngredientNameStatus,
      isAccepted: boolean
    ) => {
      setState({
        ...state,
        ingredientNameStatuses: state.ingredientNameStatuses.map(
          (existingStatus) =>
            existingStatus.ingredientName === status.ingredientName
              ? {
                  ...existingStatus,
                  isAccepted,
                }
              : existingStatus
        ),
      });
    };

    const handleSelectAlternativeChange = ({
      status,
      alternativeIngredientName,
      alternativeIngredientFoodClassId,
    }: {
      status: IngredientNameStatus;
      alternativeIngredientName: string;
      alternativeIngredientFoodClassId: number | null;
    }) => {
      setState({
        ...state,
        ingredientNameStatuses: state.ingredientNameStatuses.map(
          (existingStatus) =>
            existingStatus.ingredientName === status.ingredientName
              ? {
                  ...existingStatus,
                  hasError: false,
                  selectedAlternativeName:
                    alternativeIngredientName !== ""
                      ? alternativeIngredientName
                      : null,
                  selectedAlternativeFoodClassId:
                    alternativeIngredientFoodClassId,
                }
              : existingStatus
        ),
      });
    };

    const submitButtonLabel = {
      loadingLabel: (
        <FormattedMessage
          id="components/recipes/UploadRecipesPage:uploading"
          defaultMessage="Uploading"
        />
      ),
      submitLabel: (
        <FormattedMessage
          id="components/recipes/UploadRecipesPage:upload"
          defaultMessage="Upload"
        />
      ),
    };

    return (
      <StatusDisplay status={availableIngredientsStatus}>
        {(availableIngredients) => (
          <div className="d-flex flex-column h-100">
            {state.ingredientNameStatuses.length === 0 ? (
              <div className="form-group">
                <p>
                  <FormattedMessage
                    id="components/recipes/UploadedRecipesPage:nothingToUpload"
                    defaultMessage="No {recipeLabel} to upload."
                    values={{ recipeLabel: recipeLabel.pluralLowercase }}
                  />
                </p>
                <ButtonLink to={pages.Recipes.url} variant="primary">
                  <FormattedMessage
                    id="components/recipes/UploadedRecipesPage:nothingToUploadButton"
                    defaultMessage="Done"
                  />
                </ButtonLink>
              </div>
            ) : allIngredientsAreExactMatches(state.ingredientNameStatuses) ? (
              <>
                <p>
                  <FormattedMessage
                    id="components/recipes/UploadedRecipesPage:allIngredientsMatched"
                    defaultMessage="Successfully matched all of your ingredients to ingredients in our database."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="components/recipes/UploadedRecipesPage:clickUploadPrompt"
                    defaultMessage="Please click Upload to continue."
                  />
                </p>
                <Form onSubmit={handleUpload}>
                  <Form.SubmitButton
                    loadingLabel={
                      <FormattedMessage
                        id="components/recipes/UploadRecipesPage:uploading"
                        defaultMessage="Uploading"
                      />
                    }
                    submitLabel={
                      <FormattedMessage
                        id="components/recipes/UploadRecipesPage:upload"
                        defaultMessage="Upload"
                      />
                    }
                  />
                </Form>
              </>
            ) : (
              <IngredientMatcher
                availableIngredients={availableIngredients}
                canRequestImpactAnalysis={canRequestImpactAnalysis}
                ingredientNameStatuses={state.ingredientNameStatuses}
                onSubmit={handleUpload}
                onIsAcceptedChange={handleIsAcceptedChange}
                onSelectAlternativeChange={handleSelectAlternativeChange}
                submitButtonLabel={submitButtonLabel}
              />
            )}
          </div>
        )}
      </StatusDisplay>
    );
  } else if (state.type === "uploaded") {
    const flaggedRecipeCount = state.recipes.filter((recipe) =>
      uploadedRecipeIsOutOfBounds(recipe)
    ).length;
    return (
      <>
        <p className="mt-2">
          <FormattedMessage
            id="components/recipes/UploadedRecipesPage:success"
            defaultMessage="<medium>{recipeCount} products</medium> have successfully been uploaded."
            values={{
              recipeCount: state.recipes.length,
              medium: (chunks: React.ReactNode) => (
                <span className="medium-font">{chunks}</span>
              ),
            }}
          />
        </p>
        <p className="mb-2">
          <FormattedMessage
            id="components/recipes/UploadedRecipesPage:followUpInstructions"
            defaultMessage="To complete the process:"
          />
        </p>
        <ul className="mb-0 pl-4 pb-3">
          {flaggedRecipeCount !== 0 && (
            <li>
              <FormattedMessage
                id="components/recipes/UploadedRecipesPage:followUpInstructionsReviewFlaggedProducts"
                defaultMessage="Review the <medium>{flaggedRecipeCount, plural, one {# product} other {# products}}</medium> below flagged with an <medium>unusually high or low weight or carbon footprint</medium>."
                values={{
                  flaggedRecipeCount,
                  medium: (chunks: React.ReactNode) => (
                    <span className="medium-font">{chunks}</span>
                  ),
                }}
              />
            </li>
          )}
          {!foodManufacturerOrganization &&
            (accurateCookingImpacts && accuratePostPreparationStorageImpacts ? (
              <li>
                <FormattedMessage
                  id="components/recipes/UploadedRecipesPage:followUpInstructionsListAddCookingAndPostPreparationStorageInstructions"
                  defaultMessage="Add cooking and post-preparation storage instructions for <medium>all uploaded products</medium> or specify there aren’t any."
                  values={{
                    medium: (chunks: React.ReactNode) => (
                      <span className="medium-font">{chunks}</span>
                    ),
                  }}
                />
              </li>
            ) : (
              <li>
                <FormattedMessage
                  id="components/recipes/UploadedRecipesPage:followUpInstructionsSpecifyIfCookedAndStored"
                  defaultMessage="Specify whether your uploaded products are cooked and stored post-preparation."
                />
              </li>
            ))}
          {/* TODO: remove this when we add packaging to the uploader */}
          {foodManufacturerOrganization && (
            <li>
              <FormattedMessage
                id="components/recipes/UploadedRecipesPage:followUpInstructionsListAddPackaging"
                defaultMessage="Add packaging components for <medium>all uploaded products</medium>."
                values={{
                  medium: (chunks: React.ReactNode) => (
                    <span className="medium-font">{chunks}</span>
                  ),
                }}
              />
            </li>
          )}
          {/* TODO: remove this if/when we add coproducts to the uploader */}
          {foodManufacturerOrganization && (
            <li>
              <FormattedMessage
                id="components/recipes/UploadedRecipesPage:followUpInstructionsListAddCoProducts"
                defaultMessage="Add co-products for any uploaded products which have a co-product(s) of meaningful economic value."
              />
            </li>
          )}

          {canRequestImpactAnalysis ? (
            <li>
              <FormattedMessage
                id="components/recipes/UploadedRecipesPage:followUpInstructionsListLinkIngredientsCanRequestImpactAnalysis"
                defaultMessage="Update any ingredients that we couldn't find in our database or wait for Foodsteps to make them available."
              />
            </li>
          ) : (
            <li>
              <FormattedMessage
                id="components/recipes/UploadedRecipesPage:followUpInstructionsListLinkIngredients"
                defaultMessage="Update any ingredients that we couldn't find in our database."
              />
            </li>
          )}
        </ul>
        <div className="mb-3 pb-4">
          <ButtonLink to={pages.Recipes.url} variant="primary">
            <FormattedMessage
              id="components/recipes/UploadedRecipesPage:finishUploadButtonMessage"
              defaultMessage="Done"
            />
          </ButtonLink>
        </div>
        <Card className="uploaded-recipes-table-card">
          <UploadedRecipesTable
            recipePageUrl={pages.Recipe.url}
            recipes={state.recipes}
          />
        </Card>
      </>
    );
  } else if (state.type === "error") {
    return <ErrorAlert error={state.error} />;
  } else {
    return assertNever(state, "unexpected state");
  }
}

interface DuplicateRecipePromptProps {
  onKeepBoth: () => void;
  onStop: () => void;
  onReplace: () => void;
  onSkipDuplicates: () => void;
}

export function DuplicateRecipePrompt(props: DuplicateRecipePromptProps) {
  const { onKeepBoth, onStop, onReplace, onSkipDuplicates } = props;

  return (
    <div style={{ maxWidth: 600 }}>
      <p>
        <FormattedMessage
          id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/question"
          defaultMessage="
            Some of the uploaded products have the same name as existing products. How do you want to handle these products?
          "
        />
      </p>

      <div className="list-group">
        <DuplicateRecipePromptButton
          explanation={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/keepBoth/explanation"
              defaultMessage="
                  New products will be created for each uploaded product.
                  Existing products will not be modified.
                "
            />
          }
          onClick={onKeepBoth}
          title={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/keepBoth/title"
              defaultMessage="Keep Both"
            />
          }
        />
        <DuplicateRecipePromptButton
          explanation={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/stop/explanation"
              defaultMessage="
                  Stop the upload process.
                  No new products will be created.
                  Existing products will not be modified.
                "
            />
          }
          onClick={onStop}
          title={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/stop/title"
              defaultMessage="Stop"
            />
          }
        />
        <DuplicateRecipePromptButton
          explanation={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/replace/explanation"
              defaultMessage="
                  If there is an existing product with the same name as an uploaded product,
                  replace it with the uploaded product.
                  This will overwrite any existing information for the product.
                "
            />
          }
          onClick={onReplace}
          title={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/replace/title"
              defaultMessage="Replace"
            />
          }
        />
        <DuplicateRecipePromptButton
          explanation={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/skipDuplicates/explanation"
              defaultMessage="New products with the same name won't be uploaded and existing versions kept."
            />
          }
          onClick={onSkipDuplicates}
          title={
            <FormattedMessage
              id="components/recipes/UploadRecipesPage:DuplicateRecipePrompt/skipDuplicates/title"
              defaultMessage="Skip Over"
            />
          }
        />
      </div>
    </div>
  );
}

interface DuplicateRecipePromptButtonProps {
  explanation: React.ReactNode;
  onClick: () => void;
  title: React.ReactNode;
}

function DuplicateRecipePromptButton(props: DuplicateRecipePromptButtonProps) {
  const { explanation, onClick, title } = props;
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <button
      className="DuplicateRecipePromptButton d-flex justify-content-between align-items-center list-group-item list-group-item-action flex-row"
      onClick={() => {
        setLoading(true);
        onClick();
      }}
    >
      <div>
        <div className="medium-font">{title}</div>
        <div>{explanation}</div>
      </div>
      <span className="pl-4">
        {loading ? <Spinner size="sm" /> : <ChevronRight width={16} />}
      </span>
    </button>
  );
}

function duplicateRecipesDecisionRequired(state: {
  duplicateRecipes: Array<DuplicateRecipe>;
}) {
  return state.duplicateRecipes.length > 0;
}

function allIngredientsAreExactMatches(
  ingredientNameStatuses: Array<IngredientNameStatus>
): boolean {
  return ingredientNameStatuses.every(
    (ingredientNameStatus) => ingredientNameStatus.isExact
  );
}

export function useCreateIngredientStatuses() {
  const fetchNameLowerCaseToFoodClassId = useFetchNameLowerCaseToFoodClassId();
  const fuzzyLookupFoodClassNames = useFuzzyLookupFoodClassNames();
  const packagingComponentNameLookupPromise =
    usePackagingComponentLookupByName();
  const [organization] = useOrganization();

  return async (
    ingredientIdToRecipeClientId: Map<string, string>,
    ingredientIdToUseRecipeId: Map<string, number>,
    // id is optional because we don't set it from the post-upload ingredient linking page
    ingredientsOrPackagingComponents: Array<{ id?: string; name: string }>,
    allowExactMatches: boolean
  ): Promise<{
    identifiedPackagingComponents: Map<string, string>;
    ingredientSubrecipeStatuses: Array<IngredientSubrecipeStatus>;
    ingredientNameStatuses: Array<IngredientNameStatus>;
  }> => {
    const nameLowerCaseToFoodClassId = await fetchNameLowerCaseToFoodClassId();
    const packagingComponentNameLookup =
      await packagingComponentNameLookupPromise();

    const ingredientIds: Array<string> = [];
    const ingredientSubrecipeStatuses: Array<IngredientSubrecipeStatus> = [];

    const ingredientNames: Array<string> = [];
    const ingredientNamesForFuzzyMatching: Array<string> = [];
    const ingredientNameStatuses: Array<IngredientNameStatus> = [];

    const identifiedPackagingComponents: Map<string, string> = new Map();

    for (const ingredient of ingredientsOrPackagingComponents) {
      const packagingComponentId = packagingComponentNameLookup.get(
        ingredient.name
      );
      if (packagingComponentId !== undefined) {
        identifiedPackagingComponents.set(
          ingredient.name,
          packagingComponentId
        );
      } else if (
        ingredient.id !== undefined &&
        (ingredientIdToRecipeClientId.get(ingredient.id) ||
          ingredientIdToUseRecipeId.get(ingredient.id))
      ) {
        ingredientIds.push(ingredient.id);
      } else {
        ingredientNames.push(ingredient.name);
      }
    }

    for (const ingredientId of ingredientIds) {
      let usesSubrecipe: UseSubrecipe | undefined = undefined;

      const inFileMatchedClientId =
        ingredientIdToRecipeClientId.get(ingredientId);
      const useRecipeId = ingredientIdToUseRecipeId.get(ingredientId);

      if (inFileMatchedClientId) {
        usesSubrecipe = {
          type: "clientId",
          clientId: inFileMatchedClientId,
        };
      } else if (useRecipeId) {
        usesSubrecipe = {
          type: "recipeId",
          recipeId: useRecipeId,
        };
      }
      ingredientSubrecipeStatuses.push({
        ingredientId,
        usesSubrecipe,
      } as IngredientSubrecipeStatus);
    }

    const ingredientNamesCount = counter<string>(ingredientNames);

    for (const [ingredientName, numOccurrences] of ingredientNamesCount) {
      const exactMatchFoodClassId = nameLowerCaseToFoodClassId.get(
        ingredientName.toLowerCase()
      );
      if (exactMatchFoodClassId === undefined) {
        ingredientNamesForFuzzyMatching.push(ingredientName);
      } else {
        const commonProperties = {
          foodClassId: exactMatchFoodClassId,
          foodClassName: ingredientName,
          ingredientName,
          hasError: false,
          isAccepted: true,
          numOccurrences,
        };
        if (allowExactMatches) {
          ingredientNameStatuses.push({
            ...commonProperties,
            isExact: true,
            selectedAlternativeName: null,
            selectedAlternativeFoodClassId: null,
          });
        } else {
          ingredientNameStatuses.push({
            ...commonProperties,
            isExact: false,
            selectedAlternativeName: ingredientName,
            selectedAlternativeFoodClassId: exactMatchFoodClassId,
          });
        }
      }
    }

    const fuzzyMatches = await fuzzyLookupFoodClassNames(
      ingredientNamesForFuzzyMatching,
      organization.localeForFoodClasses
    );
    for (const fuzzyMatch of fuzzyMatches) {
      if (fuzzyMatch.foodClass !== null) {
        ingredientNameStatuses.push({
          foodClassId: fuzzyMatch.foodClass.id,
          foodClassName: fuzzyMatch.foodClass.name,
          ingredientName: fuzzyMatch.name,
          hasError: false,
          isAccepted: true,
          isExact: false,
          numOccurrences: ingredientNamesCount.get(fuzzyMatch.name) ?? 0,
          selectedAlternativeName: getNameForLocale(
            fuzzyMatch.foodClass,
            organization.localeForFoodClasses
          ),
          selectedAlternativeFoodClassId: fuzzyMatch.foodClass.id,
        });
      } else {
        ingredientNameStatuses.push({
          foodClassId: null,
          foodClassName: undefined,
          ingredientName: fuzzyMatch.name,
          hasError: false,
          isAccepted: false,
          isExact: false,
          numOccurrences: ingredientNamesCount.get(fuzzyMatch.name) ?? 0,
          selectedAlternativeName: null,
          selectedAlternativeFoodClassId: null,
        });
      }
    }

    return {
      identifiedPackagingComponents,
      ingredientSubrecipeStatuses,
      ingredientNameStatuses,
    };
  };
}

function useFetchNameLowerCaseToFoodClassId() {
  const dataStore = useDataStore();
  const [organizationId] = useOrganizationId();
  const [organization] = useOrganization();

  return async () => {
    const availableIngredients = await dataStore.fetchAvailableIngredients(
      organizationId
    );
    return new Map(
      availableIngredients.flatMap((availableIngredient) =>
        [
          ...(organization.localeForFoodClasses.startsWith("en")
            ? [availableIngredient.name]
            : []),
          ...availableIngredient.synonyms
            .filter(
              (synonym) => synonym.locale === organization.localeForFoodClasses
            )
            .map((synonym) => synonym.name),
        ].map((name): [string, number] => [
          name.toLowerCase(),
          availableIngredient.id,
        ])
      )
    );
  };
}

function useFuzzyLookupFoodClassNames() {
  const graphQL = useGraphQL();

  return async (names: Array<string>, locale: string) => {
    const response = await graphQL.fetch<
      FoodClassLookupQuery,
      FoodClassLookupQueryVariables
    >({
      query: foodClassLookupQuery,
      variables: {
        names,
        locale,
      },
    });
    return response.foodClassLookup;
  };
}

function usePackagingComponentLookupByName() {
  const graphQL = useGraphQL();
  const [organizationId] = useOrganizationId();

  return async () => {
    const response = await graphQL.fetch<
      UploadRecipesPage_PackagingComponentLookupQuery,
      UploadRecipesPage_PackagingComponentLookupQueryVariables
    >({
      query: packagingComponentQuery(),
      variables: {
        organizationId,
        first: packagingComponentPageSize,
      },
    });
    if (response.packagingComponents.pageInfo.hasNextPage) {
      reportError(
        new Error(
          `organization ${organizationId} has more then ${response.packagingComponents.edges.length} packaging components, some were not fetched`
        )
      );
    }
    return new Map(
      response.packagingComponents.edges.map(({ node }) => [node.name, node.id])
    );
  };
}

function useCreateRecipeTagSet({ organizationId }: { organizationId: string }) {
  const fetchRecipeTags = useFetchRecipeTags();

  const [addRecipeTag] = useMutation<
    AddRecipeCollection,
    AddRecipeCollectionVariables
  >(addRecipeTagMutation);

  return async () => {
    const recipeTags = await fetchRecipeTags();
    const recipeTagNameToId = new Map(
      recipeTags.map((recipeTag) => [recipeTag.name, recipeTag.id])
    );

    return {
      nameToId: async (name: string) => {
        if (!recipeTagNameToId.has(name)) {
          const response = await addRecipeTag({
            variables: {
              input: { name, ownerOrganizationId: organizationId },
            },
          });
          recipeTagNameToId.set(
            name,
            response.addRecipeCollection.recipeCollection.id
          );
        }

        const id = recipeTagNameToId.get(name);
        if (id === undefined) {
          throw new Error(`missing ID for name: ${name}`);
        }
        return id;
      },
    };
  };
}

function useFetchRecipeTags() {
  const graphQL = useGraphQL();
  const [organizationId] = useOrganizationId();

  return () =>
    paging.fetchAll({
      fetchPage: async ({ after }) => {
        const response = await graphQL.fetch<TagsQuery, TagsQueryVariables>({
          query: tagsQuery,
          variables: {
            after,
            filter: { ownerOrganizations: { id: organizationId } },
          },
        });
        return response.recipeCollections;
      },
    });
}

function useFindDuplicateRecipes(): (
  recipes: Array<ParsedRecipe>
) => Promise<Array<DuplicateRecipe>> {
  const fetchExistingRecipes = useFetchExistingRecipes();

  return async (parsedRecipes) => {
    const existingRecipes = await fetchExistingRecipes;

    return mapNotNull(parsedRecipes, (parsedRecipe) => {
      const duplicateRecipe = findExistingRecipe(
        existingRecipes,
        parsedRecipe.name,
        parsedRecipe.tagNames
      );
      return duplicateRecipe === undefined
        ? null
        : {
            existingRecipeId: duplicateRecipe.id,
            parsedRecipeRowIndex: parsedRecipe.rowIndex,
          };
    });
  };
}

function useFindSubrecipes(): (
  recipes: Array<ParsedRecipeWithAncestors>,
  allParsedRecipes: Array<ParsedRecipe>
) => Promise<Array<Subrecipe>> {
  const fetchExistingRecipes = useFetchExistingRecipes();

  return async (recipes, allParsedRecipes) => {
    const existingRecipes = await fetchExistingRecipes;

    return mapNotNull(recipes, (recipe) => {
      return mapNotNull(recipe.ingredients, (ingredient) => {
        let parsedSubrecipe: ParsedRecipe | undefined;
        const subrecipeId = ingredient.subrecipeId;
        if (subrecipeId !== null) {
          parsedSubrecipe = allParsedRecipes.find(
            (parsedRecipe) => parsedRecipe.code === subrecipeId.toString()
          );
        } else {
          parsedSubrecipe = allParsedRecipes.find(
            (parsedRecipe) => ingredient.name === parsedRecipe.name
          );
        }

        if (parsedSubrecipe !== undefined) {
          if (
            recipe.ancestors.find(
              (ancestor) => ancestor.clientId === parsedSubrecipe!.clientId
            )
          ) {
            const ancestorString = [
              ...recipe.ancestors,
              recipe,
              parsedSubrecipe,
            ]
              .map(
                (ancestor) => `${ancestor.name} (row ${ancestor.rowIndex + 2})`
              )
              .join(" uses ");
            throw new UserVisibleError(
              `Recipe loop detected in ${recipe.name} (row ${
                recipe.rowIndex + 2
              }). ${ancestorString}`
            );
          }

          return {
            kind: "inFile" as const,
            ingredientId: ingredient.id,
            name: ingredient.name,
            parsedRecipeRowIndex: recipe.rowIndex,
            parsedSubrecipe: {
              ...parsedSubrecipe,
              ancestors: [...recipe.ancestors, recipe],
            },
            useRecipeId: null,
          };
        } else {
          const existingRecipe = findExistingRecipe(
            existingRecipes,
            ingredient.name,
            recipe.tagNames
          );
          if (existingRecipe === undefined) {
            if (
              subrecipeOnlyQuantityUnits
                .flatMap((unit) => [unit.value, unit.label])
                .includes(ingredient.unit)
            ) {
              throw new SubrecipeOnlyInvalidUnitError(
                ingredient.name,
                ingredient.unit,
                recipe.name,
                recipe.rowIndex
              );
            }

            return null;
          } else {
            return {
              kind: "existing" as const,
              ingredientId: ingredient.id,
              name: ingredient.name,
              parsedRecipeRowIndex: recipe.rowIndex,
              parsedSubrecipe: null,
              useRecipeId: existingRecipe.id,
            };
          }
        }
      });
    }).flat();
  };
}

function findExistingRecipe(
  existingRecipes: Array<ExistingRecipe>,
  name: string,
  recipeTagNames: Array<string> | null
) {
  return existingRecipes.find(
    (existingRecipe) =>
      existingRecipe.name === name &&
      (recipeTagNames === null ||
        existingRecipe.collections.some((existingRecipeTag) =>
          recipeTagNames?.includes(existingRecipeTag.name)
        ))
  );
}

function useFetchExistingRecipes() {
  const graphQL = useGraphQL();
  const [organizationId] = useOrganizationId();

  return useMemoOne(
    () =>
      paging.fetchAll({
        fetchPage: async ({ after }) => {
          const response = await graphQL.fetch<
            ExistingRecipesQuery,
            ExistingRecipesQueryVariables
          >({
            query: existingRecipesQuery,
            variables: {
              after,
              filter:
                recipesOwnedByOrganizationOrInUserGroupFilter(organizationId),
            },
          });
          return response.recipes;
        },
      }),
    [graphQL, organizationId]
  );
}

function useFetchUploadedRecipes() {
  const graphQL = useGraphQL();

  return async (recipeIds: Array<number>) =>
    paging.fetchAll({
      fetchPage: async ({ after }) => {
        const response = await graphQL.fetch<
          UploadedRecipesQuery,
          UploadedRecipesQueryVariables
        >({
          query: uploadedRecipesQuery,
          variables: {
            after,
            recipeFilter: { ids: recipeIds },
          },
        });
        return response.recipes;
      },
    });
}

export function useCreateIngredientNameToFoodClassIdMap() {
  const { trackIngredientLinked } = useTracking();
  return function (
    ingredientNameStatuses: Array<IngredientNameStatus>,
    pageName: "Ingredients" | "Upload Recipes"
  ): Map<string, number | null> {
    return new Map<string, number | null>(
      ingredientNameStatuses.map(
        ({
          ingredientName,
          foodClassName,
          foodClassId,
          isAccepted,
          isExact,
          numOccurrences,
          selectedAlternativeFoodClassId,
          selectedAlternativeName,
        }) => {
          let selectedFoodClassId: number | null = null;
          if (isExact) {
            selectedFoodClassId = foodClassId;
          } else if (isAccepted) {
            selectedFoodClassId = selectedAlternativeFoodClassId;
          }
          if (!isExact) {
            trackIngredientLinked({
              ingredientName,
              numOccurrences,
              page: pageName,
              recommendedFoodClass: foodClassName ?? null,
              recommendedFoodClassSelected: isAccepted
                ? selectedAlternativeFoodClassId === foodClassId
                : false,
              selectedFoodClass: isAccepted ? selectedAlternativeName : null,
            });
          }

          return [ingredientName, selectedFoodClassId];
        }
      )
    );
  };
}

export function setIngredientNameStatusErrors(
  ingredientNameStatuses: Array<IngredientNameStatus>
) {
  let hasError: boolean = false;
  const updatedStatuses: Array<IngredientNameStatus> = [];
  for (const status of ingredientNameStatuses) {
    const ingredientHasError =
      !status.isExact &&
      status.isAccepted &&
      status.selectedAlternativeFoodClassId === null;
    if (ingredientHasError) {
      hasError = true;
    }
    updatedStatuses.push({ ...status, hasError: ingredientHasError });
  }
  return { updatedStatuses, hasError };
}

const addRecipeTagMutation = gql`
  mutation UploadRecipesPage_AddRecipeCollection(
    $input: AddRecipeCollectionInput!
  ) {
    addRecipeCollection(input: $input) {
      recipeCollection {
        id
      }
    }
  }
`;

const tagsQuery = gql`
  query UploadRecipesPage_CollectionsQuery(
    $after: String
    $filter: RecipeCollectionFilter!
  ) {
    recipeCollections(after: $after, filter: $filter) {
      edges {
        node {
          ...UploadRecipesPage_RecipeCollection
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment UploadRecipesPage_RecipeCollection on RecipeCollection {
    id
    name
  }
`;

const foodClassLookupQuery = gql`
  query UploadRecipesPage_FoodClassLookupQuery(
    $names: [String!]!
    $locale: String!
  ) {
    foodClassLookup(names: $names, locale: $locale) {
      name
      foodClass {
        id
        name
        synonyms {
          name
          locale
          isDefaultForLocale
        }
      }
    }
  }
`;

const existingRecipesQuery = gql`
  query UploadRecipesPage_ExistingRecipesQuery(
    $after: String
    $filter: RecipeFilter!
  ) {
    recipes(after: $after, filter: $filter) {
      edges {
        node {
          ...UploadRecipesPage_ExistingRecipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment UploadRecipesPage_ExistingRecipe on Recipe {
    collections {
      name
    }
    id
    name
  }
`;

function packagingComponentQuery() {
  return gql`
    query UploadRecipesPage_PackagingComponentLookupQuery(
      $organizationId: UUID!
      $first: Int!
    ) {
      packagingComponents(
        filter: { organizationId: $organizationId }
        first: $first
      ) {
        edges {
          node {
            id
            name
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;
}

const uploadedRecipesQuery = gql`
  query UploadRecipesPage_UploadedRecipesQuery(
    $after: String
    $recipeFilter: RecipeFilter!
  ) {
    recipes(after: $after, filter: $recipeFilter) {
      edges {
        node {
          ...UploadRecipesPage_UploadedRecipe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  fragment UploadRecipesPage_UploadedRecipe on Recipe {
    id
    impact(excludePackaging: false, fetchStaleImpacts: false) {
      effects {
        ghgPerRootRecipeServing
      }
      weightKgPerServing
    }
  }
`;
