import React from "react";
import { FormattedMessage } from "react-intl";

import { allUnitStringsForParsing } from "../../domain/units";

export interface ColumnDescription {
  id: string;
  columnNameCandidates: Array<string>; // Use first element in the array as the default column name.
  description: React.ReactNode;
}

export function getDefaultColumnName(
  columnDescription: ColumnDescription
): string {
  return columnDescription.columnNameCandidates[0];
}

export const recipeColumnDescription: ColumnDescription = {
  id: "recipe",
  columnNameCandidates: ["Product Name", "Recipe Name", "Name"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:recipeColumnDescription"
      defaultMessage="The name of the product."
    />
  ),
};

export const tagColumnDescription: ColumnDescription = {
  id: "tag",
  columnNameCandidates: ["Tag", "Collection", "Category"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:collectionColumnDescription"
      defaultMessage="The name of the tag that the product belongs to. <em>(Optional)</em>"
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
      }}
    />
  ),
};

export const servingsColumnDescription: ColumnDescription = {
  id: "servings",
  columnNameCandidates: ["Servings", "# Servings", "Ptn no."],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:servingsColumnDescription"
      defaultMessage="The number of servings in the product. <em>(Optional - you can leave this column out and select a value to apply to all the products in the CSV in the next step)</em>"
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
      }}
    />
  ),
};

export const ingredientColumnDescription: ColumnDescription = {
  id: "ingredient",
  columnNameCandidates: [
    "Ingredient",
    "Ingredient Name",
    "Ingredient - Name",
    "Material Description",
  ],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:ingredientColumnDescription"
      defaultMessage="The name of the ingredient."
    />
  ),
};

export const recipeNotCookedColumnDescription: ColumnDescription = {
  id: "recipe not cooked",
  columnNameCandidates: [
    "Recipe Not Cooked",
    "Product Not Cooked",
    "Not Cooked",
  ],
  description: null,
};

export const recipeNotStoredColumnDescription: ColumnDescription = {
  id: "recipe not stored",
  columnNameCandidates: [
    "Recipe Not Stored",
    "Product Not Stored",
    "Not Stored",
  ],
  description: null,
};

export const unitColumnDescription: ColumnDescription = {
  id: "unit",
  columnNameCandidates: ["Unit", "Ingredient Unit", "Ingredient - UoM"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:unitColumnDescription"
      defaultMessage="The unit of measurement for the ingredient quantity. <em>(Case-insensitive. We support the following units: {units})</em>"
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
        units: Array.from(allUnitStringsForParsing).join(", "),
      }}
    />
  ),
};

export const quantityColumnDescription: ColumnDescription = {
  id: "quantity",
  columnNameCandidates: [
    "Quantity",
    "Ingredient Quantity",
    "Ingredient - Quantity",
  ],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:quantityColumnDescription"
      defaultMessage="The quantity of the ingredient, using the unit specified in the <medium>{unitColumnName}</medium> column."
      values={{
        medium: (chunks: React.ReactNode) => (
          <span className="medium-font">{chunks}</span>
        ),
        unitColumnName: getDefaultColumnName(unitColumnDescription),
      }}
    />
  ),
};

export const weightInGramsColumnDescription: ColumnDescription = {
  id: "weight in grams",
  columnNameCandidates: ["Weight in grams"],
  description: null,
};

export const codeColumnDescription: ColumnDescription = {
  id: "code",
  columnNameCandidates: ["Code", "Recipe"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:codeColumnDescription"
      defaultMessage="A unique identifier for the product. <em>(Optional)</em>"
      values={{ em: (chunks: React.ReactNode) => <em>{chunks}</em> }}
    />
  ),
};

export const ingredientLossPercentageColumnDescription: ColumnDescription = {
  id: "ingredientLossPercentage",
  columnNameCandidates: ["Ingredient Loss (%)", "Ingredient Loss / %"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:ingredientLossPercentageColumnDescription"
      defaultMessage="The percentage lost for the ingredient if known. <em>(Optional - an average will be used if not provided).</em>"
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
      }}
    />
  ),
};

export const productWeightColumnDescription: ColumnDescription = {
  id: "productWeight",
  columnNameCandidates: ["Product Weight (kg)", "Product Weight / kg"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:productWeightColumnDescription"
      defaultMessage="The net weight of product you would like to assess, e.g. 800g of Cauli Bites that will be packaged and sold to retail, in kg."
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
      }}
    />
  ),
};

export const processingWeightColumnDescription: ColumnDescription = {
  id: "processingWeight",
  columnNameCandidates: ["Processing Weight (kg)", "Processing Weight / kg"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:processingWeightColumnDescription"
      defaultMessage="The physical output of processing of one batch of the recipe in kg."
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
      }}
    />
  ),
};

export const productProcessingEnergyColumnDescription: ColumnDescription = {
  id: "productProcessingEnergy",
  columnNameCandidates: ["Processing Energy (kWh)", "Processing Energy / kWh"],
  description: (
    <FormattedMessage
      id="components/recipes/recipes-csv-columns:productProcessingEnergyColumnDescription"
      defaultMessage="The energy consumed during processing of one batch of the product, measured in kilowatt-hours (kWh)."
      values={{
        em: (chunks: React.ReactNode) => <em>{chunks}</em>,
      }}
    />
  ),
};

export const productProcessingLossPercentageColumnDescription: ColumnDescription =
  {
    id: "productProcessingLossPercentage",
    columnNameCandidates: ["Product Loss (%)", "Product Loss / %"],
    description: (
      <FormattedMessage
        id="components/recipes/recipes-csv-columns:productProcessingLossPercentageColumnDescription"
        defaultMessage="The percentage of product loss during processing. <em>(Optional - an average will be used if not provided).</em>"
        values={{
          em: (chunks: React.ReactNode) => <em>{chunks}</em>,
        }}
      />
    ),
  };

export const subrecipeIdColumnDescription: ColumnDescription = {
  id: "subrecipeId",
  columnNameCandidates: ["subrecipeId"],
  description: null,
};

export const columnDescriptions: Array<ColumnDescription> = [
  recipeColumnDescription,
  tagColumnDescription,
  servingsColumnDescription,
  ingredientColumnDescription,
  quantityColumnDescription,
  unitColumnDescription,
  codeColumnDescription,
];

export const manufacturerColumnDescriptions: Array<ColumnDescription> = [
  recipeColumnDescription,
  tagColumnDescription,
  ingredientColumnDescription,
  quantityColumnDescription,
  unitColumnDescription,
  codeColumnDescription,
  productWeightColumnDescription,
  processingWeightColumnDescription,
  productProcessingEnergyColumnDescription,
  productProcessingLossPercentageColumnDescription,
  ingredientLossPercentageColumnDescription,
];
