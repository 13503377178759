import { gql } from "graphql-tag";
import { FormattedMessage } from "react-intl";

import { useTracking } from "../../tracking";
import * as statuses from "../../util/statuses";
import { SetRequired } from "../../util/types";
import useMutation from "../graphql/useMutation";
import {
  extractNodesFromPagedQueryResult,
  usePagedQueryFetchAll,
} from "../graphql/usePagedQuery";
import useQuery from "../graphql/useQuery";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import PackagingComponentEditor, {
  PackagingComponentInput,
} from "../packaging/PackagingComponentEditor/PackagingComponentEditor";
import StatusDisplay from "../StatusDisplay";
import BackButton from "../utils/BackButton";
import Card from "../utils/Card";
import {
  AddPackagingComponentV2,
  AddPackagingComponentV2Variables,
  PackagingComponentCard_PackagingComponentV2 as PackagingComponentV2,
  PackagingComponentCard_PackagingComponentV2Query as PackagingComponentV2Query,
  PackagingComponentCard_PackagingComponentV2QueryVariables as PackagingComponentV2QueryVariables,
  PackagingComponentCard_PackagingMaterialsV2Query as PackagingMaterialsV2Query,
  UpdatePackagingComponentV2,
  UpdatePackagingComponentV2Variables,
} from "./PackagingComponentCard.graphql";

type PackagingMaterialV2 =
  PackagingMaterialsV2Query["packagingMaterialsV2"]["edges"][0]["node"];

interface PackagingComponentCardLoaderProps {
  onAfterSave: () => void;
  onDiscard: () => void;
  packagingComponentId?: string;
}

export default function PackagingComponentCardLoader(
  props: PackagingComponentCardLoaderProps
) {
  const { onAfterSave, onDiscard, packagingComponentId } = props;

  if (packagingComponentId === undefined) {
    return (
      <PackagingComponentCard
        onAfterSave={onAfterSave}
        onDiscard={onDiscard}
        packagingComponent={undefined}
      />
    );
  } else {
    return (
      <ExistingPackagingComponentLoader
        onAfterSave={onAfterSave}
        onDiscard={onDiscard}
        packagingComponentId={packagingComponentId}
      />
    );
  }
}

interface ExistingPackagingComponentLoaderProps
  extends SetRequired<
    PackagingComponentCardLoaderProps,
    "packagingComponentId"
  > {}

function ExistingPackagingComponentLoader(
  props: ExistingPackagingComponentLoaderProps
) {
  const { onAfterSave, onDiscard, packagingComponentId } = props;

  const [organizationId] = useOrganizationId();

  const { status } = useQuery<
    PackagingComponentV2Query,
    PackagingComponentV2QueryVariables
  >(packagingComponentQuery, {
    componentId: packagingComponentId,
    organizationId,
  });

  return (
    <StatusDisplay
      status={statuses.map(
        status,
        (data) => data.packagingComponents.edges.map((edge) => edge.node)[0]
      )}
    >
      {(packagingComponent) => (
        <PackagingComponentCard
          onAfterSave={onAfterSave}
          onDiscard={onDiscard}
          packagingComponent={packagingComponent}
        />
      )}
    </StatusDisplay>
  );
}

type PackagingComponentCardProps = Omit<
  PackagingComponentCardLoaderProps,
  "packagingComponentId"
> & { packagingComponent?: PackagingComponentV2 }; // Type should be replaced with PackagingComponentCard_PackagingComponent

function PackagingComponentCard(props: PackagingComponentCardProps) {
  const { onAfterSave, onDiscard, packagingComponent } = props;

  const [addPackagingComponent] = useMutation<
    AddPackagingComponentV2,
    AddPackagingComponentV2Variables
  >(addPackagingComponentV2Mutation);

  const [updatePackagingComponent] = useMutation<
    UpdatePackagingComponentV2,
    UpdatePackagingComponentV2Variables
  >(updatePackagingComponentV2Mutation);

  const { trackPackagingComponentSubmitted } = useTracking();

  async function handleSave(input: PackagingComponentInput): Promise<void> {
    const isNewComponent = packagingComponent === undefined;

    if (isNewComponent) {
      await addPackagingComponent({
        variables: { input: { ownerOrganizationId: organizationId, ...input } },
      });
    } else {
      await updatePackagingComponent({
        variables: {
          input: {
            ...input,
            id: packagingComponent.id,
            ownerOrganizationId: organizationId,
          },
        },
      });
    }

    trackPackagingComponentSubmitted({
      isNew: isNewComponent,
    });

    onAfterSave();
  }

  const [organizationId] = useOrganizationId();
  const packagingMaterialsStatus = usePackagingMaterialsV2();

  return (
    <div
      className="d-flex justify-content-center flex-row"
      style={{ marginBottom: "3em" }}
    >
      <Card className="d-flex flex-column mw-100 p-4">
        <div className="d-flex align-items-center mb-4">
          <BackButton back={() => onDiscard()} />
          <h2 className="my-0 ml-3">
            {packagingComponent !== undefined ? (
              <FormattedMessage
                id="components/packaging/PackagingComponentCard:editPackagingComponentTitle"
                defaultMessage="Edit {packagingComponentTitle}"
                values={{ packagingComponentTitle: packagingComponent.name }}
              />
            ) : (
              <FormattedMessage
                id="components/packaging/PackagingComponentCard:createPackagingComponentTitle"
                defaultMessage="Create new packaging component"
              />
            )}
          </h2>
        </div>

        <StatusDisplay status={packagingMaterialsStatus}>
          {(packagingMaterials) => (
            <PackagingComponentEditor
              availableMaterials={packagingMaterials}
              onDiscard={onDiscard}
              onSave={handleSave}
              packagingComponent={packagingComponent}
            />
          )}
        </StatusDisplay>
      </Card>
    </div>
  );
}

function usePackagingMaterialsV2(): statuses.Status<
  Array<PackagingMaterialV2>
> {
  const { status } = usePagedQueryFetchAll<
    PackagingMaterialsV2Query,
    {},
    PackagingMaterialV2
  >(packagingMaterialsV2Query, {}, (data) => data.packagingMaterialsV2);

  return statuses.map(status, extractNodesFromPagedQueryResult);
}

PackagingComponentCard.fragments = {
  packagingComponentV2: gql`
    fragment PackagingComponentCard_PackagingComponentV2 on PackagingComponentV2 {
      ...PackagingComponentEditor_PackagingComponentV2
    }

    ${PackagingComponentEditor.fragments.packagingComponentV2}
  `,
};

const packagingComponentQuery = gql`
  query PackagingComponentCard_PackagingComponentV2Query(
    $componentId: UUID!
    $organizationId: UUID!
  ) {
    packagingComponents(
      filter: { ids: [$componentId], organizationId: $organizationId }
      first: 1
    ) {
      edges {
        node {
          ...PackagingComponentCard_PackagingComponentV2
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${PackagingComponentCard.fragments.packagingComponentV2}
`;

const packagingMaterialsV2Query = gql`
  query PackagingComponentCard_PackagingMaterialsV2Query($after: String) {
    packagingMaterialsV2(after: $after, first: 1000) {
      edges {
        node {
          ...PackagingComponentEditor_PackagingMaterialV2
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${PackagingComponentEditor.fragments.packagingMaterialV2}
`;

const addPackagingComponentV2Mutation = gql`
  mutation AddPackagingComponentV2($input: AddPackagingComponentV2Input!) {
    addPackagingComponentV2(input: $input) {
      success
    }
  }
`;

const updatePackagingComponentV2Mutation = gql`
  mutation UpdatePackagingComponentV2(
    $input: UpdatePackagingComponentV2Input!
  ) {
    updatePackagingComponentV2(input: $input) {
      success
    }
  }
`;
