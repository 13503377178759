import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useHistory, useLocation } from "react-router-dom";

import { useShouldHideIngredientsPage } from "../../services/useFeatureFlags";
import {
  useProductTags,
  useDashboard,
  useIsHeadOffice,
  useLandUse,
  useProductPackaging,
  useScope3Report,
  useSystemBoundary,
  useViewSharedProducts,
  useWaterUse,
} from "../../services/useOrganizationFeatures";
import Footer from "../footer/Footer";
import { useOrganization } from "../organizations/OrganizationProvider";
import { NAV_ICON_WIDTH, usePages } from "../pages";
import SystemBoundaryDisplay from "../recipes/SystemBoundaryDisplay";
import RotatingChevron from "../utils/RotatingChevron";
import TooltipOverlay from "../utils/TooltipOverlay";
import UpgradeRequestModal from "../utils/UpgradeRequestModal";
import {
  Dashboard as DashboardIcon,
  Inputs,
  Private as PrivateIcon,
  Products as ProductsIcon,
} from "../utils/Vectors";
import Report from "../utils/Vectors/Report";
import "./Navbar.css";

export enum NavbarItemType {
  MenuItem,
  SubMenuItem,
}

type NavbarMenuItemLockedMessage = {
  message: React.ReactNode;
  maxWidth?: string;
};

export type NavbarMenuItem = {
  type: NavbarItemType.MenuItem;
  locked?: boolean;
  lockedMessage?: NavbarMenuItemLockedMessage;
  symbol?: React.ReactNode;
  title: string;
  url: string;
};

type NavbarSubmenuId =
  | "products"
  | "inputs"
  | "reports"
  | "ghgEmissionsReporting";

type NavbarSubMenuItem = {
  type: NavbarItemType.SubMenuItem;
  id: NavbarSubmenuId;
  locked?: boolean;
  lockedMessage?: NavbarMenuItemLockedMessage;
  title: string;
  symbol: React.ReactNode;
  subItems: Array<NavbarItem>;
};

export type NavbarItem = NavbarMenuItem | NavbarSubMenuItem;

interface NavbarProps {
  items?: Array<NavbarItem>;
}

export default function Navbar(props: NavbarProps) {
  const { items } = props;
  const hasFeatureViewSharedProducts = useViewSharedProducts();
  const shouldHideIngredientsPage = useShouldHideIngredientsPage();
  const {
    Dashboard,
    Recipes,
    Collections,
    Ingredients,
    LandUse,
    Packaging,
    ProcurementDashboard,
    Scope3,
    SharedProducts,
    WaterUse,
  } = usePages();
  const [organization] = useOrganization();
  const canViewCollectionsPage = useProductTags();
  const canViewDashboardPage = useDashboard();
  const canViewPackagingPage = useProductPackaging();
  const isHeadOffice = useIsHeadOffice();
  const landUse = useLandUse();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const scope3Report = useScope3Report();
  const systemBoundary = useSystemBoundary();

  const waterUse = useWaterUse();

  const [showUpgradeRequestModal, setShowUpgradeRequestModal] =
    useState<boolean>(false);

  const showSharedProductsPage =
    organization.parentId !== null && hasFeatureViewSharedProducts;

  const productFootprintNavbarItems = (): NavbarItem[] => {
    if (isHeadOffice) {
      return [
        {
          type: NavbarItemType.MenuItem,
          ...Dashboard(!canViewDashboardPage),
        },
      ];
    }

    if (process.env.REACT_APP_MODE === "product-report") {
      return [
        { type: NavbarItemType.MenuItem, ...Dashboard(!canViewDashboardPage) },
        { type: NavbarItemType.MenuItem, ...Recipes },
        {
          type: NavbarItemType.MenuItem,
          ...Collections(!canViewCollectionsPage),
        },
      ];
    } else {
      const productsMenuItemBase = {
        symbol: <ProductsIcon width={NAV_ICON_WIDTH} />,
        title: intl.formatMessage({
          id: "components/pages:ProductsSubMenuTitle",
          defaultMessage: "Products",
        }),
      };

      const productsMenuItem: NavbarItem = {
        ...productsMenuItemBase,
        type: NavbarItemType.MenuItem,
        url: Recipes.url,
      };
      const navbarItems: NavbarItem[] = [
        { type: NavbarItemType.MenuItem, ...Dashboard(!canViewDashboardPage) },

        productsMenuItem,
        {
          id: "inputs",
          type: NavbarItemType.SubMenuItem,
          subItems: shouldHideIngredientsPage
            ? [
                {
                  type: NavbarItemType.MenuItem,
                  ...Packaging(!canViewPackagingPage),
                },
              ]
            : [
                { type: NavbarItemType.MenuItem, ...Ingredients },
                {
                  type: NavbarItemType.MenuItem,
                  ...Packaging(!canViewPackagingPage),
                },
              ],
          symbol: <Inputs width={NAV_ICON_WIDTH} />,
          title: intl.formatMessage({
            id: "components/pages:InputsSubMenuTitle",
            defaultMessage: "Inputs",
          }),
        },
      ];

      if (showSharedProductsPage) {
        navbarItems.push({ type: NavbarItemType.MenuItem, ...SharedProducts });
      }

      return navbarItems;
    }
  };

  const corporateReportingNavbarItems = (): NavbarItem[] => {
    if (!scope3Report) {
      return [];
    }
    const ghgEmissionsSubItems: NavbarMenuItem[] = [
      {
        type: NavbarItemType.MenuItem,
        ...Scope3,
        title: intl.formatMessage({
          id: "components/pages:scope3.1/navbarTitle",
          defaultMessage: "Scope 3.1",
        }),
      },
    ];

    let subItems: Array<NavbarSubMenuItem | NavbarMenuItem> = [
      {
        id: "ghgEmissionsReporting",
        subItems: ghgEmissionsSubItems,
        symbol: null,
        title: intl.formatMessage({
          id: "components/pages:GHGEmissionsReporting",
          defaultMessage: "GHG Emissions",
        }),
        type: NavbarItemType.SubMenuItem,
      },
    ];
    if (landUse) {
      subItems.push({
        type: NavbarItemType.MenuItem,
        ...LandUse,
        title: intl.formatMessage({
          id: "components/pages:landUse/navbarTitle",
          defaultMessage: "Land Use",
        }),
      });
    }
    if (waterUse) {
      subItems.push({
        type: NavbarItemType.MenuItem,
        ...WaterUse,
        title: intl.formatMessage({
          id: "components/pages:waterUse/navbarTitle",
          defaultMessage: "Water Use",
        }),
      });
    }

    const dashboardItem: NavbarItem = {
      type: NavbarItemType.MenuItem,
      ...ProcurementDashboard,
      symbol: <DashboardIcon width={NAV_ICON_WIDTH} />,
    };

    if (isHeadOffice) {
      return [dashboardItem];
    }

    const reportsItem: NavbarItem = {
      id: "reports",
      title: intl.formatMessage({
        id: "components/pages:ReportsSubMenuTitle",
        defaultMessage: "Reports",
      }),
      type: NavbarItemType.SubMenuItem,
      subItems,
      symbol: <Report width={NAV_ICON_WIDTH} />,
    };

    return [dashboardItem, reportsItem];
  };

  const isActiveLink = (url: string): boolean => {
    if (url === "/") {
      return location.pathname === "/";
    }
    return location.pathname.startsWith(url);
  };

  const isActiveSubmenuItem = (subMenuItem: NavbarSubMenuItem): boolean => {
    return subMenuItem.subItems.some(
      (item) =>
        (item.type === NavbarItemType.MenuItem && isActiveLink(item.url)) ||
        (item.type === NavbarItemType.SubMenuItem && isActiveSubmenuItem(item))
    );
  };

  const initialOpenSubmenuIds = (): Array<NavbarSubmenuId> => {
    const initialOpenIds: Array<NavbarSubmenuId> = [];
    const initialNavbarItems = [
      ...productFootprintNavbarItems(),
      ...corporateReportingNavbarItems(),
    ];
    function findOpenSubmenuIds(navbarItems: Array<NavbarItem>) {
      navbarItems.forEach((item) => {
        if (
          item.type === NavbarItemType.SubMenuItem &&
          isActiveSubmenuItem(item)
        ) {
          initialOpenIds.push(item.id);
          findOpenSubmenuIds(item.subItems);
        }
      });
      return initialOpenIds;
    }
    return findOpenSubmenuIds(initialNavbarItems);
  };

  const [openSubmenuIds, setOpenSubmenuIds] = useState<Array<NavbarSubmenuId>>(
    initialOpenSubmenuIds()
  );

  const handleMenuItemClicked = (page: NavbarMenuItem) => {
    if (!page.locked) {
      history.push(page.url);
    }
  };

  const handleNavbarItemClicked = (item: NavbarItem) => {
    if (item.type === NavbarItemType.MenuItem) {
      handleMenuItemClicked(item);
    } else {
      handleSubmenuClick(item.id);
    }
  };

  const renderNavbarItem = (item: NavbarItem, parentHasIcon: boolean) => {
    return (
      <MenuItem
        key={item.title}
        icon={item.symbol}
        className={classNames({
          Navbar__MenuItem__active:
            !item.locked &&
            item.type === NavbarItemType.MenuItem &&
            isActiveLink(item.url),
          Navbar__MenuItem__locked: item.locked,
          SubMenu__Item: item.type === NavbarItemType.SubMenuItem,
        })}
        onClick={() => handleNavbarItemClicked(item)}
      >
        <div
          className={classNames("w-100 d-flex justify-content-between", {
            IndentedNavbarItem:
              parentHasIcon &&
              item.type === NavbarItemType.MenuItem &&
              !item.symbol,
            "pl-3":
              !parentHasIcon &&
              item.type === NavbarItemType.MenuItem &&
              !item.symbol,
          })}
        >
          {item.type === NavbarItemType.MenuItem ? (
            <>
              <div>{item.title}</div>
              {item.locked && (
                <div className="private-icon">
                  <TooltipOverlay
                    id="components/navigation/Navbar:privateIconTooltip"
                    overlay={item.lockedMessage?.message}
                    placement="right"
                    style={{
                      maxWidth: item.lockedMessage?.maxWidth ?? "188px",
                    }}
                  >
                    <PrivateIcon
                      width={16}
                      handleClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        setShowUpgradeRequestModal(true);
                      }}
                    />
                  </TooltipOverlay>
                </div>
              )}
            </>
          ) : (
            renderSubMenu(item)
          )}
        </div>
      </MenuItem>
    );
  };

  const handleSubmenuClick = (submenuId: NavbarSubmenuId) => {
    if (openSubmenuIds.includes(submenuId)) {
      setOpenSubmenuIds(openSubmenuIds.filter((_) => _ !== submenuId));
    } else {
      setOpenSubmenuIds([...openSubmenuIds, submenuId]);
    }
  };

  const renderTopLevelNavbarItem = (item: NavbarItem) => {
    if (item.type === NavbarItemType.MenuItem) {
      return renderNavbarItem(item, false);
    } else {
      return renderSubMenu(item);
    }
  };

  const renderSubMenu = (item: NavbarSubMenuItem) => {
    return (
      <ul key={`navbar-submenu-${item.id}`} className="SubMenuListWrapper">
        <SubMenu
          key={item.title}
          onClick={() => handleNavbarItemClicked(item)}
          className={classNames("Navbar__SubMenu", {
            Navbar__SubMenu__closed_and_active:
              !openSubmenuIds.includes(item.id) && isActiveSubmenuItem(item),
          })}
          label={
            <>
              <div className="SubMenu__Label__Title">{item.title}</div>
              <div className="SubMenu__Label__Chevron__Container">
                <RotatingChevron expanded={openSubmenuIds.includes(item.id)} />
              </div>
            </>
          }
          defaultOpen={isActiveSubmenuItem(item)}
          icon={item.symbol}
        >
          {item.subItems.map((subItem) => {
            return renderNavbarItem(subItem, item.symbol !== null);
          })}
        </SubMenu>
      </ul>
    );
  };

  const corporateReportingSectionIsActive = () => {
    return corporateReportingNavbarItems().some((item) => {
      return (
        (item.type === NavbarItemType.SubMenuItem &&
          isActiveSubmenuItem(item)) ||
        (item.type === NavbarItemType.MenuItem && isActiveLink(item.url))
      );
    });
  };

  return (
    <>
      <div className="Navbar_SidebarAndFooterContainer">
        <Sidebar className="Navbar__Sidebar">
          <Menu className="p-3">
            {items ? (
              items.map(renderTopLevelNavbarItem)
            ) : (
              <>
                <div className="small-copy-medium text-muted Navbar_SectionHeader">
                  <FormattedMessage
                    id="components/navigation/Navbar:productFootprints"
                    defaultMessage="Product Footprints"
                  />
                </div>
                {productFootprintNavbarItems().map(renderTopLevelNavbarItem)}
                <SystemBoundaryDisplay
                  className="Navbar_SystemBoundaryDisplay"
                  inactive={corporateReportingSectionIsActive()}
                  systemBoundary={systemBoundary}
                />
                {scope3Report && (
                  <>
                    <hr />
                    <div className="small-copy-medium text-muted Navbar_SectionHeader">
                      <FormattedMessage
                        id="components/navigation/Navbar:corporateReporting"
                        defaultMessage="Corporate Reporting"
                      />
                    </div>
                    {corporateReportingNavbarItems().map(
                      renderTopLevelNavbarItem
                    )}
                  </>
                )}
              </>
            )}
          </Menu>
        </Sidebar>
        <Footer />
      </div>
      <UpgradeRequestModal
        onHide={() => setShowUpgradeRequestModal(false)}
        show={showUpgradeRequestModal}
      />
    </>
  );
}
