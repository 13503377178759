import UserVisibleError from "../../util/UserVisibleError";
import {
  codeColumnDescription,
  tagColumnDescription,
  getDefaultColumnName,
  recipeColumnDescription,
  servingsColumnDescription,
} from "./recipes-csv-columns";
import {
  FailedToParseError,
  MissingFieldError,
  MissingFieldOnNamedRecipeError,
  UnexpectedDataTypeError,
} from "./recipes-json-errors";

export interface CaternetJsonDataFormat {
  recipes: Array<CaternetRecipe>;
}

const caternetJsonDataFormatHeaders: {
  [K in keyof Required<CaternetJsonDataFormat>]: K;
} = { recipes: "recipes" };

interface CaternetRecipe {
  categories?: Array<string>;
  ingredients: Array<CaternetIngredient>;
  name: string;
  nestedRecipes?: Array<NestedRecipe>;
  recipeId: number;
  servings: Array<{ quantity: number }>;
}

const caternetRecipeColumnHeaders: {
  [K in keyof Required<CaternetRecipe>]: K;
} = {
  categories: "categories",
  ingredients: "ingredients",
  name: "name",
  nestedRecipes: "nestedRecipes",
  recipeId: "recipeId",
  servings: "servings",
};

interface NestedRecipe {
  nestedRecipeId: number;
  nestedRecipeName: string;
  nestedRecipePortions?: number;
  nestedRecipeAmount: number;
  nestedRecipeAmountUnit: string;
}

interface CaternetIngredient {
  approxWeight?: number;
  approxWeightUnit?: string;
  ingredient: string;
  nestedRecipeId?: number;
  quantity: number;
  subrecipeId?: number;
  unit: string;
}

const caternetIngredientColumnHeaders: {
  [K in keyof Required<CaternetIngredient>]: K;
} = {
  approxWeight: "approxWeight",
  approxWeightUnit: "approxWeightUnit",
  ingredient: "ingredient",
  nestedRecipeId: "nestedRecipeId",
  quantity: "quantity",
  subrecipeId: "subrecipeId",
  unit: "unit",
};

const excludedCaternetIngredientHeaders: Array<keyof CaternetIngredient> = [
  "approxWeight",
  "approxWeightUnit",
  "nestedRecipeId",
];

const ingredientCsvHeaders = Object.values(
  caternetIngredientColumnHeaders
).filter((header) => !excludedCaternetIngredientHeaders.includes(header));

export function convertCaternetRecipesJsonToCsv(text: string): string {
  if (text === "") {
    return "";
  }

  let data: CaternetJsonDataFormat;
  try {
    data = JSON.parse(text);
  } catch (e) {
    let errorMessage: string | undefined = undefined;
    if (e instanceof SyntaxError) {
      errorMessage = e.message;
    }
    throw new UserVisibleError(
      errorMessage ?? "Failed to parse JSON file",
      errorMessage !== undefined ? null : FailedToParseError
    );
  }
  validateDataFormat(data);

  data.recipes.forEach((recipe, recipeIndex) => {
    if (recipe.ingredients === undefined) {
      throw new MissingFieldOnNamedRecipeError(
        "ingredients",
        recipe.name,
        recipeIndex
      );
    }
  });

  const headers: Array<string> = [
    getDefaultColumnName(recipeColumnDescription),
    getDefaultColumnName(codeColumnDescription),
    getDefaultColumnName(servingsColumnDescription),
    getDefaultColumnName(tagColumnDescription),
    ...ingredientCsvHeaders,
  ];

  let csvText: string = headers.join(",") + "\n";

  data.recipes.forEach((recipe, recipeIndex) => {
    validateRecipe(recipe, recipeIndex);

    if (recipe.nestedRecipes !== undefined) {
      for (const nestedRecipe of recipe.nestedRecipes) {
        const nestedRecipeIngredient: CaternetIngredient = {
          ingredient: nestedRecipe.nestedRecipeName,
          quantity: nestedRecipe.nestedRecipeAmount,
          subrecipeId: nestedRecipe.nestedRecipeId,
          unit: nestedRecipe.nestedRecipeAmountUnit,
        };

        const ingredientRowValues = generateIngredientRowValues(
          nestedRecipeIngredient
        );
        csvText += generateCsvRow(recipe, ingredientRowValues);
      }
    }

    const recipeIngredients = recipe.ingredients.filter(
      (ingredient) => ingredient.nestedRecipeId === undefined
    );

    for (const ingredient of recipeIngredients) {
      const ingredientRowValues = generateIngredientRowValues(ingredient);
      csvText += generateCsvRow(recipe, ingredientRowValues);
    }
  });
  return csvText;
}

function generateIngredientRowValues(
  ingredient: CaternetIngredient
): Array<string> {
  return [
    ...ingredientCsvHeaders.map((header) => {
      if (
        header === caternetIngredientColumnHeaders.quantity &&
        ingredient[caternetIngredientColumnHeaders.approxWeight] !== undefined
      ) {
        return JSON.stringify(
          ingredient[caternetIngredientColumnHeaders.approxWeight]
        );
      } else if (
        header === caternetIngredientColumnHeaders.unit &&
        ingredient[caternetIngredientColumnHeaders.approxWeight] !==
          undefined &&
        ingredient[caternetIngredientColumnHeaders.approxWeightUnit] !==
          undefined
      ) {
        return JSON.stringify(
          ingredient[caternetIngredientColumnHeaders.approxWeightUnit]
        );
      }
      return JSON.stringify(ingredient[header]) ?? "";
    }),
  ];
}

function generateCsvRow(
  recipe: CaternetRecipe,
  rowValues: Array<string>
): string {
  return (
    [
      JSON.stringify(recipe.name),
      JSON.stringify(recipe.recipeId),
      JSON.stringify(recipe.servings[0].quantity),
      recipe.categories !== undefined
        ? JSON.stringify(
            recipe.categories
              .map((category) => category.replace(/,/g, "\\,"))
              .join(",")
          )
        : "",
      rowValues.join(","),
    ].join(",") + "\n"
  );
}

function validateDataFormat(data: CaternetJsonDataFormat) {
  if (typeof data !== "object" || data.recipes === undefined) {
    throw new MissingFieldError(caternetJsonDataFormatHeaders.recipes, 0);
  }

  if (!Array.isArray(data.recipes)) {
    throw new UnexpectedDataTypeError(
      caternetJsonDataFormatHeaders.recipes,
      "List",
      0
    );
  }
}

function validateRecipe(recipe: CaternetRecipe, recipeIndex: number) {
  if (recipe.name === undefined) {
    throw new MissingFieldError(caternetRecipeColumnHeaders.name, recipeIndex);
  } else if (
    recipe.servings === undefined ||
    recipe.servings[0] === undefined ||
    recipe.servings[0].quantity === undefined
  ) {
    throw new MissingFieldOnNamedRecipeError(
      caternetRecipeColumnHeaders.servings,
      recipe.name,
      recipeIndex
    );
  } else if (!Array.isArray(recipe.ingredients)) {
    throw new UnexpectedDataTypeError(
      caternetRecipeColumnHeaders.ingredients,
      "List",
      recipeIndex
    );
  }
}
