import VectorProps from "../VectorProps";

export default function GhgEmissions(props: VectorProps) {
  const { width } = props;

  // from https://www.figma.com/file/Z5JvdIZfbok8AxNw5lmpCU/DQS?node-id=15663%3A7005&mode=dev

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3723_3662)">
        <path
          d="M12.6034 23.1761C19.0033 23.1761 24.1915 17.9879 24.1915 11.588C24.1915 5.18814 19.0033 0 12.6034 0C6.20352 0 1.01538 5.18814 1.01538 11.588C1.01538 17.9879 6.20352 23.1761 12.6034 23.1761Z"
          fill="#67DCC2"
        />
        <path
          d="M17.6001 31.1495C25.0129 31.1495 31.0221 25.1403 31.0221 17.7276C31.0221 10.3148 25.0129 4.30563 17.6001 4.30563C10.1874 4.30563 4.17822 10.3148 4.17822 17.7276C4.17822 25.1403 10.1874 31.1495 17.6001 31.1495Z"
          fill="var(--foodsteps-turquoise)"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M28.9754 15.1495C28.5502 14.9369 28.1249 14.9369 27.487 15.5748C27.216 15.8459 27.2744 16.1063 27.2744 16.6379C27.2744 16.8037 27.1061 17.571 26.9095 17.701C26.1822 18.1818 24.9387 17.9434 24.404 17.701C23.9192 17.481 23.1793 17.5575 22.9156 18.02C22.4904 18.7641 22.8093 19.5083 23.5535 19.8273C23.9904 20.0144 24.9925 20 25.0913 21.0909C25.1685 21.9441 23.7661 22.0598 23.6598 23.2293C23.5535 24.3987 22.7147 24.9175 22.3362 25.8275C21.9577 26.7376 21.8132 27.2415 21.3592 27.8336C20.7479 28.6299 19.6465 29.0126 18.6727 28.7671C18.3091 28.6746 17.9593 28.4981 17.7063 28.2206C16.9526 27.3967 17.2853 26.1018 17.1556 24.9919C17.0504 24.0989 16.5985 23.2505 15.9171 22.6647C15.1697 22.0226 14.2001 21.7143 13.3422 21.2306C12.4843 20.7469 11.9793 20.0675 11.9665 19.082C11.9538 18.0869 12.312 16.8824 13.2423 16.5305C14.1725 16.1786 16.1042 16.0766 17.0695 16.3179C18.3453 16.6368 20.0633 16.7389 20.7904 15.7863C21.0956 15.3866 21.1721 14.7253 20.6841 14.298C19.8336 13.5538 18.6642 14.5106 17.8137 14.1917C17.1843 13.9556 17.028 12.9021 16.0064 12.9159C14.9847 12.9297 14.3479 12.9797 13.6675 13.6601C13.0296 14.298 11.789 14.2852 11.435 13.3412C11.116 12.4907 11.7496 12.0612 12.1791 11.7465C12.6086 11.4318 12.6011 11.0632 12.7276 10.5455C12.7968 10.2616 12.9233 8.98235 13.7738 8.5571C15.0953 7.8969 15.1899 9.93916 16.3253 9.51391C17.1758 9.19498 16.9377 8.33597 17.1758 7.91923C17.6011 7.17504 18.1263 7.08468 18.6642 6.85611C19.806 6.37026 20.5672 5.91949 21.1115 4.7692C26.5356 6.23524 30.5977 11.015 30.9911 16.8037C30.9911 16.8037 29.4017 15.3611 28.9765 15.1485L28.9754 15.1495Z"
          fill="#DCFFD1"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.38037 12.1696C5.4027 12.1675 5.42609 12.1664 5.44841 12.1632C6.55725 12.0207 7.48323 11.9208 8.3518 11.216C9.23313 10.5005 9.73599 9.53622 10.0528 8.66446C10.2729 8.05848 9.92097 7.51629 9.57545 6.96878C7.77452 8.31469 6.32336 10.1018 5.38143 12.1696H5.38037Z"
          fill="var(--label-light-turquoise)"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.39417 23.5482C7.25703 22.8869 7.10394 22.3713 6.86261 21.7409C6.58513 21.0179 6.67337 20.1111 6.65743 19.3371C6.64998 18.9767 6.54367 18.1262 6.22473 17.701C5.67404 16.9664 5.16161 16.5316 4.27709 16.0967C4.21224 16.6315 4.17822 17.1747 4.17822 17.7276C4.17822 20.9403 5.30832 23.8894 7.19111 26.2007C7.2464 26.1284 7.30168 26.055 7.35377 25.9806C7.87576 25.2375 7.57915 24.4369 7.39417 23.5482Z"
          fill="var(--label-light-turquoise)"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3723_3662">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
