import { FormattedMessage } from "react-intl";

import { DietaryCategory } from "../../__generated__/globalTypes";

export const orderedDietsAndMessages: [DietaryCategory, JSX.Element][] = [
  [
    DietaryCategory.VEGAN,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:VEGAN"
      defaultMessage="Vegan"
    />,
  ],
  [
    DietaryCategory.VEGETARIAN,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:VEGETARIAN"
      defaultMessage="Vegetarian"
    />,
  ],
  [
    DietaryCategory.SEAFOOD,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:SEAFOOD"
      defaultMessage="Seafood"
    />,
  ],
  [
    DietaryCategory.POULTRY,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:POULTRY"
      defaultMessage="Poultry"
    />,
  ],
  [
    DietaryCategory.PORK,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:PORK"
      defaultMessage="Pork"
    />,
  ],
  [
    DietaryCategory.GAME_MEAT,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:GAME_MEAT"
      defaultMessage="Game Meat"
    />,
  ],
  [
    DietaryCategory.RUMINANT_MEAT,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:RUMINANT_MEAT"
      defaultMessage="Ruminant Meat"
    />,
  ],
  [
    DietaryCategory.UNKNOWN,
    <FormattedMessage
      id="components/product-filter/ProductFilterSelect:dietName:UNKNOWN"
      defaultMessage="Unknown"
    />,
  ],
];
