import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { parseUnit } from "../../domain/units";
import { mapNotNull } from "../../util/arrays";
import assertNever from "../../util/assertNever";
import * as csv from "../../util/csv";
import { ParsedIngredient, parseIngredient } from "./ingredient-parser";
import {
  codeColumnDescription,
  tagColumnDescription,
  ColumnDescription,
  getDefaultColumnName,
  ingredientColumnDescription,
  ingredientLossPercentageColumnDescription,
  productProcessingEnergyColumnDescription,
  productProcessingLossPercentageColumnDescription,
  productWeightColumnDescription,
  processingWeightColumnDescription,
  quantityColumnDescription,
  recipeColumnDescription,
  recipeNotCookedColumnDescription,
  recipeNotStoredColumnDescription,
  servingsColumnDescription,
  subrecipeIdColumnDescription,
  unitColumnDescription,
  weightInGramsColumnDescription,
} from "./recipes-csv-columns";
import {
  ConflictingRecipeDataError,
  DuplicateRecipesConflictingDataError,
  InvalidUnitError,
  MissingColumnError,
  RecipeWithDuplicateNameHasMissingInformationError,
} from "./recipes-csv-errors";

interface ParsedBoolean {
  rawValue: string | null;
  parsedValue: boolean;
}

export interface ParsedRecipe {
  clientId: string;
  code: string | null;
  tagNames: Array<string> | null;
  ingredients: Array<ParsedIngredient>;
  name: string;
  notCooked: ParsedBoolean;
  notStored: ParsedBoolean;
  rowIndex: number;
  servings: number | null;
  skipUpload?: boolean;
  productWeight?: number | null;
  productProcessingWeight?: number | null;
  productProcessingEnergy?: number | null;
  productProcessingLossPercentage?: number | null;
}

export interface ParseRecipesCsvOutput {
  parsedRecipes: Array<ParsedRecipe>;
  servingsCountRequired: boolean;
}

export function parseRecipesCsv(
  text: string,
  foodManufacturerOrganization = false
): ParseRecipesCsvOutput {
  if (text === "") {
    return { parsedRecipes: [], servingsCountRequired: false };
  }

  const { headers, body } = csv.parse(text);
  const format = detectFormat(headers, foodManufacturerOrganization);

  const servingsCountRequired = foodManufacturerOrganization
    ? false
    : format.servingsHeader === null;

  const rows = body.filter((row) => !row.isEmpty());

  if (format.type === "rowPerRecipe") {
    const parsedRecipes = rows.map((row, rowIndex) => ({
      clientId: uuidv4(),
      code: row.readOptional(format.codeHeader),
      tagNames: readTagNames(row, format.tagNameHeader),
      ingredients: mapNotNull(format.ingredientHeaderIndices, (headerIndex) => {
        const ingredientString = row.values[headerIndex];
        if (ingredientString === "") {
          return null;
        } else {
          return parseIngredient(ingredientString);
        }
      }),
      name: row.readCompulsory(format.recipeNameHeader),
      notCooked: readRecipeNotCooked(row, format.recipeNotCookedHeader),
      notStored: readRecipeNotStored(row, format.recipeNotStoredHeader),
      rowIndex,
      servings: readServings(row, format.servingsHeader),
    }));
    return { parsedRecipes, servingsCountRequired };
  } else if (
    format.type === "rowPerIngredient" ||
    format.type === "rowPerIngredientManufacturer"
  ) {
    const rowsByRecipeIdentifier = new Map<string, ParsedRecipe>();
    const rowsByRecipeName = new Map<string, Array<ParsedRecipe>>();

    rows.forEach((row, rowIndex) => {
      const recipeName = row.readCompulsory(format.recipeNameHeader);
      const tagNames = readTagNames(row, format.tagNameHeader);

      const recipeIdentifier = createRecipeIdentifier(recipeName, tagNames);

      let recipe = rowsByRecipeIdentifier.get(recipeIdentifier);
      if (recipe === undefined) {
        recipe = {
          clientId: uuidv4(),
          name: recipeName,
          code: row.readOptional(format.codeHeader),
          tagNames,
          ingredients: [],
          notCooked: readRecipeNotCooked(row, format.recipeNotCookedHeader),
          notStored: readRecipeNotStored(row, format.recipeNotStoredHeader),
          rowIndex,
          servings: readServings(row, format.servingsHeader),
        };
        if (format.type === "rowPerIngredientManufacturer") {
          recipe = {
            ...recipe,
            productWeight: foodManufacturerOrganization
              ? row.readCompulsoryNumber(format.productWeightHeader!)
              : null,
            productProcessingWeight: foodManufacturerOrganization
              ? row.readCompulsoryNumber(format.processingWeightHeader!)
              : null,
            productProcessingEnergy: foodManufacturerOrganization
              ? row.readCompulsoryNumber(
                  format.productProcessingEnergyHeader!,
                  {
                    canBeZero: true,
                  }
                )
              : null,
            productProcessingLossPercentage: row.readOptionalNumber(
              format.productProcessingLossPercentageHeader,
              { canBeZero: true }
            ),
          };
        }
        rowsByRecipeIdentifier.set(recipeIdentifier, recipe);

        if (!rowsByRecipeName.has(recipeName)) {
          rowsByRecipeName.set(recipeName, [recipe]);
        } else {
          const duplicateNameRecipes = rowsByRecipeName.get(
            recipeName
          ) as ParsedRecipe[];
          rowsByRecipeName.set(recipeName, [...duplicateNameRecipes, recipe]);
          validateDuplicateRecipesHaveDistinctCodes(
            [...duplicateNameRecipes, recipe],
            format
          );
        }
      } else {
        validateRecipeRowIsConsistentWithRecipe(row, recipe, format);
      }

      const ingredientName = row.readCompulsory(format.ingredientNameHeader);
      const ingredientSubrecipeId = row.readOptionalNumber(
        format.subrecipeIdHeader
      );

      let ingredientQuantity: number;
      let ingredientQuantityUnit: string;

      if (format.ingredientQuantityFormat.type === "weightInGrams") {
        ingredientQuantity = row.readCompulsoryNumber(
          format.ingredientQuantityFormat.weightInGramsHeader
        );
        ingredientQuantityUnit = "g";
      } else if (format.ingredientQuantityFormat.type === "quantityWithUnit") {
        ingredientQuantity = row.readCompulsoryNumber(
          format.ingredientQuantityFormat.quantityHeader
        );
        ingredientQuantityUnit = readUnitValue(
          row,
          format.ingredientQuantityFormat.quantityUnitHeader,
          recipeName
        );
      } else {
        assertNever(
          format.ingredientQuantityFormat,
          "unhandled ingredient quantity format"
        );
      }

      const ingredient = {
        id: uuidv4(),
        name: ingredientName,
        quantity: ingredientQuantity,
        subrecipeId: ingredientSubrecipeId,
        unit: ingredientQuantityUnit,
        ...(format.type === "rowPerIngredientManufacturer" && {
          processLossPercentage: row.readOptionalNumber(
            format.ingredientLossPercentageHeader,
            { canBeZero: true }
          ),
        }),
      };
      recipe.ingredients.push(ingredient);
    });

    const parsedRecipes = Array.from(rowsByRecipeIdentifier.values());
    return { parsedRecipes, servingsCountRequired };
  } else {
    return assertNever(format, "unhandled format type");
  }
}

function readUnitValue(
  row: csv.Row,
  header: csv.Header,
  recipeName: string
): string {
  const unitInput = row.readCompulsory(header);
  const unit = parseUnit(unitInput);
  if (unit === null) {
    throw new InvalidUnitError(
      unitInput,
      header.name,
      recipeName,
      row.rowNumber()
    );
  } else {
    return unit.value;
  }
}

function readTagNames(
  row: csv.Row,
  header: csv.Header | null
): Array<string> | null {
  if (header === null) {
    return null;
  }
  const tagNames = row.readOptional(header);
  if (tagNames === null) {
    return null;
  }
  const tagNamesArray = tagNames.match(/(?:\\,|[^,])+/g); // Defines a word as a sequence of non-commas or escaped commas
  return tagNamesArray!.map((tagName) => tagName.replace(/\\/g, ""));
}

function readRecipeNotCooked(
  row: csv.Row,
  header: csv.Header | null
): ParsedBoolean {
  return {
    rawValue: row.readRaw(header),
    parsedValue: row.readOptionalBoolean(header) ?? false,
  };
}

function readRecipeNotStored(
  row: csv.Row,
  header: csv.Header | null
): ParsedBoolean {
  return {
    rawValue: row.readRaw(header),
    parsedValue: row.readOptionalBoolean(header) ?? false,
  };
}

function readServings(row: csv.Row, header: csv.Header | null): number | null {
  if (header === null) {
    return null;
  } else {
    return row.readCompulsoryNumber(header);
  }
}

type Format =
  | {
      type: "rowPerRecipe";
      codeHeader: csv.Header | null;
      tagNameHeader: csv.Header | null;
      ingredientHeaderIndices: Array<number>;
      recipeNameHeader: csv.Header;
      recipeNotCookedHeader: csv.Header | null;
      recipeNotStoredHeader: csv.Header | null;
      servingsHeader: csv.Header | null;
    }
  | {
      type: "rowPerIngredient";
      codeHeader: csv.Header | null;
      tagNameHeader: csv.Header | null;
      ingredientNameHeader: csv.Header;
      ingredientQuantityFormat: RowPerIngredientIngredientQuantityFormat;
      recipeNameHeader: csv.Header;
      recipeNotCookedHeader: csv.Header | null;
      recipeNotStoredHeader: csv.Header | null;
      servingsHeader: csv.Header | null;
      subrecipeIdHeader: csv.Header | null;
    }
  | {
      type: "rowPerIngredientManufacturer";
      codeHeader: csv.Header | null;
      tagNameHeader: csv.Header | null;
      ingredientNameHeader: csv.Header;
      ingredientQuantityFormat: RowPerIngredientIngredientQuantityFormat;
      recipeNameHeader: csv.Header;
      recipeNotCookedHeader: csv.Header | null;
      recipeNotStoredHeader: csv.Header | null;
      servingsHeader: csv.Header | null;
      subrecipeIdHeader: csv.Header | null;
      ingredientLossPercentageHeader: csv.Header | null;
      productWeightHeader: csv.Header;
      processingWeightHeader: csv.Header;
      productProcessingEnergyHeader: csv.Header;
      productProcessingLossPercentageHeader: csv.Header | null;
    };
type RowPerIngredientIngredientQuantityFormat =
  | { type: "weightInGrams"; weightInGramsHeader: csv.Header }
  | {
      type: "quantityWithUnit";
      quantityHeader: csv.Header;
      quantityUnitHeader: csv.Header;
    };

function detectFormat(
  headers: Array<string>,
  foodManufacturerOrganization: boolean
): Format {
  const recipeNameHeader = findRecipeNameHeader(headers);
  const tagNameHeader = findTagHeaderOrNull(headers);
  const ingredientNameHeader = findIngredientNameHeaderOrNull(headers);
  const recipeNotCookedHeader = findRecipeNotCookedHeaderOrNull(headers);
  const recipeNotStoredHeader = findRecipeNotStoredHeaderOrNull(headers);
  const servingsHeader = findServingsHeaderOrNull(headers);
  const codeHeader = findCodeHeaderOrNull(headers);

  const subrecipeIdHeader = findSubrecipeIdHeaderOrNull(headers);

  if (ingredientNameHeader === null) {
    const ingredientHeaderIndices = mapNotNull(headers, (header, headerIndex) =>
      /^ingredients[0-9]+$/.test(header) ? headerIndex : null
    );

    return {
      type: "rowPerRecipe",
      codeHeader,
      tagNameHeader,
      ingredientHeaderIndices,
      recipeNameHeader,
      recipeNotCookedHeader,
      recipeNotStoredHeader,
      servingsHeader,
    };
  } else {
    const weightInGramsHeader = findWeightInGramsHeaderOrNull(headers);

    const ingredientQuantityFormat =
      weightInGramsHeader === null
        ? {
            type: "quantityWithUnit" as const,
            quantityHeader: findIngredientQuantityHeader(headers),
            quantityUnitHeader: findIngredientQuantityUnitHeader(headers),
          }
        : {
            type: "weightInGrams" as const,
            weightInGramsHeader,
          };

    if (foodManufacturerOrganization) {
      const ingredientLossPercentageHeader =
        findIngredientLossPercentageHeaderOrNull(headers);
      const productWeightHeader = findProductWeightHeader(headers);
      const processingWeightHeader = findProcessingWeightHeader(headers);
      const productProcessingEnergyHeader =
        findProductProcessingEnergyHeader(headers);
      const productProcessingLossPercentageHeader =
        findProductProcessingLossPercentageHeaderOrNull(headers);

      return {
        type: "rowPerIngredientManufacturer",
        codeHeader,
        tagNameHeader,
        ingredientNameHeader,
        ingredientQuantityFormat,
        recipeNameHeader,
        recipeNotCookedHeader,
        recipeNotStoredHeader,
        servingsHeader,
        subrecipeIdHeader,
        ingredientLossPercentageHeader,
        productWeightHeader,
        processingWeightHeader,
        productProcessingEnergyHeader,
        productProcessingLossPercentageHeader,
      };
    } else {
      return {
        type: "rowPerIngredient",
        codeHeader,
        tagNameHeader,
        ingredientNameHeader,
        ingredientQuantityFormat,
        recipeNameHeader,
        recipeNotCookedHeader,
        recipeNotStoredHeader,
        servingsHeader,
        subrecipeIdHeader,
      };
    }
  }
}

function findTagHeaderOrNull(headers: Array<string>): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    tagColumnDescription.columnNameCandidates
  );
}

function findIngredientNameHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    ingredientColumnDescription.columnNameCandidates
  );
}

function findIngredientQuantityHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, quantityColumnDescription);
}

function findIngredientQuantityUnitHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, unitColumnDescription);
}

function findRecipeNameHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, recipeColumnDescription);
}

function findCompulsoryHeader(
  headers: Array<string>,
  columnDescription: ColumnDescription
): csv.Header {
  const header = csv.findHeaderOrNull(
    headers,
    columnDescription.columnNameCandidates
  );
  if (header === null) {
    throw new MissingColumnError(getDefaultColumnName(columnDescription));
  } else {
    return header;
  }
}

function findServingsHeaderOrNull(headers: Array<string>): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    servingsColumnDescription.columnNameCandidates
  );
}

function findWeightInGramsHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    weightInGramsColumnDescription.columnNameCandidates
  );
}

function findRecipeNotCookedHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    recipeNotCookedColumnDescription.columnNameCandidates
  );
}

function findRecipeNotStoredHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    recipeNotStoredColumnDescription.columnNameCandidates
  );
}

function findCodeHeaderOrNull(headers: Array<string>): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    codeColumnDescription.columnNameCandidates
  );
}

function findIngredientLossPercentageHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    ingredientLossPercentageColumnDescription.columnNameCandidates
  );
}

function findProductWeightHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, productWeightColumnDescription);
}

function findProcessingWeightHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(headers, processingWeightColumnDescription);
}

function findProductProcessingEnergyHeader(headers: Array<string>): csv.Header {
  return findCompulsoryHeader(
    headers,
    productProcessingEnergyColumnDescription
  );
}

function findProductProcessingLossPercentageHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    productProcessingLossPercentageColumnDescription.columnNameCandidates
  );
}

function findSubrecipeIdHeaderOrNull(
  headers: Array<string>
): csv.Header | null {
  return csv.findHeaderOrNull(
    headers,
    subrecipeIdColumnDescription.columnNameCandidates
  );
}

function createRecipeIdentifier(
  recipeName: string,
  tagNames: Array<string> | null
) {
  return JSON.stringify([recipeName, tagNames?.join(",") ?? ""]);
}

function validateDuplicateRecipesHaveDistinctCodes(
  recipesWithDuplicateNames: Array<ParsedRecipe>,
  format: Format
) {
  for (const currentRecipe of recipesWithDuplicateNames) {
    if (currentRecipe.code === null || currentRecipe.code === "") {
      throw new RecipeWithDuplicateNameHasMissingInformationError(
        format.codeHeader?.name ?? "Code",
        currentRecipe.name,
        currentRecipe.rowIndex
      );
    }
    const recipeWithIdenticalCode = recipesWithDuplicateNames.find(
      (recipe) =>
        recipe.code === currentRecipe.code &&
        recipe.rowIndex !== currentRecipe.rowIndex
    );
    if (recipeWithIdenticalCode !== undefined) {
      throw new DuplicateRecipesConflictingDataError(
        format.codeHeader?.name ?? "Code",
        currentRecipe.name,
        currentRecipe.rowIndex,
        recipeWithIdenticalCode.rowIndex,
        currentRecipe.code
      );
    }
  }
}

function validateRecipeRowIsConsistentWithRecipe(
  row: csv.Row,
  recipe: ParsedRecipe,
  format: Format
) {
  if (format.tagNameHeader !== null) {
    // Ensure that all rows in a recipe have the same tag name (or are all null).
    const tagNames = readTagNames(row, format.tagNameHeader);
    if (!isEqual(tagNames, recipe.tagNames)) {
      throw new ConflictingRecipeDataError(
        recipe.tagNames,
        tagNames,
        format.tagNameHeader.name,
        recipe.name
      );
    }
  }

  if (format.servingsHeader !== null) {
    // Ensure that all rows in a recipe have the same servings value.
    const servings = readServings(row, format.servingsHeader);
    if (servings !== recipe.servings) {
      throw new ConflictingRecipeDataError(
        recipe.servings,
        servings,
        format.servingsHeader.name,
        recipe.name
      );
    }
  }

  if (format.recipeNotCookedHeader !== null) {
    const recipeNotCooked = readRecipeNotCooked(
      row,
      format.recipeNotCookedHeader
    );
    if (recipeNotCooked.parsedValue !== recipe.notCooked.parsedValue) {
      throw new ConflictingRecipeDataError(
        recipe.notCooked.rawValue,
        recipeNotCooked.rawValue,
        format.recipeNotCookedHeader.name,
        recipe.name
      );
    }
  }

  if (format.recipeNotStoredHeader !== null) {
    const recipeNotStored = readRecipeNotStored(
      row,
      format.recipeNotStoredHeader
    );
    if (recipeNotStored.parsedValue !== recipe.notStored.parsedValue) {
      throw new ConflictingRecipeDataError(
        recipe.notStored.rawValue,
        recipeNotStored.rawValue,
        format.recipeNotStoredHeader.name,
        recipe.name
      );
    }
  }

  if (format.codeHeader !== null) {
    // Ensure that all rows in a recipe have the same code name (or are all null).
    const code = row.readOptional(format.codeHeader);
    if (code !== recipe.code) {
      throw new ConflictingRecipeDataError(
        recipe.code,
        code,
        format.codeHeader.name,
        recipe.name
      );
    }
  }

  if (
    format.type === "rowPerIngredientManufacturer" &&
    recipe.productWeight !== undefined
  ) {
    const productWeight = row.readOptionalNumber(format.productWeightHeader);
    if (productWeight !== recipe.productWeight) {
      throw new ConflictingRecipeDataError(
        recipe.productWeight,
        productWeight,
        format.productWeightHeader.name,
        recipe.name
      );
    }
  }

  if (
    format.type === "rowPerIngredientManufacturer" &&
    recipe.productProcessingWeight !== undefined
  ) {
    const productProcessingWeight = row.readOptionalNumber(
      format.processingWeightHeader
    );
    if (productProcessingWeight !== recipe.productProcessingWeight) {
      throw new ConflictingRecipeDataError(
        recipe.productProcessingWeight,
        productProcessingWeight,
        format.processingWeightHeader.name,
        recipe.name
      );
    }
  }

  if (
    format.type === "rowPerIngredientManufacturer" &&
    recipe.productProcessingEnergy !== undefined
  ) {
    const productProcessingEnergy = row.readOptionalNumber(
      format.productProcessingEnergyHeader,
      { canBeZero: true }
    );
    if (productProcessingEnergy !== recipe.productProcessingEnergy) {
      throw new ConflictingRecipeDataError(
        recipe.productProcessingEnergy,
        productProcessingEnergy,
        format.productProcessingEnergyHeader.name,
        recipe.name
      );
    }
  }

  if (
    format.type === "rowPerIngredientManufacturer" &&
    format.productProcessingLossPercentageHeader !== null &&
    recipe.productProcessingLossPercentage !== undefined
  ) {
    const productProcessingLossPercentage = row.readOptionalNumber(
      format.productProcessingLossPercentageHeader,
      { canBeZero: true }
    );
    if (
      productProcessingLossPercentage !== recipe.productProcessingLossPercentage
    ) {
      throw new ConflictingRecipeDataError(
        recipe.productProcessingLossPercentage,
        productProcessingLossPercentage,
        format.productProcessingLossPercentageHeader.name,
        recipe.name
      );
    }
  }
}

// Exported for testing only
export {
  findCodeHeaderOrNull as _findCodeHeaderOrNull,
  findTagHeaderOrNull as _findTagHeaderOrNull,
  findIngredientNameHeaderOrNull as _findIngredientNameHeaderOrNull,
  findIngredientQuantityHeader as _findIngredientQuantityHeader,
  findIngredientQuantityUnitHeader as _findIngredientQuantityUnitHeader,
  findRecipeNameHeader as _findRecipeNameHeader,
  findServingsHeaderOrNull as _findServingsHeaderOrNull,
  findSubrecipeIdHeaderOrNull as _findSubrecipeIdHeaderOrNull,
  findWeightInGramsHeaderOrNull as _findWeightInGramsHeaderOrNull,
};
