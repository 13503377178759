import compact from "lodash/compact";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FormattedMessage } from "react-intl";

import { RecipeFilter } from "../../__generated__/globalTypes";
import useUserInfo from "../../data-store/useUserInfo";
import { ImpactCategory } from "../../domain/impactCategories";
import { useLabels } from "../../services/useOrganizationFeatures";
import { RecipePageName } from "../../tracking";
import assertNever from "../../util/assertNever";
import { RecipeFilterWithExclude } from "../labels/DownloadLabelsModal";
import { useOrganization } from "../organizations/OrganizationProvider";
import { EditTagsButton } from "../tags/EditTagsButton";
import RotatingChevron from "../utils/RotatingChevron";
import { Bin, Download, SaveCopy } from "../utils/Vectors";
import {
  CopySuccessToastState,
  MultipleRecipeCopyToButton,
} from "./CopyToButton";
import { RecipesDropdownButton } from "./RecipesDropdown";
import { RecipeSelection, RecipeSelectionType } from "./RecipesPanel";
import useExportLabelsDropdownItem from "./useExportLabelsDropdownItem";
import useExportRecipeImpactsCsvDropdownItem from "./useExportRecipeImpactsCsvDropdownItem";
import useRecipeLabel from "./useRecipeLabel";
import "./ActionBar.css";

interface ActionBarProps {
  allProductsIncludingUnseenSelected: boolean;
  allProductsInViewSelected: boolean;
  impactCategory: ImpactCategory;
  onCollectionsDeleted: (
    deletedCollectionIds: Set<number>,
    collectionIdsRemovedFromRecipes: Set<number>
  ) => void;
  onDelete: () => void;
  onSelectAllProducts: () => void;
  pageName: RecipePageName;
  recipeFilter: RecipeFilter;
  recipeSelection: RecipeSelection;
  requiresAttentionIds: Array<number>;
  totalRecipeCount: number;
  setToastState: (state: CopySuccessToastState) => void;
}

export default function ActionBar(props: ActionBarProps) {
  const {
    allProductsIncludingUnseenSelected,
    allProductsInViewSelected,
    impactCategory,
    onCollectionsDeleted,
    onDelete,
    onSelectAllProducts,
    pageName,
    recipeFilter,
    recipeSelection,
    requiresAttentionIds,
    totalRecipeCount,
    setToastState,
  } = props;

  const [organization] = useOrganization();
  const recipeLabel = useRecipeLabel();
  const [{ isReadonly: userIsReadonly }] = useUserInfo();

  const showSelectAllOption =
    allProductsInViewSelected && !allProductsIncludingUnseenSelected;

  const numSelectedProducts =
    recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN
      ? totalRecipeCount - recipeSelection.excludedIds.length
      : recipeSelection.ids.length;

  const editRecipeFilter = (): RecipeFilterWithExclude => {
    if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
      return {
        ...recipeFilter,
        ownerOrganizations: { id: organization.id },
        excludedIds: recipeSelection.excludedIds,
      };
    } else if (recipeSelection.type === RecipeSelectionType.PARTIAL) {
      return {
        ownerOrganizations: { id: organization.id },
        ids: recipeSelection.ids,
      };
    } else {
      assertNever(recipeSelection, "Unsupported recipe selection type");
    }
  };

  const sharedProductsFilter = (): RecipeFilterWithExclude => {
    if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
      return {
        ...recipeFilter,
        ownerOrganizations: { id: organization.parentId },
        excludedIds: recipeSelection.excludedIds,
      };
    } else if (recipeSelection.type === RecipeSelectionType.PARTIAL) {
      return {
        ownerOrganizations: { id: organization.parentId },
        ids: recipeSelection.ids,
      };
    } else {
      assertNever(recipeSelection, "Unsupported recipe selection type");
    }
  };

  const exportRecipeFilter = (): RecipeFilterWithExclude => {
    if (recipeSelection.type === RecipeSelectionType.ALL_INCLUDING_UNSEEN) {
      return {
        ...recipeFilter,
        ownerOrganizations: { id: organization.id },
        excludedIds: recipeSelection.excludedIds,
      };
    } else if (recipeSelection.type === RecipeSelectionType.PARTIAL) {
      return {
        ownerOrganizations: { id: organization.id },
        ids: recipeSelection.ids.filter(
          (id) => !requiresAttentionIds.includes(id)
        ),
      };
    } else {
      assertNever(recipeSelection, "Unsupported recipe selection type");
    }
  };

  const editTagsButton = (
    <EditTagsButton
      control={(className, disabled, icon, label, onClick) => (
        <div className="ActionBar_ButtonContainer" onClick={onClick}>
          {icon}
          {label}
        </div>
      )}
      onCollectionsDeleted={onCollectionsDeleted}
      recipeFilter={editRecipeFilter()}
      selectedAll={allProductsIncludingUnseenSelected}
    />
  );

  const exportDropdown = (
    <ExportDropdown
      defaultImpactCategory={impactCategory}
      pageName={pageName}
      recipeFilter={exportRecipeFilter()}
    />
  );

  const deleteButton = (
    <div
      className="ActionBar_ButtonContainer ActionBar_EndButton"
      onClick={onDelete}
    >
      <Bin width={20} />
      <FormattedMessage
        id="components/recipes/ActionBar:delete"
        defaultMessage="Delete"
      />
    </div>
  );

  const copyToButtonControl = (onClick: () => void) => {
    return (
      <div
        className="ActionBar_ButtonContainer ActionBar_EndButton"
        onClick={onClick}
      >
        <SaveCopy width={20} />
        <FormattedMessage
          id="components/recipes/ActionBar:copyTo"
          defaultMessage="Copy to"
        />
      </div>
    );
  };

  const copyToButton = (
    <MultipleRecipeCopyToButton
      control={copyToButtonControl}
      recipeFilter={sharedProductsFilter()}
      setToastState={setToastState}
    />
  );

  const actions =
    pageName === "Shared Products"
      ? [copyToButton]
      : userIsReadonly
      ? [exportDropdown]
      : [editTagsButton, exportDropdown, deleteButton];

  return (
    <div className="ActionBar">
      <div className="ActionBar_NumberOfSelectedProducts">
        {allProductsIncludingUnseenSelected && numSelectedProducts !== 1 ? (
          <FormattedMessage
            id="components/recipes/ActionBar:allProductsSelected"
            defaultMessage="All {numSelectedProducts} {recipeLabel} selected"
            values={{
              numSelectedProducts: totalRecipeCount,
              recipeLabel: recipeLabel.pluralLowercase,
            }}
          />
        ) : showSelectAllOption ? (
          <FormattedMessage
            id="components/recipes/ActionBar:allProductsInViewSelected"
            defaultMessage="{numSelectedProducts} {recipeLabel} in view selected"
            values={{
              numSelectedProducts,
              recipeLabel:
                numSelectedProducts === 1
                  ? recipeLabel.singularLowercase
                  : recipeLabel.pluralLowercase,
            }}
          />
        ) : (
          <FormattedMessage
            id="components/recipes/ActionBar:numProductsSelected"
            defaultMessage="{numSelectedProducts} {recipeLabel} selected"
            values={{
              numSelectedProducts,
              recipeLabel:
                numSelectedProducts === 1
                  ? recipeLabel.singularLowercase
                  : recipeLabel.pluralLowercase,
            }}
          />
        )}
        <div
          className="medium-font ActionBar_SelectAllIncludingUnseen"
          onClick={onSelectAllProducts}
        >
          {showSelectAllOption && (
            <FormattedMessage
              id="components/recipes/ActionBar:selectAllProductsLabel"
              defaultMessage="Select all {totalRecipeCount}"
              values={{ totalRecipeCount }}
            />
          )}
        </div>
      </div>
      {actions.map((action) => (
        <>
          <Divider />
          {action}
        </>
      ))}
    </div>
  );
}

function Divider() {
  return <div className="ActionBar_Divider" />;
}

interface ExportDropdownProps {
  defaultImpactCategory: ImpactCategory;
  pageName: RecipePageName;
  recipeFilter: RecipeFilterWithExclude;
}

function ExportDropdown(props: ExportDropdownProps) {
  const { defaultImpactCategory, recipeFilter, pageName } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  const hasCarbonLabels = useLabels();

  const exportCarbonLabelsDropdownItem = useExportLabelsDropdownItem({
    defaultImpactCategory,
    recipeFilter,
    pageName,
    closeDropdown: () => setShowDropdown(false),
  });

  const exportRecipeImpactsCsvDropdownItem =
    useExportRecipeImpactsCsvDropdownItem({
      pageName,
      recipeFilter,
      closeDropdown: () => setShowDropdown(false),
    });

  const items = compact([
    hasCarbonLabels && exportCarbonLabelsDropdownItem,
    exportRecipeImpactsCsvDropdownItem,
  ]);

  return (
    <Dropdown show={showDropdown} onToggle={setShowDropdown} drop="up">
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        showDropdown={showDropdown}
      />
      <Dropdown.Menu>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Dropdown.Divider />}

            <RecipesDropdownButton
              className="RecipesDropdown__dropdownItem"
              item={item}
            />
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

type CustomToggleProps = {
  showDropdown: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        // @ts-ignore
        props.onClick(e);
      }}
    >
      <div className="ActionBar_ButtonContainer">
        <Download width={20} />
        <FormattedMessage
          id="components/recipes/ActionBar:export"
          defaultMessage="Export"
        />
        <RotatingChevron expanded={!props.showDropdown} />
      </div>
    </a>
  )
);
