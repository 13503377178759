import { FunctionalUnit } from "../../../../domain/functionalUnits";
import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  foodstepsTurquoiseCol,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { titleOver2Lines } from "./titleOver2Lines";
import {
  impactCategoryTitle,
  impactRatingToName,
  serving,
  impactCategoryUnitPerKg,
  impactCategoryUnit,
  impactRatingToLetter,
} from "./Translations";

export const lineHeight = 14;
interface DescriptiveImpactAndScaleProps {
  functionalUnit: FunctionalUnit;
  impactCategory: ImpactCategory;
  impactMagnitude: number;
  impactRating: ImpactRating;
  greyscale?: boolean;
  locale: string | null;
  width: number;
}

export default function DescriptiveImpactAndScale(
  props: DescriptiveImpactAndScaleProps
) {
  const {
    functionalUnit,
    impactCategory,
    impactMagnitude,
    impactRating,
    greyscale = false,
    locale,
    width,
  } = props;

  const impactMagnitudeFontSize = impactMagnitude > 1000 ? 22 : 26;
  const setHeight = (defaultHeight: number) => {
    return titleOver2Lines(impactCategory, locale)
      ? defaultHeight + lineHeight
      : defaultHeight;
  };

  return (
    <svg
      width={width}
      viewBox={`0 0 98 ${setHeight(204)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <rect
        x="1"
        y="23"
        width="96"
        height={setHeight(180)}
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d={`M2 32C2 27.5817 5.58172 24 10 24H88C92.4183 24 96 27.5817 96 32V${setHeight(
          88
        )}H2V32Z`}
        fill="white"
      />

      <text
        x={49}
        y={60}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>

      <Title impactCategory={impactCategory} locale={locale} />

      <rect
        width="94"
        height={setHeight(50)}
        transform={`translate(2 ${setHeight(88)})`}
        fill="white"
      />

      <text
        x={49}
        y={setHeight(111)}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactMagnitudeFontSize}
        fill="black"
      >
        {impactMagnitude.toFixed(2)}
      </text>

      <g
        transform={
          titleOver2Lines(impactCategory, locale)
            ? `translate(0 ${lineHeight})`
            : ""
        }
      >
        <Unit
          functionalUnit={functionalUnit}
          impactCategory={impactCategory}
          locale={locale}
        />

        <rect
          width="94"
          height="40"
          transform="translate(2 138)"
          fill="white"
        />

        <ImpactRatingScale
          greyscale={greyscale}
          impactRating={impactRating}
          locale={locale}
        />
        <path
          d="M2 178H96V194C96 198.418 92.4183 202 88 202H10C5.58172 202 2 198.418 2 194V178Z"
          fill={greyscale ? "black" : foodstepsTurquoiseCol}
        />
        <path
          d="M12.9453 193.672H15.0639V190.05H18.1372V188.454H15.0639V185.983H18.948V184.322H12.9453V193.672Z"
          fill="white"
        />
        <path
          d="M23.0543 192.378C21.9819 192.378 21.3803 191.593 21.3803 190.285V190.18C21.3803 188.873 22.0081 188.114 23.0543 188.114C24.1136 188.114 24.7282 188.899 24.7282 190.207V190.298C24.7282 191.593 24.1136 192.378 23.0543 192.378ZM23.0412 193.816C25.0944 193.816 26.6506 192.443 26.6506 190.285V190.18C26.6506 188.049 25.1075 186.676 23.0543 186.676C21.0011 186.676 19.4448 188.075 19.4448 190.22V190.324C19.4448 192.456 21.0011 193.816 23.0412 193.816Z"
          fill="white"
        />
        <path
          d="M31.1129 192.378C30.0405 192.378 29.4389 191.593 29.4389 190.285V190.18C29.4389 188.873 30.0667 188.114 31.1129 188.114C32.1722 188.114 32.7868 188.899 32.7868 190.207V190.298C32.7868 191.593 32.1722 192.378 31.1129 192.378ZM31.0998 193.816C33.153 193.816 34.7092 192.443 34.7092 190.285V190.18C34.7092 188.049 33.1661 186.676 31.1129 186.676C29.0597 186.676 27.5034 188.075 27.5034 190.22V190.324C27.5034 192.456 29.0597 193.816 31.0998 193.816Z"
          fill="white"
        />
        <path
          d="M38.5438 193.816C39.5508 193.816 40.3877 193.228 40.7408 192.534V193.672H42.624V183.733H40.7408V187.853C40.3616 187.16 39.6815 186.676 38.583 186.676C36.8698 186.676 35.5751 187.996 35.5751 190.246V190.35C35.5751 192.626 36.8829 193.816 38.5438 193.816ZM39.1061 192.338C38.1645 192.338 37.5106 191.671 37.5106 190.311V190.207C37.5106 188.847 38.0991 188.127 39.1584 188.127C40.1916 188.127 40.7931 188.82 40.7931 190.18V190.285C40.7931 191.671 40.1 192.338 39.1061 192.338Z"
          fill="white"
        />
        <path
          d="M46.7921 193.816C48.5968 193.816 49.6692 193.044 49.6692 191.567C49.6692 190.102 48.7276 189.67 47.0144 189.422C46.0205 189.278 45.6936 189.108 45.6936 188.677C45.6936 188.258 46.0467 187.97 46.6613 187.97C47.3152 187.97 47.6291 188.232 47.7337 188.794H49.473C49.303 187.225 48.1653 186.676 46.6483 186.676C45.2489 186.676 43.9412 187.382 43.9412 188.807C43.9412 190.141 44.6474 190.664 46.4652 190.939C47.446 191.083 47.8514 191.279 47.8514 191.75C47.8514 192.221 47.5114 192.495 46.779 192.495C45.9551 192.495 45.6544 192.142 45.5759 191.527H43.8104C43.8758 193.005 44.9482 193.816 46.7921 193.816Z"
          fill="white"
        />
        <path
          d="M53.3696 193.803C53.8796 193.803 54.2589 193.711 54.5204 193.62V192.155C54.2981 192.247 54.0889 192.286 53.8011 192.286C53.3173 192.286 53.0426 192.024 53.0426 191.475V188.18H54.4812V186.833H53.0426V185.355H51.1595V186.833H50.2832V188.18H51.1595V191.645C51.1595 193.071 51.931 193.803 53.3696 193.803Z"
          fill="white"
        />
        <path
          d="M58.9531 193.816C60.8232 193.816 62.0002 192.992 62.2226 191.514H60.4309C60.3132 192.09 59.8817 192.469 59.0055 192.469C57.9723 192.469 57.3577 191.815 57.3054 190.677H62.2356V190.154C62.2356 187.709 60.6663 186.676 58.9008 186.676C56.913 186.676 55.3829 188.075 55.3829 190.233V190.337C55.3829 192.521 56.8869 193.816 58.9531 193.816ZM57.3315 189.513C57.4754 188.546 58.0508 187.983 58.9008 187.983C59.8032 187.983 60.3263 188.48 60.3917 189.513H57.3315Z"
          fill="white"
        />
        <path
          d="M63.4769 196H65.3732V192.626C65.7263 193.293 66.4978 193.816 67.5571 193.816C69.2572 193.816 70.5389 192.548 70.5389 190.285V190.18C70.5389 187.918 69.2311 186.676 67.5571 186.676C66.5502 186.676 65.7524 187.251 65.3732 187.905V186.833H63.4769V196ZM66.9948 192.338C65.9617 192.338 65.3078 191.671 65.3078 190.298V190.194C65.3078 188.82 65.9878 188.127 66.9817 188.127C67.9364 188.127 68.6164 188.807 68.6164 190.194V190.298C68.6164 191.632 68.041 192.338 66.9948 192.338Z"
          fill="white"
        />
        <path
          d="M74.2885 193.816C76.0932 193.816 77.1656 193.044 77.1656 191.567C77.1656 190.102 76.224 189.67 74.5108 189.422C73.5169 189.278 73.1899 189.108 73.1899 188.677C73.1899 188.258 73.543 187.97 74.1577 187.97C74.8116 187.97 75.1254 188.232 75.2301 188.794H76.9694C76.7994 187.225 75.6616 186.676 74.1446 186.676C72.7453 186.676 71.4375 187.382 71.4375 188.807C71.4375 190.141 72.1437 190.664 73.9615 190.939C74.9424 191.083 75.3478 191.279 75.3478 191.75C75.3478 192.221 75.0077 192.495 74.2754 192.495C73.4515 192.495 73.1507 192.142 73.0722 191.527H71.3068C71.3721 193.005 72.4445 193.816 74.2885 193.816Z"
          fill="white"
        />
        <path
          d="M81.6594 186.848L81.6591 186.611C81.6592 186.475 81.7241 186.381 81.8534 186.33C82.3715 186.125 82.6021 185.528 82.3508 185.044C82.3036 184.953 82.2427 184.871 82.1695 184.799C81.9584 184.59 81.7455 184.383 81.5343 184.174C81.5114 184.152 81.4974 184.149 81.4726 184.172C81.3439 184.291 81.2137 184.408 81.0837 184.526C80.9907 184.61 80.8958 184.692 80.804 184.777C80.6159 184.952 80.5118 185.165 80.5002 185.419C80.4876 185.697 80.5844 185.936 80.7845 186.133C80.8726 186.22 80.9768 186.285 81.0939 186.33C81.2219 186.379 81.2922 186.48 81.2923 186.613C81.2924 186.778 81.2925 186.943 81.2922 187.108C81.2922 187.13 81.2975 187.156 81.2679 187.165C81.2386 187.174 81.2279 187.15 81.2139 187.132C81.0416 186.915 80.8181 186.789 80.5353 186.767C80.4535 186.76 80.4537 186.762 80.4615 186.84L80.4618 186.843C80.4793 187.018 80.5388 187.177 80.6458 187.319C80.7651 187.476 80.9221 187.584 81.1118 187.646C81.2294 187.685 81.2919 187.769 81.2923 187.891C81.2926 187.979 81.2923 188.068 81.2923 188.157C81.2923 188.415 81.1188 188.571 80.8599 188.518C80.9766 188.625 81.061 188.693 81.1715 188.72C81.4565 188.79 81.6728 188.879 82.0895 188.523C81.874 188.564 81.6815 188.371 81.6803 188.157C81.6799 188.054 81.6803 187.985 81.6803 187.882C81.6803 187.767 81.7423 187.683 81.8578 187.646C82.0279 187.591 82.1505 187.498 82.2657 187.365C82.3966 187.213 82.472 187.038 82.4862 186.839C82.4918 186.762 82.4915 186.761 82.4129 186.767C82.2011 186.782 82.0174 186.861 81.8597 186.999C81.8102 187.043 81.767 187.092 81.7272 187.143C81.715 187.159 81.7025 187.171 81.6803 187.164C81.6593 187.157 81.6593 187.14 81.6594 187.123V186.848Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.4875 188.58C83.221 188.58 84.6262 187.201 84.6262 185.5C84.6262 183.799 83.221 182.42 81.4875 182.42C79.7541 182.42 78.3489 183.799 78.3489 185.5C78.3489 187.201 79.7541 188.58 81.4875 188.58ZM81.4875 189C83.4573 189 85.0542 187.433 85.0542 185.5C85.0542 183.567 83.4573 182 81.4875 182C79.5177 182 77.9209 183.567 77.9209 185.5C77.9209 187.433 79.5177 189 81.4875 189Z"
          fill="white"
        />
      </g>

      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
  locale: string | null;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating, locale } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);
  const ratingLetter = impactRatingToLetter(impactRating, locale);
  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);

  const ratingLetterY = 161;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="12"
            y="139"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path d="M42 144H51V164H42V144Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="54"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 144H83C85.2091 144 87 145.791 87 148V160C87 162.209 85.2091 164 83 164H78V144Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M11 148C11 145.791 12.7909 144 15 144H20V164H15C12.7909 164 11 162.209 11 160V148Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="24"
            y="139"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 144H83C85.2091 144 87 145.791 87 148V160C87 162.209 85.2091 164 83 164H78V144Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M11 148C11 145.791 12.7909 144 15 144H20V164H15C12.7909 164 11 162.209 11 160V148Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="36"
            y="139"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 144H83C85.2091 144 87 145.791 87 148V160C87 162.209 85.2091 164 83 164H78V144Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M11 148C11 145.791 12.7909 144 15 144H20V164H15C12.7909 164 11 162.209 11 160V148Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="48"
            y="139"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 144H83C85.2091 144 87 145.791 87 148V160C87 162.209 85.2091 164 83 164H78V144Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M11 148C11 145.791 12.7909 144 15 144H20V164H15C12.7909 164 11 162.209 11 160V148Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="144"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M47 144H56V164H47V144Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="60"
            y="139"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface UnitProps {
  functionalUnit: FunctionalUnit;
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Unit(props: UnitProps) {
  const { functionalUnit, impactCategory, locale } = props;

  return (
    <text
      x={49}
      y={127}
      textAnchor="middle"
      fontFamily="Graphik Web"
      fontWeight={500}
      fontSize={unitFontSize(functionalUnit, impactCategory, locale)}
      fill="black"
    >
      {functionalUnit === FunctionalUnit.KG
        ? impactCategoryUnitPerKg(impactCategory, locale)
        : `${impactCategoryUnit(impactCategory, locale)} / ${serving(locale)}`}
    </text>
  );
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M49.209 40.7719C59.9111 40.7719 68.5869 32.0961 68.5869 21.394C68.5869 10.6919 59.9111 2.01611 49.209 2.01611C38.5068 2.01611 29.8311 10.6919 29.8311 21.394C29.8311 32.0961 38.5068 40.7719 49.209 40.7719Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M65.6326 17.6722C65.0187 17.3652 64.4047 17.3652 63.4838 18.2861C63.0924 18.6775 63.1768 19.0535 63.1768 19.821C63.1768 20.0604 62.9339 21.1681 62.6499 21.3559C61.5999 22.05 59.8047 21.7058 59.0326 21.3559C58.3327 21.0382 57.2644 21.1487 56.8838 21.8163C56.2698 22.8908 56.7303 23.9652 57.8047 24.4256C58.4356 24.6958 59.8823 24.675 60.0249 26.25C60.1364 27.4818 58.1117 27.6489 57.9582 29.3373C57.8047 31.0256 56.5937 31.7747 56.0473 33.0885C55.5009 34.4024 55.2921 35.1299 54.6367 35.9848C53.7542 37.1345 52.164 37.687 50.7581 37.3325C50.2331 37.1989 49.7282 36.9441 49.3629 36.5435C48.2746 35.354 48.755 33.4845 48.5678 31.8821C48.4158 30.5928 47.7635 29.368 46.7796 28.5222C45.7006 27.5952 44.3008 27.1501 43.0622 26.4517C41.8235 25.7533 41.0944 24.7725 41.076 23.3497C41.0576 21.913 41.5749 20.174 42.9179 19.666C44.2609 19.1579 47.0498 19.0106 48.4435 19.359C50.2853 19.8195 52.7657 19.9668 53.8156 18.5915C54.2561 18.0144 54.3666 17.0597 53.6621 16.4427C52.4342 15.3683 50.7458 16.7497 49.5179 16.2892C48.6092 15.9485 48.3836 14.4274 46.9086 14.4474C45.4336 14.4673 44.5142 14.5395 43.5318 15.5218C42.6109 16.4427 40.8197 16.4243 40.3086 15.0613C39.8481 13.8334 40.7629 13.2133 41.383 12.759C42.0031 12.3047 42.4262 11.7039 42.6089 10.9564C42.7086 10.5466 42.4574 8.76829 43.6853 8.15434C45.5932 7.20117 45.7298 10.1497 47.369 9.53573C48.5969 9.07527 48.2531 7.83508 48.5969 7.23341C49.2109 6.15899 49.9691 6.02852 50.7458 5.69852C52.3942 4.99708 53.4932 4.34629 54.2791 2.68555C62.1101 4.80215 67.9749 11.703 68.5428 20.0604C68.5428 20.0604 66.2481 17.9776 65.6342 17.6706L65.6326 17.6722Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.5684 13.3697C31.6006 13.3667 31.6344 13.3651 31.6666 13.3605C33.2675 13.1549 34.6044 13.0106 35.8584 11.9929C37.1308 10.96 37.8568 9.56783 38.3142 8.30922C38.6319 7.43434 38.1238 6.65155 37.625 5.86108C35.0249 7.80425 32.9298 10.3844 31.5699 13.3697H31.5684Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.4741 29.7978C34.2761 28.8431 34.0551 28.0987 33.7066 27.1885C33.306 26.1448 33.4334 24.8355 33.4104 23.7181C33.3997 23.1978 33.2462 21.9699 32.7857 21.3559C31.9906 20.2953 31.2508 19.6676 29.9738 19.0398C29.8802 19.8118 29.8311 20.5962 29.8311 21.3943C29.8311 26.0327 31.4626 30.2905 34.1809 33.6273C34.1809 33.6273 34.2607 33.5214 34.4158 33.3096C35.1694 32.2367 34.7411 31.081 34.4741 29.7978Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M55.3096 31.6599H34.0596C37.5996 37.2599 43.8396 40.9899 50.9496 40.9899C54.7196 40.9899 58.2296 39.9299 61.2296 38.1199L55.3096 31.6599Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.4805 40.6601C63.8405 38.9901 70.9605 30.8301 70.9605 20.9901C70.9605 11.1501 63.8405 2.99007 54.4805 1.32007V40.6601Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M54.4805 1.32007V20.7701H70.9505C70.8405 11.0301 63.7805 2.98007 54.4805 1.32007Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M35.9997 25.7001V31.6701H41.1097V25.7001C41.1097 25.0601 41.5497 24.5401 42.0997 24.5401H47.2197C48.6297 24.5401 49.3097 22.8101 48.2697 21.8501L43.7097 17.6401H45.4697C46.8797 17.6401 47.5597 15.9101 46.5197 14.9501L42.4397 11.1801H43.5597C44.9697 11.1801 45.6497 9.45012 44.6097 8.49012L39.9697 4.41012C39.1597 3.69012 37.9397 3.69012 37.1197 4.41012L32.4797 8.49012C31.4397 9.45012 32.1197 11.1801 33.5297 11.1801H34.6497L30.5697 14.9501C29.5297 15.9101 30.2097 17.6401 31.6197 17.6401H33.3797L28.8197 21.8501C27.7797 22.8101 28.4597 24.5401 29.8697 24.5401H34.9897C35.5397 24.5401 35.9797 25.0601 35.9797 25.7001H35.9997Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M50.9502 40.99C61.9959 40.99 70.9502 32.0357 70.9502 20.99C70.9502 9.94429 61.9959 0.98999 50.9502 0.98999C39.9045 0.98999 30.9502 9.94429 30.9502 20.99C30.9502 32.0357 39.9045 40.99 50.9502 40.99Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M50.9502 40.99C58.1902 40.99 64.5102 37.13 68.0202 31.37C62.1902 37.16 57.2402 28 57.2402 28C49.7502 39.47 43.9702 27.29 43.9702 27.29C40.7202 31.44 37.6502 32.91 34.9502 32.96C38.6002 37.82 44.3902 40.99 50.9402 40.99H50.9502Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M52.3203 19.5301C52.3203 16.2901 56.0503 9.72006 56.0503 9.72006C56.4503 8.84006 57.8303 8.84006 58.2303 9.72006C58.2303 9.72006 61.9603 16.1801 61.9603 19.5301C61.9603 22.1901 59.8003 24.6801 57.1403 24.6801C54.4803 24.6801 52.3203 22.1901 52.3203 19.5301Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.6797 24.3099C27.6797 17.4299 35.5997 3.45994 35.5997 3.45994C36.4497 1.59994 39.3797 1.59994 40.2297 3.45994C40.2297 3.45994 48.1497 17.1899 48.1497 24.3099C48.1497 29.9599 43.5697 35.2499 37.9097 35.2499C32.2497 35.2499 27.6797 29.9599 27.6797 24.3099Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}

interface TitleProps {
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Title(props: TitleProps) {
  const { impactCategory, locale } = props;

  const titleString = impactCategoryTitle(impactCategory, locale);

  const y = 78;

  const commonProps = {
    y,
    x: 49,
    textAnchor: "middle",
    fontFamily: "Graphik Web",
    fontWeight: 500,
    fontSize: impactCategoryTitleFontSize(impactCategory, locale),
    fill: "black",
  };

  if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    const words = titleString.split(" ");
    const firstWord = words.shift();
    return (
      <>
        <text {...commonProps}>{firstWord}</text>
        <text {...commonProps} y={y + lineHeight}>
          {words.join(" ")}
        </text>
      </>
    );
  } else {
    return <text {...commonProps}>{titleString}</text>;
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 10;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 12;
  }
  return 13;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === "VLOW") {
    return 14;
  }
  return 16;
}

function unitFontSize(
  functionalUnit: FunctionalUnit,
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (
    functionalUnit === FunctionalUnit.PORTION &&
    impactCategory === ImpactCategory.GHG &&
    locale !== "zh-CN"
  ) {
    return 10;
  } else {
    return 11;
  }
}
