import gql from "graphql-tag";
import React from "react";
import react, { useState as reactUseState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";

import {
  useEditProducts,
  useFoodManufacturerOrganization,
  useViewSharedProducts,
} from "../../services/useOrganizationFeatures";
import { useTracking } from "../../tracking";
import useMutation from "../graphql/useMutation";
import { useOrganizationId } from "../organizations/OrganizationProvider";
import PackagingSwitch from "../packaging/PackagingSwitch";
import { usePages } from "../pages";
import { useHasRecipePermissionAddAndEditProductsFeature } from "../permissions";
import ProductLimitModal from "../sharing/ProductLimitModal";
import SplitButton from "../utils/SplitButton";
import Toast from "../utils/Toast";
import { FilledCheckmark } from "../utils/Vectors";
import ContactUsButton from "./ContactUsButton";
import {
  SingleRecipeCopyToButton,
  copyRecipesMutation,
  CopySuccessToastState,
} from "./CopyToButton";
import {
  CopyToButton_CopyRecipes,
  CopyToButton_CopyRecipesVariables,
} from "./CopyToButton.graphql";
import RecipeCard from "./RecipeCard";
import { RecipePageControls_Recipe as Recipe } from "./RecipePageControls.graphql";
import useRecipeLabel from "./useRecipeLabel";
import "./RecipePageControls.css";

export type State = "view" | "edit" | "delete";

export function useState() {
  return React.useState<State>("view");
}

interface ButtonsProps {
  includePackaging: boolean;
  includePackagingChange: (excludePackaging: boolean) => void;
  state: State;
  recipe: Recipe;
}

export function Buttons(props: ButtonsProps) {
  const { includePackaging, includePackagingChange, state, recipe } = props;

  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();
  const canViewSharedProducts = useViewSharedProducts();
  const [copyToastState, setCopyToastState] =
    reactUseState<CopySuccessToastState | null>(null);

  return state !== "view" ? null : (
    <div className="recipe-page-controls-buttons">
      <div className="grid-item-2-1">
        {!isFoodManufacturerOrganization && (
          <PackagingSwitch
            disabled={recipe.packagingComponentsV2.length === 0}
            checked={includePackaging}
            onChange={includePackagingChange}
            toolTipOverlayPropsWhenDisabled={{
              placement: "top",
              overlay: (
                <FormattedMessage
                  id={
                    "components/recipes/RecipePageControls:packagingSwitchDisabledToolTip"
                  }
                  defaultMessage="You can't use this switch because the product is not packaged"
                />
              ),
            }}
          />
        )}
      </div>
      <div className="grid-item-2-2">
        {recipe.viewerHasPermissionUpdate ? (
          <EditRecipeSplitButton recipe={recipe} />
        ) : (
          canViewSharedProducts && (
            <SingleRecipeCopyToButton
              recipe={recipe}
              setToastState={setCopyToastState}
            />
          )
        )}
      </div>
      {copyToastState && (
        <Toast
          message={copyToastState.message}
          onClose={() => setCopyToastState(null)}
          show
          title={copyToastState.title}
          symbol={<FilledCheckmark width={20} />}
        />
      )}
    </div>
  );
}

Buttons.useEditButtonProperties = ({
  onEdit,
  recipe,
}: {
  onEdit: () => void;
  recipe: Recipe;
}): {
  label: string;
  onClick: () => void;
} | null => {
  const { trackRecipeEditStarted } = useTracking();
  const intl = useIntl();
  const recipeLabel = useRecipeLabel();
  const editProducts = useEditProducts();

  return recipe.viewerHasPermissionUpdate && editProducts
    ? {
        label: intl.formatMessage(
          {
            id: "components/recipes/RecipePageControls:editButton",
            defaultMessage: "Edit {recipeLabel}",
          },
          { recipeLabel: recipeLabel.singularUppercase }
        ),
        onClick: () => {
          trackRecipeEditStarted({
            type: "edit",
            recipeId: recipe.id,
            recipeName: recipe.name,
          });
          onEdit();
        },
      }
    : null;
};

Buttons.useCopyButtonProperties = ({
  onCopy,
  recipe,
}: {
  onCopy: () => void;
  recipe: Recipe;
}): {
  label: string;
  onClick: () => void;
} | null => {
  const { trackRecipeCopyStarted } = useTracking();
  const intl = useIntl();
  const hasRecipePermissionAddAndEditProductsFeature =
    useHasRecipePermissionAddAndEditProductsFeature();

  return hasRecipePermissionAddAndEditProductsFeature
    ? {
        label: intl.formatMessage({
          id: "components/recipes/RecipePageControls:copyButton",
          defaultMessage: "Duplicate",
        }),
        onClick: () => {
          trackRecipeCopyStarted({
            recipeId: recipe.id,
            recipeName: recipe.name,
          });
          onCopy();
        },
      }
    : null;
};

interface EditRecipeSplitButtonProps {
  recipe: Recipe;
}

function EditRecipeSplitButton(props: EditRecipeSplitButtonProps) {
  const { recipe } = props;

  const [copyRecipes] = useMutation<
    CopyToButton_CopyRecipes,
    CopyToButton_CopyRecipesVariables
  >(copyRecipesMutation());
  const intl = useIntl();

  const recipeLabel = useRecipeLabel();
  const foodManufacturerOrganization = useFoodManufacturerOrganization();
  const history = useHistory();
  const pages = usePages();
  const [organizationId] = useOrganizationId();
  const [showProductLimitExceeded, setShowProductLimitExceeded] =
    react.useState<boolean>(false);
  const [copyInProgress, setCopyInProgress] = react.useState<boolean>(false);

  const doCopyCallback = async () => {
    try {
      const response = await copyRecipes({
        variables: {
          input: {
            recipeIds: [recipe.id],
            shallowCopyPackaging: true,
            shallowCopyRecipes: true,
            toCollectionIds: recipe.collections.map(
              (collection) => collection.id
            ),
            updateExistingComponents: false,
            updateExistingRecipes: false,
            toOrganizationId: organizationId,
          },
        },
      });
      if (foodManufacturerOrganization) {
        history.push({
          pathname: pages.RecipeEdit.url({
            id: response.copyRecipes.recipes[0].id,
          }),
        });
      } else {
        history.push({
          pathname: pages.Recipe.url({
            id: response.copyRecipes.recipes[0].id,
          }),
          search: "state=edit",
        });
      }
    } catch (e: any) {
      if (e instanceof Error && e.message === "Recipe count limit reached.") {
        setShowProductLimitExceeded(true);
      }
    }
    setCopyInProgress(false);
  };

  const onDelete = () => {
    history.push({
      pathname: pages.Recipe.url({
        id: recipe.id,
      }),
      search: "state=delete",
    });
  };

  const editButtonProperties = Buttons.useEditButtonProperties({
    onEdit: () => {
      if (foodManufacturerOrganization) {
        history.push({
          pathname: pages.RecipeEdit.url(recipe),
        });
      } else {
        history.push({
          pathname: pages.Recipe.url({
            id: recipe.id,
          }),
          search: "state=edit",
        });
      }
    },
    recipe,
  });
  const copyButtonProperties = Buttons.useCopyButtonProperties({
    onCopy: () => {
      if (!copyInProgress) {
        setCopyInProgress(true);
        doCopyCallback();
      }
    },
    recipe,
  });

  const editItems = [
    ...(editButtonProperties ? [editButtonProperties] : []),
    ...(copyButtonProperties ? [copyButtonProperties] : []),
    ...(recipe.viewerHasPermissionUpdate
      ? [
          {
            label: intl.formatMessage(
              {
                id: "components/recipes/RecipePageControls:deleteButton",
                defaultMessage: "Delete {recipeLabel}",
              },
              { recipeLabel: recipeLabel.singularUppercase }
            ),
            onClick: onDelete,
          },
        ]
      : []),
  ];

  return (
    <>
      <SplitButton items={editItems} variant="primary" />
      <ProductLimitModal
        show={showProductLimitExceeded}
        onClose={() => setShowProductLimitExceeded(false)}
        onContact={() => history.push(pages.ContactUs.url)}
      />
    </>
  );
}

interface EditRecipeContentProps {
  onStateChange: (state: State) => void;
  recipe: Recipe;
  refresh: () => Promise<void>;
  state: State;
}

export function EditRecipeContent(props: EditRecipeContentProps) {
  const { onStateChange, recipe, refresh, state } = props;

  const history = useHistory();
  const pages = usePages();

  if (state === "view") {
    return null;
  } else {
    const onAfterSave = (savedRecipeId: number) => {
      if (savedRecipeId === recipe.id) {
        onStateChange("view");
      } else {
        history.push(pages.Recipe.url({ id: savedRecipeId }));
      }
    };

    return (
      <RecipeCard
        onAfterSave={onAfterSave}
        onDiscard={() => {
          history.push({
            pathname: pages.Recipe.url({
              id: recipe.id,
            }),
            search: "state=view",
          });
        }}
        recipeId={recipe.id}
        refresh={refresh}
      />
    );
  }
}

export const fragments = {
  recipe: gql`
    fragment RecipePageControls_Recipe on Recipe {
      collections {
        id
      }
      id
      viewerHasPermissionUpdate
      packagingComponentsV2 {
        packagingComponent {
          id
        }
      }
      ...ContactUsButton_Recipe
    }

    ${ContactUsButton.fragments.recipe}
  `,
};
