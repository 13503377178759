import { gql } from "graphql-tag";
import React, { useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { AddRecipeInput } from "../../../__generated__/globalTypes";
import AvailableIngredient from "../../../domain/AvailableIngredient";
import {
  useAccurateCookingImpacts,
  useAccuratePostPreparationStorageImpacts,
  useFoodManufacturerOrganization,
  useProductTags,
} from "../../../services/useOrganizationFeatures";
import UserVisibleError from "../../../util/UserVisibleError";
import { SecondaryButton } from "../../utils/Button";
import Form from "../../utils/Form";
import { combineReadResults } from "../../utils/ReadResult";
import { CookingMethod } from "../RecipeCard";
import * as RecipeTagsEditor from "../RecipeTagsEditor";
import useRecipeLabel from "../useRecipeLabel";
import * as RecipeCollectionsEditor from "./RecipeCollectionsEditor";
import * as RecipeCookingEditor from "./RecipeCookingEditor";
import "./RecipeEditor.css";
import {
  RecipeEditor_Collection as Collection,
  RecipeEditor_Recipe as Recipe,
  RecipeEditor_StorageAppliance as StorageAppliance,
  RecipeEditor_PackagingComponentV2 as PackagingComponentV2,
} from "./RecipeEditor.graphql";
import * as RecipeIngredientsEditor from "./RecipeIngredientsEditor";
import * as RecipeIsHotDrinkEditor from "./RecipeIsHotDrinkEditor";
import * as RecipeNameEditor from "./RecipeNameEditor";
import * as RecipePackagingV2Editor from "./RecipePackagingV2Editor";
import * as RecipePostPreparationStorageEditor from "./RecipePostPreparationStorageEditor";
import * as RecipeServingsEditor from "./RecipeServingsEditor";

export type RecipeInput = Omit<AddRecipeInput, "ownerOrganizationId">;

interface RecipeEditorProps {
  availableIngredients: Array<AvailableIngredient>;
  cookingMethods: Array<CookingMethod>;
  packagingComponents: Array<PackagingComponentV2>;
  onDiscard: () => void;
  onSave: (recipe: RecipeInput) => Promise<void>;
  possibleCollections: Array<Collection>;
  recipe?: Recipe;
  refreshPossibleCollections: () => Promise<void>;
  storageAppliances: Array<StorageAppliance>;
}

interface RecipeEditorState {
  collectionIds: RecipeCollectionsEditor.Value;
  cooking: RecipeCookingEditor.Value;
  ingredients: RecipeIngredientsEditor.Value;
  isHotDrink: RecipeIsHotDrinkEditor.Value;
  name: RecipeNameEditor.Value;
  packagingComponentsV2: RecipePackagingV2Editor.Value;
  postPreparationStorage: RecipePostPreparationStorageEditor.Value;
  servingsCount: RecipeServingsEditor.Value;
}

export default function RecipeEditor(props: RecipeEditorProps) {
  const {
    availableIngredients,
    cookingMethods,
    packagingComponents,
    onDiscard,
    onSave,
    recipe,
    storageAppliances,
  } = props;

  const intl = useIntl();
  const accurateCookingImpacts = useAccurateCookingImpacts();
  const accuratePostPreparationStorageImpacts =
    useAccuratePostPreparationStorageImpacts();
  const isFoodManufacturerOrganization = useFoodManufacturerOrganization();
  const recipeLabel = useRecipeLabel();
  const canEditProductTags = useProductTags();

  const [state, setState] = useState<RecipeEditorState>(() => {
    return {
      collectionIds: RecipeCollectionsEditor.initialValue(recipe),
      cooking: RecipeCookingEditor.initialValue(
        recipe,
        accurateCookingImpacts,
        isFoodManufacturerOrganization
      ),
      ingredients: RecipeIngredientsEditor.initialValue(recipe),
      isHotDrink: RecipeIsHotDrinkEditor.initialValue(recipe),
      name: RecipeNameEditor.initialValue(recipe),
      packagingComponentsV2: RecipePackagingV2Editor.initialValue(
        isFoodManufacturerOrganization,
        recipe,
        packagingComponents
      ),
      postPreparationStorage: RecipePostPreparationStorageEditor.initialValue(
        recipe,
        accuratePostPreparationStorageImpacts,
        isFoodManufacturerOrganization
      ),
      servingsCount: RecipeServingsEditor.initialValue(recipe),
    };
  });

  async function handleSubmit() {
    const sectionReadResults = {
      collectionIds: RecipeCollectionsEditor.read(state.collectionIds),
      cooking: RecipeCookingEditor.read(state.cooking),
      ingredients: RecipeIngredientsEditor.read(state.ingredients),
      isHotDrink: RecipeIsHotDrinkEditor.read(state.isHotDrink),
      name: RecipeNameEditor.read(state.name),
      packagingComponentsV2: RecipePackagingV2Editor.read(
        state.packagingComponentsV2
      ),
      postPreparationStorage: RecipePostPreparationStorageEditor.read(
        state.postPreparationStorage
      ),
      servingsCount: RecipeServingsEditor.read(state.servingsCount),
    };

    const readResult = combineReadResults(sectionReadResults);

    setState(readResult.value);

    if (readResult.hasError) {
      throw missingInformationError(intl);
    } else {
      const newRecipe: RecipeInput = {
        code: readResult.input.name.code,
        collectionIds: readResult.input.collectionIds,
        cookingPenalties: readResult.input.cooking.cookingPenalties,
        coProducts: [],
        hasCompleteCookingPenalties: true,
        ingredients: readResult.input.ingredients,
        isCooked: readResult.input.cooking.isCooked,
        isHotDrink: readResult.input.isHotDrink,
        isStored: readResult.input.postPreparationStorage.isStored,
        name: readResult.input.name.name,
        numServings: readResult.input.servingsCount,
        packagingComponentsV2:
          readResult.input.packagingComponentsV2.packagingComponentsV2,
        postPreparationStoragePenalties:
          readResult.input.postPreparationStorage
            .postPreparationStoragePenalties,
        hasCompletePostPreparationStoragePenalties: true,
        servingsPerCookingBatch:
          readResult.input.cooking.servingsPerCookingBatch,
      };
      return onSave(newRecipe);
    }
  }

  return (
    <div className="RecipeEditor__root" style={{ maxWidth: "50rem" }}>
      <RecipeNameEditor.RecipeNameEditor
        key="nameEditor"
        onChange={(name) => setState((state) => ({ ...state, name }))}
        value={state.name}
        placeholder={intl.formatMessage(
          {
            defaultMessage: "Give your {recipeLabel} a name",
            id: "components/recipes/RecipeEditor:nameInputPlaceholder",
          },
          { recipeLabel: recipeLabel.singularLowercase }
        )}
      />
      <RecipeIngredientsEditor.RecipeIngredientsEditor
        key="ingredientsEditor"
        availableIngredients={availableIngredients}
        existingRecipe={recipe}
        onChange={(ingredients) =>
          setState((state) => ({ ...state, ingredients }))
        }
        value={state.ingredients}
      />
      {!isFoodManufacturerOrganization && (
        <RecipeIsHotDrinkEditor.RecipeIsHotDrinkEditor
          key="hotDrinkEditor"
          value={state.isHotDrink}
          onChange={(isHotDrink) =>
            setState((state) => ({ ...state, isHotDrink }))
          }
        />
      )}
      <RecipeServingsEditor.RecipeServingsEditor
        key="servingsEditor"
        onChange={(servingsCount) =>
          setState((state) => ({ ...state, servingsCount }))
        }
        value={state.servingsCount}
      />
      {!isFoodManufacturerOrganization && (
        <RecipeCookingEditor.RecipeCookingEditor
          key="cookingEditor"
          cookingMethods={cookingMethods}
          onChange={(cooking) => setState((state) => ({ ...state, cooking }))}
          servings={state.servingsCount.serves}
          value={state.cooking}
        />
      )}
      {!isFoodManufacturerOrganization && (
        <RecipePackagingV2Editor.RecipePackagingV2Editor
          key="packagingEditor"
          packagingComponents={packagingComponents}
          onChange={(packagingComponentsV2) => {
            setState((state) => ({ ...state, packagingComponentsV2 }));
          }}
          value={state.packagingComponentsV2}
        />
      )}
      {!isFoodManufacturerOrganization && (
        <RecipePostPreparationStorageEditor.RecipePostPreparationStorageEditor
          key="postPrepStorageEditor"
          storageAppliances={storageAppliances}
          onChange={(postPreparationStorage) =>
            setState((state) => ({ ...state, postPreparationStorage }))
          }
          value={state.postPreparationStorage}
        />
      )}
      {canEditProductTags && (
        <RecipeTagsEditor.RecipeTagsEditor
          onChange={(collectionIds) =>
            setState((state) => ({ ...state, collectionIds }))
          }
          selectedIds={state.collectionIds}
        />
      )}
      <div className="d-flex justify-content-between mt-4">
        <div>
          <DiscardChangesButton onClick={onDiscard} />
        </div>
        <Form className="d-flex" onSubmit={handleSubmit}>
          <Form.ErrorAlert className="mr-4" />
          <div>
            <SaveButton recipe={recipe} />
          </div>
        </Form>
      </div>
    </div>
  );
}

interface DiscardChangesButtonProps {
  onClick: () => void;
}

function DiscardChangesButton(props: DiscardChangesButtonProps) {
  const { onClick } = props;

  return (
    <SecondaryButton onClick={onClick}>
      <FormattedMessage
        id="components/recipes/RecipeEditor/RecipeEditor:discardChanges"
        defaultMessage="Cancel"
      />
    </SecondaryButton>
  );
}

interface SaveButtonProps {
  recipe?: Recipe;
}

function SaveButton(props: SaveButtonProps) {
  const { recipe } = props;
  const intl = useIntl();

  return recipe === undefined ? (
    <Form.SubmitButton
      loadingLabel={intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeEditor:saveNew/loadingLabel",
        defaultMessage: "Creating",
      })}
      submitLabel={intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeEditor:saveNew/submitLabel",
        defaultMessage: "Create product",
      })}
    />
  ) : (
    <Form.SubmitButton
      loadingLabel={intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeEditor:save/loadingLabel",
        defaultMessage: "Saving",
      })}
      submitLabel={intl.formatMessage({
        id: "components/recipes/RecipeEditor/RecipeEditor:save/submitLabel",
        defaultMessage: "Save",
      })}
    />
  );
}

function missingInformationError(intl: IntlShape) {
  return new UserVisibleError(
    intl.formatMessage({
      id: "components/recipes/RecipeEditor/RecipeEditor:missingInformationMessage",
      defaultMessage: "Please enter all the required information",
    })
  );
}

RecipeEditor.fragments = {
  collection: gql`
    fragment RecipeEditor_Collection on RecipeCollection {
      ...RecipeCollectionsEditor_Collection
      ...RecipeTagsEditor_Collection
    }

    ${RecipeCollectionsEditor.fragments.collection}
    ${RecipeTagsEditor.fragments.collection}
  `,

  packagingComponent: gql`
    fragment RecipeEditor_PackagingComponentV2 on PackagingComponentV2 {
      ...RecipePackagingV2Editor_PackagingComponentV2
    }

    ${RecipePackagingV2Editor.fragments.packagingComponent}
  `,

  recipe: gql`
    fragment RecipeEditor_Recipe on Recipe {
      id
      ...RecipeCollectionsEditor_Recipe
      ...RecipeCookingEditor_Recipe
      ...RecipeIngredientsEditor_Recipe
      ...RecipeIsHotDrinkEditor_Recipe
      ...RecipeNameEditor_Recipe
      ...RecipePackagingV2Editor_Recipe
      ...RecipePostPreparationStorageEditor_Recipe
      ...RecipeServingsEditor_Recipe
    }

    ${RecipeCollectionsEditor.fragments.recipe}
    ${RecipeCookingEditor.fragments.recipe}
    ${RecipeIngredientsEditor.fragments.recipe}
    ${RecipeIsHotDrinkEditor.fragments.recipe}
    ${RecipeNameEditor.fragments.recipe}
    ${RecipePackagingV2Editor.fragments.recipe}
    ${RecipePostPreparationStorageEditor.fragments.recipe}
    ${RecipeServingsEditor.fragments.recipe}
  `,

  storageAppliance: gql`
    fragment RecipeEditor_StorageAppliance on StorageAppliance {
      ...RecipePostPreparationStorageEditor_StorageAppliance
    }

    ${RecipePostPreparationStorageEditor.fragments.storageAppliance}
  `,
};

export type { Recipe };
