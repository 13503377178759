import classNames from "classnames";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FormattedMessage } from "react-intl";

import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import RotatingChevron from "../utils/RotatingChevron";
import "./ImpactCategoryToggle.css";

interface ImpactCategoryToggleProps {
  disabled?: (impactCategory: ImpactCategory) => boolean;
  onChange: (impactCategory: ImpactCategory) => void;
  options: Array<ImpactCategory>;
  selectedImpactCategory: ImpactCategory;
}

export default function ImpactCategoryToggle(props: ImpactCategoryToggleProps) {
  const {
    disabled = () => false,
    onChange,
    options,
    selectedImpactCategory,
  } = props;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [pageToggleHover, setPageToggleHover] = useState<boolean>(false);

  const renderOption = (value: ImpactCategory) => {
    if (value === ImpactCategory.GHG) {
      return (
        <FormattedMessage
          defaultMessage="GHG Emissions"
          id="components/recipes/ImpactCategoryToggle:ghgEmissions"
        />
      );
    } else if (value === ImpactCategory.LAND_USE) {
      return (
        <FormattedMessage
          defaultMessage="Land Use"
          id="components/recipes/ImpactCategoryToggle:landUse"
        />
      );
    } else if (value === ImpactCategory.WATER_USE) {
      return (
        <FormattedMessage
          defaultMessage="Water Use"
          id="components/recipes/ImpactCategoryToggle:waterUse"
        />
      );
    } else {
      assertNever(value, "Unsupported ImpactCategory");
    }
  };

  const handleChange = (value: ImpactCategory | null) => {
    if (value === null) {
      throw new Error("value should not be clearable.");
    }
    onChange(value);
  };

  return (
    <Dropdown
      className="ImpactCategoryToggle"
      show={showDropdown}
      onToggle={(show: boolean) => setShowDropdown(show)}
    >
      <Dropdown.Toggle
        className={classNames("ImpactCategoryToggle__PageToggle", {
          ImpactCategoryToggle__PageToggle__Active: showDropdown,
          ImpactCategoryToggle__PageToggle__Hover: pageToggleHover,
        })}
        variant="none"
        onMouseOver={() => setPageToggleHover(true)}
        onMouseLeave={() => setPageToggleHover(false)}
      >
        {renderOption(selectedImpactCategory)}
        <RotatingChevron
          className={classNames("ImpactCategoryToggle__Chevron", {
            ImpactCategoryToggle__Chevron__Expanded: showDropdown,
            ImpactCategoryToggle__Chevron__Hover: pageToggleHover,
          })}
          expanded={showDropdown}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className={"ImpactCategoryToggle__Menu"}>
        {options.map((impactCategory, index) => (
          <Dropdown.Item
            disabled={disabled(impactCategory)}
            key={index}
            className={classNames("ImpactCategoryToggle__Item", {
              ImpactCategoryToggle__Item__Active:
                impactCategory === selectedImpactCategory,
              ImpactCategoryToggle__Item__First: index === 0,
              ImpactCategoryToggle__Item__Last: index === options.length - 1,
            })}
            onSelect={() => handleChange(impactCategory)}
          >
            {renderOption(impactCategory)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
