import classNames from "classnames";
import gql from "graphql-tag";
import { useState } from "react";
import { IntlShape, useIntl } from "react-intl";

import { RecipeOrder } from "../../__generated__/globalTypes";
import { effectTypes } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  useLandUse,
  useWaterUse,
} from "../../services/useOrganizationFeatures";
import { RecipesListPageQueryParams } from "../pages";
import ProductFilterSelect from "../product-filter/ProductFilterSelect";
import ManageTagsButton from "../tags/ManageTagsButton";
import SearchBox from "../utils/SearchBox";
import Select from "../utils/Select";
import useStateWithDebouncedUpdate from "../utils/useStateWithDebouncedUpdate";
import { CollectionToSelect } from "./RecipeQueryControls.graphql";
import useRecipeLabel from "./useRecipeLabel";
import "./RecipeQueryControls.css";

export type OrderBy =
  | RecipeOrder.NAME_ASC
  | RecipeOrder.CACHED_GHG_PER_KG_ASC
  | RecipeOrder.CACHED_GHG_PER_KG_DESC
  | RecipeOrder.CACHED_GHG_PER_SERVING_ASC
  | RecipeOrder.CACHED_GHG_PER_SERVING_DESC
  | RecipeOrder.CACHED_LAND_USE_PER_KG_ASC
  | RecipeOrder.CACHED_LAND_USE_PER_KG_DESC
  | RecipeOrder.CACHED_WATER_USE_PER_KG_ASC
  | RecipeOrder.CACHED_WATER_USE_PER_KG_DESC
  | RecipeOrder.CREATED_AT_ASC
  | RecipeOrder.CREATED_AT_DESC;

const orderByOptions: Array<OrderBy> = [
  RecipeOrder.NAME_ASC,
  RecipeOrder.CACHED_GHG_PER_SERVING_ASC,
  RecipeOrder.CACHED_GHG_PER_SERVING_DESC,
  RecipeOrder.CACHED_GHG_PER_KG_ASC,
  RecipeOrder.CACHED_GHG_PER_KG_DESC,
  RecipeOrder.CACHED_LAND_USE_PER_KG_ASC,
  RecipeOrder.CACHED_LAND_USE_PER_KG_DESC,
  RecipeOrder.CACHED_WATER_USE_PER_KG_ASC,
  RecipeOrder.CACHED_WATER_USE_PER_KG_DESC,
  RecipeOrder.CREATED_AT_ASC,
  RecipeOrder.CREATED_AT_DESC,
];

function recipeOrderLabel(intl: IntlShape, orderBy: OrderBy): string {
  switch (orderBy) {
    case RecipeOrder.NAME_ASC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/nameAsc/label",
        defaultMessage: "Name",
      });

    case RecipeOrder.CACHED_GHG_PER_KG_ASC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/ghgPerKgAsc/label",
        defaultMessage: "Rating: Low to High",
      });

    case RecipeOrder.CACHED_GHG_PER_KG_DESC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/ghgPerKgDesc/label",
        defaultMessage: "Rating: High to Low",
      });

    case RecipeOrder.CACHED_GHG_PER_SERVING_ASC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/ghgPerServingAsc/label",
          defaultMessage: "{effectType}: Low to High",
        },
        { effectType: effectTypes.ghgPerServing.title(intl) }
      );

    case RecipeOrder.CACHED_GHG_PER_SERVING_DESC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/ghgPerServingDesc/label",
          defaultMessage: "{effectType}: High to Low",
        },
        { effectType: effectTypes.ghgPerServing.title(intl) }
      );

    case RecipeOrder.CACHED_LAND_USE_PER_KG_ASC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/landUsePerKgAsc/label",
          defaultMessage: "{effectType}: Low to High",
        },
        { effectType: effectTypes.landUsePerKg.title(intl) }
      );

    case RecipeOrder.CACHED_LAND_USE_PER_KG_DESC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/landUsePerKgDesc/label",
          defaultMessage: "{effectType}: High to Low",
        },
        { effectType: effectTypes.landUsePerKg.title(intl) }
      );

    case RecipeOrder.CACHED_WATER_USE_PER_KG_ASC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/waterUsePerKgAsc/label",
          defaultMessage: "{effectType}: Low to High",
        },
        { effectType: effectTypes.waterUsePerKg.title(intl) }
      );

    case RecipeOrder.CACHED_WATER_USE_PER_KG_DESC:
      return intl.formatMessage(
        {
          id: "components/recipes/RecipeQueryControls:order/waterUsePerKgDesc/label",
          defaultMessage: "{effectType}: High to Low",
        },
        { effectType: effectTypes.waterUsePerKg.title(intl) }
      );

    case RecipeOrder.CREATED_AT_ASC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/createdAtAsc/label",
        defaultMessage: "Oldest to Newest",
      });

    case RecipeOrder.CREATED_AT_DESC:
      return intl.formatMessage({
        id: "components/recipes/RecipeQueryControls:order/createdAtDesc/label",
        defaultMessage: "Newest to Oldest",
      });
  }
}

export function parseRecipeOrder(rawOrderBy: string): OrderBy | null {
  return (orderByOptions as Array<string>).includes(rawOrderBy)
    ? (rawOrderBy as OrderBy)
    : null;
}

interface RecipeQueryControlsProps {
  filterByParentCollections?: boolean;
  disabled: boolean;
  impactCategory: ImpactCategory;
  onChange: (value: Partial<RecipesListPageQueryParams>) => void;
  selectAllCheckbox: React.ReactNode;
  showFilterToRequiresAttention: boolean;
  showSearch: boolean;
  showManageTagsButton: boolean;
  value: RecipesListPageQueryParams;
}

export default function RecipeQueryControls(props: RecipeQueryControlsProps) {
  const {
    filterByParentCollections,
    disabled,
    impactCategory,
    onChange,
    selectAllCheckbox,
    showFilterToRequiresAttention,
    showSearch,
    showManageTagsButton,
    value,
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [searchTermText, setSearchTermText] = useStateWithDebouncedUpdate({
    initialValue: value.searchTerm,
    update: (newValue: string) => onChange({ searchTerm: newValue }),
  });

  return (
    <div
      className={classNames("recipe-query-controls", {
        RecipeQueryControls_Disabled: disabled,
      })}
    >
      <div className="d-flex top-filter-row">
        {showSearch && (
          <RecipeSearchBox
            onChange={(searchTerm) => setSearchTermText(searchTerm)}
            value={searchTermText}
          />
        )}
        <OrderByDropdown
          impactCategory={impactCategory}
          onChange={(orderBy) => onChange({ orderBy })}
          value={value.orderBy}
        />
        <div className="filter-wrapper">
          <ProductFilterSelect
            filterByParentCollections={filterByParentCollections}
            showGeneralStatusSection={showFilterToRequiresAttention}
            showManageTagsButton={showManageTagsButton}
            setFilters={(
              filterToSelectedCollectionIds: Array<number>,
              filterToRequiresClientAttention: boolean,
              intersectCollections: boolean,
              filterToSelectedGhgImpactRatings: Array<string>,
              filterToSelectedLandUseImpactRatings: Array<string>,
              filterToSelectedWaterUseImpactRatings: Array<string>,
              filterToSelectedDiets: Array<string>
            ) =>
              onChange({
                filterToSelectedCollectionIds,
                filterToRequiresClientAttention,
                intersectCollections,
                filterToSelectedGhgImpactRatings,
                filterToSelectedLandUseImpactRatings,
                filterToSelectedWaterUseImpactRatings,
                filterToSelectedDiets,
              })
            }
            filters={{
              filterToSelectedCollectionIds:
                value.filterToSelectedCollectionIds,
              filterToRequiresClientAttention:
                value.filterToRequiresClientAttention,
              intersectCollections: value.intersectCollections,
              filterToSelectedGhgImpactRatings:
                value.filterToSelectedGhgImpactRatings,
              filterToSelectedLandUseImpactRatings:
                value.filterToSelectedLandUseImpactRatings,
              filterToSelectedWaterUseImpactRatings:
                value.filterToSelectedWaterUseImpactRatings,
              filterToSelectedDiets: value.filterToSelectedDiets,
            }}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
          />
        </div>
        {showManageTagsButton && (
          <ManageTagsButton
            onCollectionDeleted={(deletedCollectionId) => {
              const withoutDeletedCollection = [
                ...value.filterToSelectedCollectionIds,
              ].filter(
                (filteredCollectionId) =>
                  filteredCollectionId !== deletedCollectionId
              );
              if (
                withoutDeletedCollection.length !==
                value.filterToSelectedCollectionIds.length
              ) {
                onChange({
                  filterToSelectedCollectionIds: withoutDeletedCollection,
                  filterToRequiresClientAttention:
                    value.filterToRequiresClientAttention,
                  intersectCollections: value.intersectCollections,
                });
              }
            }}
            withText={false}
          />
        )}
      </div>
      <div className="RecipeQueryControls_Checkboxes">
        <div className="mr-3">{selectAllCheckbox}</div>
      </div>
    </div>
  );
}

interface RecipeSearchBoxProps {
  onChange: (value: string) => void;
  value: string;
}

function RecipeSearchBox(props: RecipeSearchBoxProps) {
  const { onChange, value } = props;

  const intl = useIntl();
  const recipeLabel = useRecipeLabel();

  const searchPlaceholder = intl.formatMessage(
    {
      id: "components/recipes/RecipeQueryControls:RecipeSearchBox/searchPlaceholder",
      defaultMessage: "Search {recipeLabel} & codes",
    },
    {
      recipeLabel: recipeLabel.pluralLowercase,
    }
  );

  return (
    <SearchBox
      onChange={onChange}
      placeholder={searchPlaceholder}
      value={value}
    />
  );
}

interface OrderByDropdownProps {
  impactCategory: ImpactCategory;
  onChange: (value: OrderBy) => void;
  value: OrderBy;
}

function OrderByDropdown(props: OrderByDropdownProps) {
  const { impactCategory, onChange, value } = props;

  const intl = useIntl();
  const landUse = useLandUse();
  const waterUse = useWaterUse();

  let filteredOrderByOptions = orderByOptions;
  if (impactCategory !== ImpactCategory.LAND_USE || !landUse) {
    filteredOrderByOptions = filteredOrderByOptions.filter(
      (option) =>
        option !== RecipeOrder.CACHED_LAND_USE_PER_KG_ASC &&
        option !== RecipeOrder.CACHED_LAND_USE_PER_KG_DESC
    );
  }
  if (impactCategory !== ImpactCategory.WATER_USE || !waterUse) {
    filteredOrderByOptions = filteredOrderByOptions.filter(
      (option) =>
        option !== RecipeOrder.CACHED_WATER_USE_PER_KG_ASC &&
        option !== RecipeOrder.CACHED_WATER_USE_PER_KG_DESC
    );
  }
  if (impactCategory !== ImpactCategory.GHG) {
    filteredOrderByOptions = filteredOrderByOptions.filter(
      (option) =>
        option !== RecipeOrder.CACHED_GHG_PER_KG_ASC &&
        option !== RecipeOrder.CACHED_GHG_PER_KG_DESC &&
        option !== RecipeOrder.CACHED_GHG_PER_SERVING_ASC &&
        option !== RecipeOrder.CACHED_GHG_PER_SERVING_DESC
    );
  }

  return (
    <Select<OrderBy>
      className="sort-by"
      isClearable={false}
      onChange={(orderBy) => onChange(orderBy!)}
      optionKey={(orderBy) => orderBy}
      options={filteredOrderByOptions}
      renderOption={(orderBy) =>
        intl.formatMessage(
          {
            id: "components/recipes/RecipeQueryControls:sortBy",
            defaultMessage: "Sort by: {order}",
          },
          { order: recipeOrderLabel(intl, orderBy) }
        )
      }
      dropdownArrow="upDown"
      value={value}
    />
  );
}

interface SelectCollectionProps {
  collections: CollectionToSelect[];
  placeholder: string;
  selectedCollection: CollectionToSelect | null;
  setSelectedCollection: (
    selectedCollection: CollectionToSelect | null
  ) => void;
}

type SelectAllCollections = { type: "allCollections" };
const SELECT_ALL_COLLECTIONS: SelectAllCollections = { type: "allCollections" };

type CollectionToSelectOrDefault =
  | (CollectionToSelect & { type: "collectionToSelect" })
  | SelectAllCollections;

function SelectCollection(props: SelectCollectionProps) {
  const {
    collections,
    placeholder,
    selectedCollection,
    setSelectedCollection,
  } = props;

  const collectionsWithDefault = [
    SELECT_ALL_COLLECTIONS,
    ...collections.map((collection) => {
      return {
        ...collection,
        type: "collectionToSelect",
      } as CollectionToSelect & { type: "collectionToSelect" };
    }),
  ];
  return (
    <Select<CollectionToSelectOrDefault>
      className="collection-select"
      isClearable={false}
      onChange={(newValue) =>
        newValue?.type === "collectionToSelect"
          ? setSelectedCollection(newValue)
          : setSelectedCollection(null)
      }
      optionKey={(selectedCollection) =>
        selectedCollection.type === "collectionToSelect"
          ? selectedCollection.id.toString()
          : placeholder
      }
      options={collectionsWithDefault}
      renderOption={(selectedCollection) =>
        selectedCollection.type === "collectionToSelect"
          ? selectedCollection.name
          : placeholder
      }
      dropdownArrow="upDown"
      value={
        selectedCollection
          ? { ...selectedCollection, type: "collectionToSelect" }
          : SELECT_ALL_COLLECTIONS
      }
    />
  );
}

SelectCollection.fragments = {
  collection: gql`
    fragment CollectionToSelect on RecipeCollection {
      id
      name
    }
  `,
};
