import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Category, Supplier } from "../../data-store";
import useCategories from "../../data-store/useCategories";
import useSuppliers from "../../data-store/useSuppliers";
import { impactCategoryToEffectType } from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import assertNever from "../../util/assertNever";
import BarChart2, { Bar } from "../graphs/BarChart2";
import { impactMagnitude } from "../scope-3/helperFunctions";
import TableOptionToggle, {
  TableOption,
  Option,
} from "../scope-3/TableOptionToggle";
import StatusDisplay from "../StatusDisplay";
import "./ProcurementImpactBreakdownChart.css";

interface ProcurementImpactBreakdownChartProps {
  assessmentId: string;
  impactCategory: ImpactCategory;
}

type ProcurementImpactBreakdownChartTableOption =
  | Option.CATEGORY
  | Option.SUPPLIER;
const allProcurementImpactBreakdownChartTableOptions: Array<ProcurementImpactBreakdownChartTableOption> =
  [Option.CATEGORY, Option.SUPPLIER];

export function ProcurementImpactBreakdownChart(
  props: ProcurementImpactBreakdownChartProps
) {
  const { assessmentId, impactCategory } = props;

  const intl = useIntl();
  const [categoriesStatus] = useCategories(assessmentId);
  const [suppliersStatus] = useSuppliers(assessmentId);

  const [tableOption, setTableOption] =
    useState<ProcurementImpactBreakdownChartTableOption>(Option.CATEGORY);

  const effectType = impactCategoryToEffectType(impactCategory);

  const dependentAxisLabel = `${effectType.title(intl)} ${effectType.largeUnit(
    intl
  )}`;

  const bars = (groups: Array<Category | Supplier>): Array<Bar> => {
    return groups.map((group) => {
      const impact = impactMagnitude(group, impactCategory);
      const convertedImpact =
        impact === null ? null : impact / effectType.largeUnitConversionFactor;
      return { label: group.name, value: [convertedImpact] };
    });
  };

  const noDataMessage = intl.formatMessage({
    id: "components/scope-3/ProcurementImpactBreakdownChart/noDataMessage",
    defaultMessage: "No data available",
  });

  const handleTableOptionChange = (option: TableOption) => {
    if (option !== Option.CATEGORY && option !== Option.SUPPLIER) {
      throw new Error("Invalid table option");
    }
    setTableOption(option);
  };

  const barChartHeight = 80;

  return (
    <>
      <div className="ProcurementImpactBreakdownChart_Header">
        <h3>
          <FormattedMessage
            id="components/scope-3/ProcurementImpactBreakdownChart/title"
            defaultMessage="Procurement Impact by"
          />
        </h3>
        <TableOptionToggle
          option={tableOption}
          onChange={handleTableOptionChange}
          options={allProcurementImpactBreakdownChartTableOptions}
        />
      </div>
      <StatusDisplay.Many<[Array<Category> | null, Array<Supplier>]>
        statuses={[categoriesStatus, suppliersStatus]}
      >
        {(categories, suppliers) => {
          if (tableOption === Option.CATEGORY) {
            return categories ? (
              <BarChart2
                bars={bars(categories)}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else if (tableOption === Option.SUPPLIER) {
            return suppliers ? (
              <BarChart2
                bars={bars(suppliers)}
                dependentAxisLabel={dependentAxisLabel}
                height={barChartHeight}
                sorted
              />
            ) : (
              noDataMessage
            );
          } else {
            assertNever(tableOption, "Unsupported table option");
          }
        }}
      </StatusDisplay.Many>
    </>
  );
}
