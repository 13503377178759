import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { DashboardDietaryCategory } from "../../data-store";
import BarChart2, { Bar } from "../graphs/BarChart2";
import "./ProductsByDietChart.css";
import { usePages } from "../pages";
import InformationModalTooltip from "../utils/InformationModalTooltip";

interface ProductsByDietChartProps {
  dietaryCategories: Array<DashboardDietaryCategory>;
}

export default function ProductsByDietChart(props: ProductsByDietChartProps) {
  const { dietaryCategories } = props;

  const intl = useIntl();

  const productCountSum = dietaryCategories.reduce(
    (sum, dietaryCategory) => sum + dietaryCategory.productCount,
    0
  );

  const bars: Array<Bar> = dietaryCategories.map((dietaryCategory) => {
    return {
      label: dietaryCategory.name,
      value: [dietaryCategory.productCount],
      percentage: `${(
        (dietaryCategory.productCount / productCountSum) *
        100
      ).toFixed(1)}%`,
    };
  });
  const dependentAxisLabel = intl.formatMessage({
    id: "components/dashboard/ProductsByDietChart:dependentAxisLabel",
    defaultMessage: "Number of Products",
  });

  return (
    <div className="ProductsByDietChart">
      <div className="ProductsByDietChart_Header">
        <h4 className="m-0">
          <FormattedMessage
            id="components/dashboard/ProductsByDietChart:title"
            defaultMessage="Products by Diet"
          />
        </h4>
        <DietCategorisationInformationTooltip />
      </div>
      <BarChart2
        bars={bars}
        dependentAxisLabel={dependentAxisLabel}
        grace="50%"
        height={300}
        horizontal
        sorted
        maintainAspectRatio={false}
        customTooltip={(tooltip: { dataPoints: Array<DataPoint> }) => (
          <Tooltip dataPoints={tooltip.dataPoints} intl={intl} />
        )}
      />
    </div>
  );
}

interface DataPoint {
  raw: number;
  label: string;
  dataIndex: number;
  dataset: {
    formattedBars: Array<{
      percentage: string;
    }>;
  };
}

interface TooltipProps {
  dataPoints: Array<DataPoint>;
  intl: IntlShape;
}

function Tooltip(props: TooltipProps) {
  const { dataPoints, intl } = props;

  const percentage =
    dataPoints[0].dataset.formattedBars[dataPoints[0].dataIndex].percentage;

  const itemCount = dataPoints[0].raw;
  const itemCountString =
    itemCount === 1
      ? intl.formatMessage({
          id: "components/ProductsByDietChart:itemCountSingle",
          defaultMessage: "product",
        })
      : intl.formatMessage({
          id: "components/ProductsByDietChart:itemCountPlural",
          defaultMessage: "products",
        });
  return (
    <div className="ProductsByDietChart_Tooltip">
      <div className="ProductsByDietChart_Tooltip_Label">
        {dataPoints[0].label}
      </div>
      <div className="ProductsByDietChart_Tooltip_ItemCount">
        <div className="ProductsByDietChart_Tooltip_ItemCountValue">
          {itemCount}
        </div>
        <div className="small-copy">{itemCountString}</div>
      </div>
      <div className="ProductsByDietChart_Tooltip_PercentageProductRange">
        <div className="small-copy-medium">{percentage}</div>
        <div className="small-copy">
          {intl.formatMessage({
            id: "components/ProductsByDietChart:percentageProductRange",
            defaultMessage: "of product range",
          })}
        </div>
      </div>
    </div>
  );
}

function DietCategorisationInformationTooltip() {
  const pages = usePages();

  const title = (
    <FormattedMessage
      id="components/dashboard/ProductsByDietChart:DietCategorisationInformationTooltip/title"
      defaultMessage="Diet Categorisation"
    />
  );
  const body = (
    <FormattedMessage
      id="components/dashboard/ProductsByDietChart:DietCategorisationInformationTooltip:body"
      defaultMessage="
      <h4>Vegan</h4>
      <p>Products with no ingredients derived from animals are classified as vegan.</p>
      <h4>Vegetarian</h4>
      <p>Products with no meat or fish ingredients are classified as vegetarian. Vegan products are classified as both vegan and vegetarian, as they meet the definition of both categories.</p>
      <h4>Meat</h4>
      <p>Products with any quantity of ruminant meat (e.g. cows or sheep), pork, poultry (e.g. chicken or turkey), game meat (e.g. venison or rabbit) or seafood are classified into these diets, based on the highest quantity (by weight) of ingredients in any of these categories.</p>
      <h4>Unknown</h4>
      <p>In cases where an ingredient isn’t specific enough to determine whether it is derived from animals, or the type of animal it is derived from, the product is classified as unknown. In these cases, you can:</p>
      <ul>
      <li>Replace the ingredient with a more specific ingredient. For example, instead of “Cheddar Cheese”, use “Vegetarian Hard Cheese” or “Vegan Cheddar Cheese” (cheddar cheese could be vegan, vegetarian or neither, as it may contain rennet).</li>
      <li>If you know the specific composition of the ingredient, create it as a product and use it as an ingredient. For example, the ingredient “Thai Red Curry Paste” could be classified as “Seafood” if it contains shrimp paste. Creating a product that describes its specific ingredients will ensure it is categorised correctly.</li>
      </ul>
      If you are unable to resolve the issue, <a>contact Foodsteps</a> customer success for help.
    "
      values={{
        a: (chunks: React.ReactNode) => (
          <Link to={pages.ContactUs.url}>{chunks}</Link>
        ),
        h4: (chunks: React.ReactNode) => <h4>{chunks}</h4>,
        li: (chunks: React.ReactNode) => <li>{chunks}</li>,
        p: (chunks: React.ReactNode) => <p>{chunks}</p>,
        ul: (chunks: React.ReactNode) => <ul>{chunks}</ul>,
      }}
    />
  );
  return <InformationModalTooltip body={body} title={title} />;
}
